import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ReportReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/report.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(ReportReducer, defaultState, asyncActions);
};

type IReportContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const ReportContext = createContext<Partial<IReportContext>>({
    state: defaultState,
}) as React.Context<IReportContext>;

interface AppProps {
    children?: React.ReactNode;
}

const ReportProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <ReportContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </ReportContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useReportContext: () => IReportContext = () =>
    useContext(ReportContext);
export type IUseReportContext = ReturnType<typeof useReportContext>;

export { ReportContext, ReportProvider };

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import MassMailReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/mass-mail.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(MassMailReducer, defaultState, asyncActions);
};

type IMassMailContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const MassMailContext = createContext<Partial<IMassMailContext>>({
    state: defaultState,
}) as React.Context<IMassMailContext>;

interface AppProps {
    children?: React.ReactNode;
}

const MassMailProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <MassMailContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </MassMailContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useMassMailContext: () => IMassMailContext = () =>
    useContext(MassMailContext);
export type IUseMassMailContext = ReturnType<typeof useMassMailContext>;

export { MassMailContext, MassMailProvider };

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ForgotPasswordReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/forgot-password.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(ForgotPasswordReducer, defaultState, asyncActions);
};

type IForgotPasswordContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const ForgotPasswordContext = createContext<Partial<IForgotPasswordContext>>({
    state: defaultState,
}) as React.Context<IForgotPasswordContext>;

interface AppProps {
    children?: React.ReactNode;
}

const ForgotPasswordProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <ForgotPasswordContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </ForgotPasswordContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useForgotPasswordContext: () => IForgotPasswordContext = () =>
    useContext(ForgotPasswordContext);
export type IUseForgotPasswordContext = ReturnType<
    typeof useForgotPasswordContext
>;

export { ForgotPasswordContext, ForgotPasswordProvider };

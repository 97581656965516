import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import UpdateSubcontractorDBReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/manage-contractor.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(
        UpdateSubcontractorDBReducer,
        defaultState,
        asyncActions
    );
};

type IUpdateSubcontractorDBContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const UpdateSubcontractorDBContext = createContext<
    Partial<IUpdateSubcontractorDBContext>
>({
    state: defaultState,
}) as React.Context<IUpdateSubcontractorDBContext>;

interface AppProps {
    children?: React.ReactNode;
}

const UpdateSubcontractorDBProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <UpdateSubcontractorDBContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </UpdateSubcontractorDBContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useUpdateSubcontractorDBContext: () => IUpdateSubcontractorDBContext =
    () => useContext(UpdateSubcontractorDBContext);
export type IUseUpdateSubcontractorDBContext = ReturnType<
    typeof useUpdateSubcontractorDBContext
>;

export { UpdateSubcontractorDBContext, UpdateSubcontractorDBProvider };

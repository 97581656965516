import React from 'react';
import {
    ATMGrid,
    ATMForm,
    ATMModal,
    ATMButton,
    ATMField,
    ATMInput,
    ATMHeader,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './subcontractor-add-view.module.scss';

type IProps = {
    isOpen: boolean;
    loading: boolean;
    data: any;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    handleClose: () => void;
};

const SubcontractorAddView: React.FC<IProps> = ({
    isOpen,
    data,
    formRef,
    handleClose,
}) => (
    <>
        <ATMModal
            open={isOpen}
            onClose={handleClose}
            closeOnDimmerClick={false}
            size="small"
            className={styles.subcontractorViewModel}
        >
            <ATMModal.Header>
                <div>{Lang.TTL_SUBCONTRACTOR_INFORMATION}</div>
            </ATMModal.Header>

            <div className={styles.modelHeader}></div>
            <ATMModal.Content className={styles.contractorViewWrapper}>
                <ATMForm
                    ref={formRef}
                    mode="onChange"
                    defaultValues={data}
                    className={styles.subcontractorViewForm}
                >
                    {({ control, formState: { errors } }) => (
                        <>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMHeader as="h2">
                                        {Lang.TTL_SUBCONTRACTOR_COMPANY_DETAILS}
                                    </ATMHeader>

                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="name"
                                        label={Lang.LBL_CONTRACTOR_COMPANY_NAME}
                                        className={styles.inputDisabled}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMHeader as="h2">
                                        {Lang.TTL_SUBCONTRACTOR_OWNERS_DETAILS}
                                    </ATMHeader>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="ownerName"
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_OWNER_NAME
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="address"
                                        error={errors.address}
                                        className={styles.fieldname}
                                        label={Lang.LBL_SUBCONTRACTOR_ADDRESS}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="phone"
                                        error={errors.phone}
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_PHONE_NUMBER
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="city"
                                        error={errors.city}
                                        className={styles.fieldname}
                                        label={Lang.LBL_SUBCONTRACTORS_CITY}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="Nationality"
                                        error={errors.Nationality}
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_ETHINICITY
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="state"
                                        className={styles.fieldname}
                                        label={Lang.LBL_SUBCONTRACTOR_STATE}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="gender"
                                        error={errors.gender}
                                        className={
                                            styles.fieldname +
                                            ' ' +
                                            styles.genderCapital
                                        }
                                        label={Lang.LBL_SUBCONTRACTOR_GENDER}
                                        disabled
                                        style={{ textTransform: 'capitalize' }}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="zip"
                                        className={styles.fieldname}
                                        label={Lang.LBL_SUBCONTRACTOR_ZIP}
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMHeader as="h2">
                                        {
                                            Lang.TTL_SUBCONTRACTOR_CONTACTS_DETAILS
                                        }
                                    </ATMHeader>

                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="contactName"
                                        error={errors.address}
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_CONTACT_NAME
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMHeader as="h2">
                                        {
                                            Lang.TTL_SUBCONTRACTOR_VERIFICATION_DETAILS
                                        }
                                    </ATMHeader>

                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="agency_name"
                                        error={errors.agency_name}
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_AGENCY
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="contactPhone"
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_CONTACT_NUMBER
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="verificationNumber"
                                        error={errors.verificationNumber}
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_NUMBER
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="contactEmail"
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_CONTACT_EMAIL
                                        }
                                        className={styles.fieldname}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="verificationExpirationDate"
                                        error={
                                            errors.verificationExpirationDate
                                        }
                                        className={styles.fieldname}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_EXP_DATE
                                        }
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column
                                    className={styles.subconColWrapper}
                                >
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="SAP_id"
                                        label={Lang.LBL_SUBCONTRACTOR_SAP_ID}
                                        className={styles.fieldname}
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                        </>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton
                    className={`secondary`}
                    size="medium"
                    onClick={handleClose}
                    style={{ marginRight: 20 }}
                >
                    {Lang.LBL_CLOSE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    </>
);

export default SubcontractorAddView;

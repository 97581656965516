import services from 'src/services/login.service';
import { ILogin } from 'src/models/login.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import { services as manageSubcontractorService } from 'src/services/manage-subcontractor.service';
import { IReportingyearsGetSchema } from 'src/models/manage-subcontractor.model';
export const actionTypes = {
    LOGIN_FETCH: 'LOGIN_FETCH',
    LOGIN_AD_FETCH: 'LOGIN_AD_FETCH',
    FETCH_TOKEN: 'FETCH_TOKEN',
    FETCH_FORGOT_PASS: 'FETCH_FORGOT_PASS',
    REPORTING_YEAR: 'REPORTING_YEAR'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    CDCLoginGetSAPLoginURLGet: {
        type: actionTypes.LOGIN_FETCH,
        service: services.CDCLoginGetSAPLoginURLGet,
        status: {} as never,
    },
    CDCAccessTokenPost: {
        type: actionTypes.LOGIN_FETCH,
        service: services.CDCAccessTokenPost,
        status: {} as never,
    },
    loginPost: {
        type: actionTypes.LOGIN_FETCH,
        service: services.loginPost,
        status: {} as never,
    },
    loginGet: {
        type: actionTypes.LOGIN_AD_FETCH,
        service: services.loginGet,
        status: {} as never,
    },
    forgotPassPost: {
        type: actionTypes.FETCH_FORGOT_PASS,
        service: services.forgotPassPost,
        status: {} as never,
    },
    refreshToken: {
        type: actionTypes.FETCH_TOKEN,
        service: services.refreshToken,
        status: {} as never,
    },
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: manageSubcontractorService.reportingyearsGet,
        status: {} as never,
    },
};
export type ILoginAsync = typeof asyncActions;

export type ILoginReducerAction = IAsyncActions<ILoginAsync>;
export interface ILoginState extends ICommonState<ILoginReducerAction> {
    info: ILogin;
    forgotPass: any;
    token: any;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
}

export const defaultState: any = {
    status: {},
    info: { data: {} },
    token: '',
    forgotPass: '',
    reportingyearsGet: {},
};

const LoginReducer = (
    state: ILoginState = defaultState,
    action: ICommonAction<ILoginReducerAction>
): ILoginState => {
    switch (action.type) {
        case actionTypes.LOGIN_FETCH: {
            return {
                ...state,
                info: action?.payload || { data: {} },
            };
        }
        case actionTypes.LOGIN_AD_FETCH: {
            return {
                ...state,
                info: action?.payload || { data: {} },
            };
        }
        case actionTypes.FETCH_TOKEN: {
            return {
                ...state,
                token: action?.payload || { data: {} },
            };
        }
        case actionTypes.FETCH_FORGOT_PASS: {
            return {
                ...state,
                forgotPass: action?.payload || { data: {} },
            };
        }

        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default LoginReducer;

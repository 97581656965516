import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import {
    ATMModal,
    ATMButton,
    ATMGrid,
    ATMField,
    ATMForm,
    ATMSelect,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './send-announcement.module.scss';
import {
    AnnouncementPostSchema,
    IAnnouncementPostSchema,
} from 'src/models/announcement.model';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useAnnouncementContext } from 'src/contexts/announcement.context';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
type IProps = {
    isOpen: boolean;
    handleClosePopUp: () => void;
    announcementData: any;
    fetchData: (params: IORGDataTableQueryState) => void;
    params: IORGDataTableQueryState;
};
const RequiredLabel = ({ children, ...props }) => (
    <label {...props} className={styles.required}>
        {children}
    </label>
);
const SendAnnouncement: React.FC<IProps> = ({
    isOpen,
    handleClosePopUp,
    announcementData,
    fetchData,
    params,
}) => {
    const resetRef = useRef<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const { state, actions } = useAnnouncementContext();
    const [isUpdateEnable, setIsUpdateEnable] = useState(false);
    const [isClearEnable, setIsClearEnable] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const [announcementCreateData, setAnnouncementCreateData] = useState(
        announcementData.list
    );
    const [error, setError] = useState<boolean>(false);
    const [resentMode, setResentMode] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [data, setData] = useState({
        sentToIds: '',
        duration: '',
        announcementTypeId: null,
        announcement: '',
    });

    useEffect(() => {
        // Object.keys(announcementData).length > 0
        //     ? setResentMode(true)
        //     : setResentMode(false);
        actions.getAnnouncementDropdownList();
    }, []);

    const handleSubmitConfirm = useCallback(async () => {
        setLoading(true);
        const response = await actions.sendAnnouncement(announcementCreateData);
        setLoading(false);
        response.payload?.status
            ? handleSuccess(response?.payload?.message)
            : handleError(response?.error?.message);
    }, [actions, announcementCreateData]);

    const handleSuccess = (msg) => {
        ToastSuccess(msg);
        setIsConfirm(false);
        fetchData(params);
        handleClosePopUp();
    };

    const handleError = (msg) => {
        ToastError(msg);
        setIsConfirm(false);
    };

    const handleClick = () => {
        if (formRef && formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleSubmit = useCallback(
        (formData: IAnnouncementPostSchema) => {
            setAnnouncementCreateData({
                ...formData,
                createdById: localStorage.getItem('loginCredential'),
            });
            setIsConfirm(true);
        },
        [isConfirm]
    );

    const handleClear = useCallback(() => {
        setData({
            sentToIds: '',
            duration: '',
            announcementTypeId: null,
            announcement: '',
        });
        setAnnouncementCreateData({});
        setIsClearEnable(false);
    }, [data, setData]);

    const handleChange = useCallback(
        async (fieldName, val) => {
            setAnnouncementCreateData({
                ...announcementCreateData,
                [fieldName]: val,
            });
            setIsClearEnable(true);
        },
        [announcementCreateData]
    );
    useEffect(() => {
        if (announcementCreateData) {
            if (Object.keys(announcementCreateData).length >= 4 && !error) {
                setIsUpdateEnable(true);
            } else {
                setIsUpdateEnable(false);
            }
        }
    }, [announcementCreateData, error]);

    const handleChangeMultiple = useCallback(
        async (values) => {
            setAnnouncementCreateData({
                ...announcementCreateData,
                sentToIds: values.join(),
            });
            setIsClearEnable(true);
        },
        [announcementCreateData]
    );
    const handleOnClose = () => {
        setIsConfirm(false);
    };
    const durationList = useMemo(
        () =>
            state.announcementDropdownList?.data?.duration?.map((type) => ({
                key: type?.id,
                value: type?.name,
                text: type?.name,
            })),
        [state.announcementDropdownList]
    );
    const sendToList = useMemo(
        () =>
            state.announcementDropdownList?.data?.sent_to?.map((type) => ({
                key: type?.communication_group_id,
                value: type?.communication_group_id,
                text: type?.communication_group_name,
            })),
        [state.announcementDropdownList]
    );
    const announcementTypeList = useMemo(
        () =>
            state.announcementDropdownList?.data?.announcement_type_Data?.map(
                (type) => ({
                    key: type?.announcement_type_id,
                    value: type?.announcement_type_id,
                    text: type?.announcement_type,
                })
            ),
        [state.announcementDropdownList]
    );

    return (
        <>
            {isConfirm && (
                <Confirm
                    open={isConfirm}
                    size="tiny"
                    content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                    loading={loading}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleSubmitConfirm()}
                />
            )}
            <ATMModal
                open={isOpen}
                onClose={handleClosePopUp}
                closeOnDimmerClick={false}
                size="small"
            >
                <ATMModal.Header as="h5" className={styles.modelHeader}>
                    <div>{Lang.LBL_SEND_ANNOUNCEMENTS}</div>
                </ATMModal.Header>
                <ATMModal.Content className={styles.wrapper}>
                    <ATMForm
                        ref={formRef}
                        validationSchema={AnnouncementPostSchema}
                        mode="onChange"
                        defaultValues={data}
                        onSubmit={handleSubmit}
                        className={styles.form}
                    >
                        {({ control, formState: { errors }, reset }) => {
                            resetRef.current = reset;
                            Object.keys(errors).length > 0
                                ? setError(true)
                                : setError(false);
                            return (
                                <>
                                    <ATMGrid columns={2}>
                                        <ATMGrid.Column>
                                            <div>
                                                <RequiredLabel>
                                                    {Lang.LBL_TO}
                                                </RequiredLabel>
                                            </div>
                                            <div>
                                                <ATMField
                                                    as={ATMSelect}
                                                    control={control}
                                                    name="sentToIds"
                                                    placeholder={
                                                        Lang.LBL_SELECT
                                                    }
                                                    error={errors.sentToIds}
                                                    options={sendToList || []}
                                                    disabled={resentMode}
                                                    onChange={([_, val]) => {
                                                        handleChangeMultiple(
                                                            val.value
                                                        );
                                                        return val.value;
                                                    }}
                                                    multiple
                                                />
                                                {/* // <ATMSelect
                                                    //     style={{ width: '100%' }}
                                                    //     control={control}
                                                    //     name="sentToIds"
                                                    //     placeholder={
                                                    //         Lang.LBL_SELECT
                                                    //     }
                                                    //     error={errors.sentToIds}
                                                    //     options={sendToList || []}
                                                    //     compact
                                                    //     disabled={resentMode}
                                                    //     onChange={(i, v) =>
                                                    //         handleChangeMultiple(
                                                    //             v.value
                                                    //         )
                                                    //     }
                                                    //     multiple
                                                    // /> */}
                                            </div>
                                        </ATMGrid.Column>
                                        <ATMGrid.Column>
                                            <RequiredLabel>
                                                {Lang.LBL_ANNOUCEMENT_TYPE}
                                            </RequiredLabel>
                                            <ATMField
                                                control={control}
                                                as={ATMSelect}
                                                name="announcementTypeId"
                                                error={
                                                    errors.announcementTypeId
                                                }
                                                placeholder={Lang.LBL_SELECT}
                                                options={
                                                    announcementTypeList || []
                                                }
                                                disabled={resentMode}
                                                onChange={([_, val]) => {
                                                    handleChange(
                                                        'announcementTypeId',
                                                        val.value
                                                    );
                                                    return val.value;
                                                }}
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid>
                                    <ATMGrid columns={2}>
                                        <ATMGrid.Column>
                                            <RequiredLabel>
                                                {Lang.LBL_ANNOUNCEMENT}
                                            </RequiredLabel>
                                            <ATMField
                                                style={{
                                                    minHeight: '6em',
                                                    height: '6em',
                                                }}
                                                type="text"
                                                control={control}
                                                as={'textarea'}
                                                name="announcement"
                                                error={errors.announcement}
                                                disabled={resentMode}
                                                onChange={([e]) => {
                                                    handleChange(
                                                        'announcement',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                        </ATMGrid.Column>
                                        <ATMGrid.Column>
                                            <RequiredLabel>
                                                {Lang.LBL_DURATION}
                                            </RequiredLabel>
                                            <ATMField
                                                control={control}
                                                as={ATMSelect}
                                                name="duration"
                                                error={errors.duration}
                                                placeholder={Lang.LBL_SELECT}
                                                options={durationList || []}
                                                disabled={resentMode}
                                                onChange={([_, val]) => {
                                                    handleChange(
                                                        'duration',
                                                        val.value
                                                    );
                                                    return val.value;
                                                }}
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid>
                                </>
                            );
                        }}
                    </ATMForm>
                </ATMModal.Content>
                <ATMModal.Actions>
                    <ATMButton
                        basic
                        color="blue"
                        onClick={() => {
                            handleClosePopUp();
                            setIsUpdateEnable(false);
                        }}
                    >
                        {Lang.LBL_CANCEL}
                    </ATMButton>
                    {!resentMode && (
                        <ATMButton
                            basic
                            color="blue"
                            onClick={() => {
                                handleClear();
                                resetRef.current();
                            }}
                            disabled={!isClearEnable}
                        >
                            {Lang.LBL_CLEAR}
                        </ATMButton>
                    )}

                    <ATMButton
                        primary
                        onClick={() => {
                            handleClick();
                        }}
                        disabled={resentMode ? false : !isUpdateEnable}
                        id="btnSubmit"
                    >
                        {resentMode ? Lang.LBL_RESEND : Lang.LBL_SEND}
                    </ATMButton>
                </ATMModal.Actions>
            </ATMModal>
        </>
    );
};

export default SendAnnouncement;

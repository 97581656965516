/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    ContractorSchema,
    ContractorUpdatePost,
    ContractorUpdatePostSchema,
    AdvisorAndContactUserSchema,
    AdminPrimeSchema,
    ReportingyearsGetSchema,
    AdvisorAndContactUserUpdateSchema,
} from '../models/contractor.model';
import { MassUpdateResponseSchema } from '../models/mass-update.model';

const client = httpClient();
export const services = {
    listGET: async (params) => {
        return client.get('/primeContractor', params, ContractorSchema);
    },
    replacePUT: async (contractData: Partial<ContractorUpdatePost>) => {
        const body: any = contractData;
        const removeKeys = [
            'isToggleAllow',
            'allow_agreements',
            'contact1_name',
            'contact2_name',
            'contact3_name',
        ];
        for (const key of removeKeys) {
            if (body[key] != undefined) {
                delete body[key];
            }
        }
        return client.put(
            '/primeContractor',
            {
                ...contractData,
            },
            ContractorUpdatePostSchema
        );
    },
    adminPrimeListGET: async (params) => {
        return client.get('/prime', params, AdminPrimeSchema);
    },
    advisorAndContactUserGET: async () => {
        return client.get(
            '/getDbeAndContactUserList',
            {},
            AdvisorAndContactUserSchema
        );
    },
    adminPrimeReplacePUT: async (
        contractData: Partial<ContractorUpdatePost>
    ) => {
        const body: any = contractData;
        if (body.isToggleAllow != undefined) {
            delete body.isToggleAllow;
        }
        return client.put(
            '/prime',
            {
                ...body,
            },
            AdvisorAndContactUserUpdateSchema
        );
    },
    adminPrimeReplacePOST: async (
        contractData: Partial<ContractorUpdatePost>
    ) => {
        const body: any = contractData;
        if (body.isToggleAllow != undefined) {
            delete body.isToggleAllow;
        }
        if (body.data != undefined) {
            delete body.data;
        }
        return client.post(
            '/prime',
            {
                ...contractData,
            },
            AdvisorAndContactUserUpdateSchema
        );
    },
    adminContactMassUpdatePut: async (params) => {
        return client.put(
            '/primeContactMassUpdate',
            {
                ...params,
            },
            MassUpdateResponseSchema
        );
    },
    primeAdvisorAndContactUserGET: async (params) => {
        return client.get(
            '/getPrimeSupplierDiversityContactAndContacts',
            { ...params },
            AdvisorAndContactUserSchema
        );
    },
    reportingyearsGet: async () => {
        return client.get('/reportingYears', {}, ReportingyearsGetSchema);
    },
};
export default services;

import React from 'react';
import {
    IndexRouteObject,
    NonIndexRouteObject,
    Navigate,
    useRoutes,
} from 'react-router-dom';
import { checkVisibility, IHasAccessType } from './access.library';
import { SemanticICONS } from 'semantic-ui-react';

export interface IRoute {
    title?: string;
    to?: string;
    icon?: SemanticICONS;
    index?: boolean;
    children?: IRoute[];
    href?: string;
    path?: string;
    element?: React.ReactNode | null;
    permission?: IHasAccessType; // For ACL
    access_role?: any;
}

type IDefaultPage = {
    Error404: React.ReactNode;
    Error403?: React.ReactNode;
};

const createRoute = (params: IRoute, DefaultPage?: React.ReactNode): IRoute => {
    const { element, path, access_role, index = false } = params;

    let component = DefaultPage;

    if (checkVisibility(access_role)) {
        component = element;
    }

    return {
        index,
        path,
        element: component || DefaultPage,
    };
};

const renderRouter = (route: IRoute, defaultPage: IDefaultPage) => {
    if (route.element) {
        const node: IRoute = {
            index: route.index,
            path: route.path,
            element: route.element,
            children: [],
        };

        if (route.path && route.to) {
            node.children?.push({
                path: route.path,
                element: <Navigate to={route.to} replace />,
            });
        }

        if (route.children?.length) {
            node.children = node.children?.concat(
                route.children.map((value) =>
                    renderRouter(
                        {
                            ...value,
                            permission: [
                                route.permission,
                                value.permission,
                            ].filter((val) => val !== undefined),
                        } as IRoute,
                        defaultPage
                    )
                )
            );
        }

        return node;
    }

    if (route.path && route.to) {
        return {
            index: route.index,
            path: route.path,
            element: <Navigate to={route.to} replace />,
        };
    }

    // Children component handler
    return createRoute(route, defaultPage.Error404);
};

const RouterElement: React.FC<{
    routes: IRoute[];
}> = ({ routes }) => {
    const newRoutes = routes.map((route) =>
        route.index
            ? ({ ...route } as IndexRouteObject)
            : ({ ...route } as NonIndexRouteObject)
    );
    return useRoutes(newRoutes);
};

const routerFactory = (routes: IRoute[], DefaultPage: IDefaultPage) => {
    const children = routes[0].children ?? [];
    children.push({ path: '*', element: DefaultPage.Error404 });
    routes[0].children = children;

    const result = routes.map((route) => renderRouter(route, DefaultPage));

    return <RouterElement routes={result} />;
};

export default routerFactory;

import * as Msal from '@azure/msal-browser';
import Lang from './language';
import { RefreshTokenPayloadSchema } from '../models/login.model';
import { httpClient } from 'src/libraries/http.library';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';

const clientId = process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID ?? '';

const getmsalInstance = () => {
    const msalConfig: Msal.Configuration = {
        auth: {
            clientId,
            authority: process.env.REACT_APP_ACTIVE_DIRECTORY_AUTHORITY ?? '',
            redirectUri:
                process.env.REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI ??
                window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
    };

    return Msal.PublicClientApplication.createPublicClientApplication(msalConfig);
};

const userScopes = ['User.Read', 'User.ReadBasic.All'];
export const logout = async () => {
    const user_type = getUserType();
    const client_id = localStorage.getItem('loginCredential');
    const loginUrl = localStorage.getItem('loginUrl');

    if (AccessRole.PRIME != user_type) {
        const msalInstance = await getmsalInstance();
        const logoutRequest = {
            onRedirectNavigate: (_) => {
                sessionStorage.clear();
            }
        } as Msal.RedirectRequest;
        msalInstance.loginRedirect(logoutRequest);
    } else {
        if (loginUrl && loginUrl === '/primelogin') {
            window.location.assign('/primelogin');
        } else {
            window.location.assign('/');
        }
    }
    localStorage.removeItem(`loginCredential`);
    localStorage.removeItem(`${client_id}_user_data`);
    localStorage.removeItem(`user_token`);
    localStorage.removeItem('primeData');
    localStorage.removeItem('prime_id');
    localStorage.clear();
};

export const getAccount = (msalInstance): Msal.AccountInfo | null => {
    const accounts = msalInstance?.getAllAccounts();
    if (accounts && accounts.length > 0) {
        return accounts[0];
    }
    return null;
};

export const adLogin = async (
    setIsVisible: (arg: { success: boolean; error: boolean }) => void,
    setToastMsg: (message: string) => void
): Promise<boolean> => {
    const loginRequest = {
        scopes: userScopes,
        prompt: 'select_account',
    };

    try {
        const msalInstance = await getmsalInstance();
        await msalInstance.loginPopup(loginRequest);
        const account = getAccount(msalInstance);
        if (account) {
            const accessTokenRequest = {
                scopes: ['user.read'],
                account: account,
            };
            return msalInstance
                .acquireTokenPopup(accessTokenRequest)
                .then(function (accessTokenResponse) {
                    const accessToken = accessTokenResponse.accessToken;
                    localStorage.setItem(`user_token`, accessToken);
                    return true;
                })
                .catch(function (error) {
                    setIsVisible({ success: false, error: true });
                    setToastMsg(error?.message);
                    setTimeout(() => {
                        setIsVisible({ success: false, error: false });
                    }, 30000);
                    return false;
                });
        } else {
            return false;
        }
    } catch (error) {
        console.log('login error', error);
        return false;
    }
};
// Get user details from active directory
export const ProfileContent = (): boolean => {
    return true;
};

// Get user details from microsoft graph server
export const getUser: any = () => {
    const client_id = localStorage.getItem('loginCredential');
    const user = localStorage.getItem(`${client_id}_user_data`);
    return user ? JSON.parse(user) : null;
};

export const getToken: any = () => {
    return localStorage.getItem(`user_token`) ?? null;
};

export const redirect: any = () => {
    //msalInstance.loginRedirect({ scopes: tokenScopes });
};

export const refreshToken: any = async () => {
    const client = httpClient();

    const response = await client.get(
        '/refreshToken',
        '',
        RefreshTokenPayloadSchema
    );
    if (
        response.data &&
        response.message === Lang.MSG_TOKEN_GENERATED_SUCCESS
    ) {
        localStorage.setItem(`user_token`, response.data);
        return true;
    } else {
        return false;
    }
};

// This will fetch the user details from microsoft graph server
// Please see https://developer.microsoft.com/en-us/graph/graph-explorer for the documentation
export const fetchUserData: any = async () => {
    return null;
};

export const hasAuth: any = async () => {
    return null;
};

export const removeSearchParams = (): void => {
    if (window.location.href.split('?').length > 1)
        window.history.pushState(
            {},
            '',
            window.location.href.split('?')[0]
            // htmlEncode(window.location.href.split('?')[0])
        );
};


import React, { useEffect, useState } from 'react';
import {
    ATMGrid,
    ATMForm,
    ATMModal,
    ATMButton,
    ATMField,
    ATMInput,
    IORGDataTableQueryState,
    ATMCheckbox,
    ATMSelect,
} from 'shared-it-appmod-ui';
import {
    IUpdateSubsSapId,
    IUpdateSubsSapIdTesting,
    updateSubsSapId,
} from 'src/models/manage-subcontractor.model';
import Lang from 'src/libraries/language';
import styles from './sapId-update.component.module.scss';

export type IProps = {
    isOpen: boolean;
    loading: boolean;
    defaultValues?: Partial<IUpdateSubsSapId>;
    fdata?: Partial<IUpdateSubsSapIdTesting>;
    editData?: Partial<IUpdateSubsSapIdTesting>;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    handleClosePopUp: () => void;
    handleClick: (params: IORGDataTableQueryState) => void;
    handleSubmit: () => void;
    handleChange: (key, data) => void;
    // setValue: (fieldName, value) => void;
    queryParam?: IORGDataTableQueryState;
    page?: string;
    limit?: string;
    reasonList: any;
};

const UpdateSapIdView: React.FC<IProps> = ({
    isOpen,
    loading,
    editData,
    formRef,
    handleClosePopUp,
    handleClick,
    handleChange,
    queryParam,
    page,
    limit,
    handleSubmit,
    reasonList,
}) => {
    const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(true);
    const [sapId, setsapId] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const [isReasonEnable, setIsReasonEnable] = useState<boolean | undefined>(
        editData?.hidden || false
    );
    const [currentReasonId, setCurrentReasonId] = useState<number | undefined>(
        editData?.reasonId
    );
    const [dropDownError, setDropdownError] = useState(false);

    useEffect(() => {
        setsapId(editData?.sap_id || '');
        setIsReasonEnable(editData?.hidden);
        setCurrentReasonId(editData?.reasonId);
        setIsUpdateEnable(true);
    }, [editData]);

    useEffect(() => {
        if (
            sapId === editData?.sap_id &&
            isReasonEnable &&
            currentReasonId != editData.reasonId
        ) {
            setIsUpdateEnable(false);
        } else if (sapId !== '' && sapId !== editData?.sap_id) {
            if (error) {
                setIsUpdateEnable(true);
            } else if (
                isReasonEnable &&
                currentReasonId === editData?.reasonId
            ) {
                setIsUpdateEnable(true);
            } else {
                setIsUpdateEnable(false);
            }
        } else {
            setIsUpdateEnable(true);
        }
    }, [sapId, currentReasonId, isReasonEnable, error]);

    return (
        <ATMModal
            open={isOpen}
            onClose={handleClosePopUp}
            closeOnDimmerClick={false}
            size="small"
        >
            <ATMModal.Header className={styles.modelHeader}>
                <div>{Lang.TTL_SAPID_EDIT_UPDATE}</div>
            </ATMModal.Header>
            <ATMModal.Content className={styles.subContractorWrapperView}>
                <ATMForm
                    ref={formRef}
                    validationSchema={updateSubsSapId}
                    mode="onChange"
                    defaultValues={editData}
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    {({ control, formState: { errors } }) => {
                        errors.sap_id ? setError(true) : setError(false);
                        return (
                            <>
                                <ATMGrid columns={2}>
                                    <ATMGrid.Column>
                                        <label>
                                            {
                                                Lang.LBL_UPDATE_SAPID_SUBCONTRACTOR_NAME
                                            }
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="subcontractor_name"
                                            error={errors.subcontractor_name}
                                            disabled
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column>
                                        <label>
                                            {Lang.LBL_UPDATE_SAPID_VON}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="verification_number"
                                            error={errors.verification_number}
                                            disabled
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid>
                                <ATMGrid columns={2}>
                                    <ATMGrid.Column>
                                        <label className={styles.required}>
                                            {Lang.LBL_UPDATE_SAPID}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="sap_id"
                                            error={errors.sap_id}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'sap_id',
                                                    val.value
                                                );
                                                setsapId(val.value);
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column>
                                        <ATMCheckbox
                                            label={
                                                Lang.LBL_SUBCONTRACTOR_INACTIVATE
                                            }
                                            className={styles.activeInactive}
                                            control={control}
                                            name="hidden"
                                            defaultChecked={editData?.hidden}
                                            onChange={(_, val) => {
                                                if (!val.checked) {
                                                    setDropdownError(false);
                                                }
                                                handleChange(
                                                    'hidden',
                                                    val.checked
                                                );
                                                setIsReasonEnable(val.checked);
                                                return val.checked;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid>
                                <ATMGrid columns={2}>
                                    <ATMGrid.Column>
                                        <label className={styles.required}>
                                            {
                                                Lang.LBL_SUBCONTRACTOR_INACTIVATION_REASON
                                            }
                                        </label>
                                        <ATMField
                                            className={styles.formField}
                                            control={control}
                                            as={ATMSelect}
                                            options={
                                                isReasonEnable
                                                    ? reasonList?.data?.map(
                                                          (reasonItem) => {
                                                              return {
                                                                  key: reasonItem.Reason_Id,
                                                                  value: reasonItem.Reason_Id,
                                                                  text: reasonItem.Reason,
                                                              };
                                                          }
                                                      )
                                                    : []
                                            }
                                            name="reasonId"
                                            onChange={(e) => {
                                                setDropdownError(false);
                                                handleChange(
                                                    'reasonId',
                                                    e[1].value || false
                                                );
                                                setCurrentReasonId(e[1].value);
                                                return e[1].value;
                                            }}
                                            placeholder="Select Reason"
                                            error={
                                                errors.Reason_id ||
                                                (dropDownError &&
                                                    isReasonEnable)
                                            }
                                            disabled={!isReasonEnable}
                                        />
                                        {dropDownError && isReasonEnable && (
                                            <div
                                                className={`ui pointing label basic contracts ${styles.tooltip}`}
                                                style={{
                                                    width: 'max-content',
                                                    marginLeft: -21,
                                                    marginTop: 10,
                                                }}
                                            >
                                                {String(
                                                    Lang.ERROR.SUBCONTRACTOR
                                                        .INACTIVATION_REASON_REQUIRED
                                                )}
                                            </div>
                                        )}
                                    </ATMGrid.Column>
                                </ATMGrid>
                            </>
                        );
                    }}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton
                    basic
                    color="blue"
                    onClick={() => {
                        handleClosePopUp();
                        setIsUpdateEnable(true);
                    }}
                >
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={() => {
                        handleClick({
                            ...queryParam,
                            page: parseInt(page ?? '1'),
                            limit: parseInt(limit ?? '10'),
                        });
                        setIsUpdateEnable(true);
                    }}
                    loading={loading}
                    disabled={isUpdateEnable}
                    id="btnSubmit"
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
};

export default UpdateSapIdView;

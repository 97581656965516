import React from 'react';
import {
    ORGDataTable,
    IORGDataTableColumn,
    ATMHeader,
    ATMMessage,
    ATMInput,
    ATMButton,
} from 'shared-it-appmod-ui';
import { Container } from 'semantic-ui-react';

import { IUpdateSubcontractorVonsListPayload } from 'src/models/manage-subcontractor.model';
import Lang from 'src/libraries/language';
import Style from './update-subcontractor-vons.module.scss';

type IProps = {
    list: Partial<IUpdateSubcontractorVonsListPayload>;
    fetchData: (data) => void;
    loading: boolean;
    handleOpen: (open) => void;
    handleOpenAuditLogs: () => void;
    searchRef: any;
    setSubContractName: (data) => void;
    setVerificationNumber: (data) => void;
    handleChangeSearch: (key, data) => void;
    handleSearch: () => void;
    handleClear: () => void;
    handleDelete: (data) => void;
};

const ContactPrimesListView: React.FC<IProps> = ({
    list,
    fetchData,
    loading,
    handleOpen,
    handleOpenAuditLogs,
    searchRef,
    handleChangeSearch,
    handleSearch,
    handleClear,
    setSubContractName,
    setVerificationNumber,
    handleDelete,
}) => {
    const columns: IORGDataTableColumn<any>[] = [
        {
            title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_SUBCONTRACTOR,
            index: 'name',
            render: (_, value) => <span>{value.name || '-'}</span>,
            headerProps: {
                className: Style.subcontractor,
            },
        },
        {
            title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_VON,
            index: 'verificationNumber',
            render: (_, value) => (
                <span>{value.verificationNumber || '-'}</span>
            ),
            headerProps: {
                className: Style.von,
            },
        },
        {
            title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_SELECTED_BY_PRIME,
            index: 'primename',
            render: (_, value) => <span>{value?.primename || '-'}</span>,
            headerProps: {
                className: Style.prime,
            },
        },
        {
            title: Lang.TTL_ACTION,
            index: 'actions',
            sortable: false,
            render: (_, value) => (
                <>
                    <span>
                        <a
                            style={{ cursor: 'pointer', padding: '0' }}
                            className="ui icon button"
                        >
                            <i
                                className="edit icon"
                                style={{
                                    background: 'transparent',
                                    color: 'grey',
                                }}
                                onClick={() => handleOpen(value)}
                            ></i>
                        </a>
                    </span>
                    <span>
                        <a
                            style={{ cursor: 'pointer', padding: '0' }}
                            className="ui icon button"
                        >
                            <i
                                className="trash alternate icon"
                                style={{
                                    background: 'transparent',
                                    color: 'grey',
                                }}
                                onClick={() => handleDelete(value)}
                            ></i>
                        </a>
                    </span>
                </>
            ),
            headerProps: {
                className: Style.edit,
            },
        },
    ];
    return (
        <Container fluid className={Style.wrapper}>
            <ATMHeader as="h1" style={{ marginTop: '10px' }}>
                {Lang.TTL_MANAGE_SUBCONTRACTORS}
                <ATMButton
                    className="ui primary button"
                    style={{ padding: '5px 10px', float: 'right' }}
                    onClick={() => handleOpenAuditLogs()}
                >
                    Audit Logs
                </ATMButton>
            </ATMHeader>

            <ATMMessage
                style={{
                    height: '86px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <label>{Lang.LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS}</label>
                    <ATMInput
                        className={Style.inputSectionVon}
                        placeholder={
                            Lang.LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS
                        }
                        name="subcontractor_name"
                        onChange={(_, val) => {
                            handleChangeSearch('subcontractor_name', val.value);
                            setSubContractName(val.value);
                        }}
                        value={
                            searchRef.current?.subcontractor_name
                                ? searchRef.current.subcontractor_name
                                : ''
                        }
                    ></ATMInput>
                    <label className={Style.inputLabel}>
                        {Lang.LBL_VIEW_ALL_SUBS_VON}
                    </label>
                    <ATMInput
                        className={Style.inputSection}
                        placeholder={Lang.PLACEHOLDER.VIEW_ALL_SUBS_ENTER_VON}
                        name="verification_number"
                        onChange={(_, val) => {
                            handleChangeSearch(
                                'verification_number',
                                val.value
                            );
                            setVerificationNumber(val.value);
                        }}
                        value={
                            searchRef.current?.verification_number
                                ? searchRef.current.verification_number
                                : ''
                        }
                    ></ATMInput>
                </div>
                <div>
                    <ATMButton
                        className="ui secondary button"
                        style={{ marginRight: '20px' }}
                        onClick={() => handleClear()}
                    >
                        {Lang.LBL_CLEAR}
                    </ATMButton>
                    <ATMButton
                        className="ui primary button"
                        onClick={() => handleSearch()}
                    >
                        {Lang.LBL_SEARCH}
                    </ATMButton>
                </div>
            </ATMMessage>
            <ORGDataTable
                celled={false}
                columns={columns}
                data={list?.data || []}
                loading={loading}
                sortable
                toolbar
                counter
                noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                onChange={fetchData}
                total={list?.total_rows || 0}
                rowsPerPageOptions={[10, 20, 25]}
                key={list?.total_rows || 0}
            />
        </Container>
    );
};

export default ContactPrimesListView;

import React, { useCallback, useState } from 'react';
import { ATMSegment, ATMContainer } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import ContractorInfo from 'src/components/pages/contractor/contractor-info/contractor-info.component';
import SubcontractorList from 'src/components/pages/subcontractor/subcontractor-list/subcontractor-list.component';
import Contracts from 'src/components/pages/contracts/contracts.component';
import UpdateSpend from 'src/components/pages/update-spend/update-spend.component';
import ResetPassword from 'src/components/pages/admin/reset-password/reset-password.component';
import ContactPrimesDetailView from './primes-detail.view';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'src/libraries/users.library';
import InvalidContracts from '../invalid-contracts/invalid-contracts.component';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

const createPane = (menuName: string, Component: any, forceUpdate: any) => ({
    menuItem: menuName,
    render: () => (
        <ATMSegment>
            <Component onReload={forceUpdate ? forceUpdate : undefined} />
        </ATMSegment>
    ),
});

const ContactPrimesDetail: React.FC = () => {
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const isUserAdmin = isAdmin();

    const contactPanes = [
        createPane(
            Lang.TTL_CONTACT_PRIME_CONTRACTOR_INFO,
            ContractorInfo,
            forceUpdate
        ),
        createPane(
            Lang.TTL_CONTACT_VIEW_SUBCONTRACTORS,
            SubcontractorList,
            undefined
        ),
        createPane(Lang.TTL_CONTACT_VIEW_CONTRACTS, Contracts, undefined),
        createPane(Lang.TTL_CONTACT_VIEW_SPEND_DATA, UpdateSpend, undefined),
        createPane(
            Lang.TTL_CONTACT_VIEW_INVALID_CONTRACTS,
            InvalidContracts,
            undefined
        ),
    ];

    const adminPanes = [
        ...contactPanes,
        createPane(Lang.LBL_RESET_PASSWORD, ResetPassword, undefined),
    ];

    const handleBack = useCallback(() => {
        navigate('/primes');
    }, []);

    return (
        <>
            <ATMContainer className="fluid">
                <ContactPrimesDetailView
                    tabs={
                        <Tabs
                            menu={{
                                fluid: false,
                                vertical: false,
                                pointing: true,
                                compact: true,
                                size: 'small',
                            }}
                            panes={isUserAdmin ? adminPanes : contactPanes}
                            segment="basic"
                        />
                    }
                    handleBack={handleBack}
                />
            </ATMContainer>
        </>
    );
};

export default ContactPrimesDetail;

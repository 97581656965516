import React, { Fragment } from 'react';
import { Container } from 'semantic-ui-react';
import {
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMSegment,
    ATMHeader,
    ATMMessage,
    ATMButton,
    ATMInput,
    ATMSelect,
    ATMGrid,
    ATMContainer,
    ATMField,
    ATMToggle,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './update-subcontractor-sapId.module.scss';
import moment from 'moment';

export type IProps = {
    data: any;
    loading: boolean;
    handleEdit: (data) => void;
    fetchData: (params: IORGDataTableQueryState) => void;
    // handleDismiss: () => void;
    totalRecord: number;
    searchRef: any;
    setSubContractName: (data) => void;
    setVerificationNumber: (data) => void;
    setPrimeName: (data) => void;
    setSapID: (data) => void;
    handleChangeSearch: (key, data) => void;
    handleSearch: () => void;
    handleClear: () => void;
    handleOpenAuditLogs: () => void;
    displayZeroSpend: (event) => void;
    generateReport: () => void;
    zeroReport: boolean;
};

type IColumnProps = {
    subcontractor_name: string;
    verification_number: string;
    expiration_date: string;
    prime_name: string;
    yearly_spend_amount: string;
    sap_id: number;
};
const UpdateSubcontractorSapIdView: React.FC<IProps> = ({
    data,
    loading,
    fetchData,
    handleEdit,
    totalRecord,
    searchRef,
    handleChangeSearch,
    handleSearch,
    handleClear,
    setSubContractName,
    setVerificationNumber,
    setPrimeName,
    setSapID,
    handleOpenAuditLogs,
    displayZeroSpend,
    generateReport,
    zeroReport,
}) => {
    const columns: IORGDataTableColumn<IColumnProps>[] = [
        {
            title: Lang.LBL_UPDATE_SAPID_SUBCONTRACTOR_NAME,
            index: 'subcontractor_name',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.subcontractor_name ? value.subcontractor_name : '-'}
                </span>
            ),
            headerProps: {
                className: style.subcontractorName,
            },
        },
        {
            title: Lang.LBL_UPDATE_SAPID_VON,
            index: 'verificationNumber',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.verification_number
                        ? value.verification_number
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.von,
            },
        },
        {
            title: Lang.LBL_UPDATE_SAPID_EXPIRY_DATE,
            index: 'verificationExpirationDate',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.expiration_date
                        ? moment(value.expiration_date)
                              .zone(0)
                              .format('MM/DD/YYYY')
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.expDate,
            },
        },
        {
            title: Lang.LBL_UPDATE_SAPID_SELECTEDBY_PRIME,
            index: 'prime_name',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.prime_name ? value.prime_name : '-'}
                </span>
            ),
            headerProps: {
                className: style.primeName,
            },
        },
        {
            title: Lang.LBL_UPDATE_SAPID_SPEND,
            index: 'sumSpendAmt',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.yearly_spend_amount
                        ? value.yearly_spend_amount
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.spendAmt,
            },
        },
        {
            title: Lang.LBL_UPDATE_SAPID,
            index: 'sap_id',
            render: (_, value) => (
                <span
                    className={style.rowData}
                    style={{ position: 'relative', left: '-5px' }}
                >
                    {value.sap_id > 0 && value.sap_id !== null
                        ? value.sap_id
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.sapId,
            },
        },
        {
            title: Lang.LBL_EDIT,
            index: 'actions',
            sortable: false,
            width: '5',
            render: (_, value) => (
                <span className={style.rowData}>
                    <a
                        style={{ cursor: 'pointer', padding: '0' }}
                        className="ui icon button"
                    >
                        <i
                            className="edit icon"
                            style={{
                                background: 'transparent',
                                color: 'grey',
                            }}
                            onClick={() => handleEdit(value)}
                        ></i>
                    </a>
                </span>
            ),
            headerProps: {
                className: style.action,
            },
        },
    ];
    return (
        <>
            <Container fluid>
                <ATMHeader as="h1" style={{ marginTop: '10px' }}>
                    {Lang.TTL_MANAGE_SUBCONTRACTORS}

                    <ATMButton
                        className="ui primary button"
                        style={{ padding: '5px 10px', float: 'right' }}
                        onClick={() => handleOpenAuditLogs()}
                    >
                        Audit Logs
                    </ATMButton>
                </ATMHeader>
                {/* <ATMMessage
                    style={{
                        height: '86px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                > */}
                <ATMContainer fluid>
                    <ATMGrid
                        columns={5}
                        className={style.filters}
                        doubling
                        stackable
                    >
                        <ATMGrid.Row>
                            <ATMGrid.Column>
                                <label style={{ display: 'table-cell' }}>
                                    {Lang.LBL_SUBCONTRACTOR}
                                </label>
                                <ATMInput
                                    // className={style.inputSectionVon}
                                    style={{ width: '185px' }}
                                    placeholder={
                                        Lang.PLACEHOLDER
                                            .VIEW_ALL_SUBS_ENTER_SUBCONTRACTOR_NAME
                                    }
                                    name="subcontractor_name"
                                    onChange={(_, val) => {
                                        handleChangeSearch(
                                            'subcontractor_name',
                                            val.value
                                        );
                                        setSubContractName(val.value);
                                    }}
                                    value={
                                        searchRef.current?.subcontractor_name
                                            ? searchRef.current
                                                  .subcontractor_name
                                            : ''
                                    }
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column>
                                <label style={{ display: 'table-cell' }}>
                                    {Lang.LBL_VIEW_ALL_SUBS_VON}
                                </label>
                                <ATMInput
                                    // className={style.inputSection}
                                    placeholder={
                                        Lang.PLACEHOLDER.VIEW_ALL_SUBS_ENTER_VON
                                    }
                                    name="verification_number"
                                    onChange={(_, val) => {
                                        handleChangeSearch(
                                            'verification_number',
                                            val.value
                                        );
                                        setVerificationNumber(val.value);
                                    }}
                                    value={
                                        searchRef.current?.verification_number
                                            ? searchRef.current
                                                  .verification_number
                                            : ''
                                    }
                                ></ATMInput>
                            </ATMGrid.Column>
                            <ATMGrid.Column>
                                <label style={{ display: 'table-cell' }}>
                                    {Lang.LBL_UPDATE_SAPID_SELECTEDBY_PRIME}
                                </label>
                                <ATMInput
                                    // className={style.inputSection}
                                    placeholder={
                                        Lang.PLACEHOLDER
                                            .VIEW_ALL_SUBS_ENTER_PRIME_NAME
                                    }
                                    name="prime_name"
                                    onChange={(_, val) => {
                                        handleChangeSearch(
                                            'prime_name',
                                            val.value
                                        );
                                        setPrimeName(val.value);
                                    }}
                                    value={
                                        searchRef.current?.prime_name
                                            ? searchRef.current.prime_name
                                            : ''
                                    }
                                ></ATMInput>
                            </ATMGrid.Column>
                            <ATMGrid.Column>
                                <label style={{ display: 'table-cell' }}>
                                    {Lang.LBL_UPDATE_SAPID}
                                </label>
                                <ATMInput
                                    // className={style.inputSection}
                                    placeholder={
                                        Lang.PLACEHOLDER
                                            .VIEW_ALL_SUBS_ENTER_SAP_ID
                                    }
                                    name="sap_id"
                                    onChange={(_, val) => {
                                        handleChangeSearch('sap_id', val.value);
                                        setSapID(val.value);
                                    }}
                                    value={
                                        searchRef.current?.sap_id
                                            ? searchRef.current.sap_id
                                            : ''
                                    }
                                ></ATMInput>
                            </ATMGrid.Column>
                            <ATMGrid.Column>
                                <label style={{ display: 'table-cell' }}>
                                    &nbsp;
                                </label>

                                <ATMButton
                                    className="ui secondary button"
                                    style={{ marginRight: '20px' }}
                                    onClick={() => handleClear()}
                                >
                                    {Lang.LBL_CLEAR}
                                </ATMButton>
                                <ATMButton
                                    // disabled={zeroReport}
                                    className="ui primary button"
                                    onClick={() => handleSearch()}
                                >
                                    {Lang.LBL_SEARCH}
                                </ATMButton>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMContainer>

                <ATMContainer fluid>
                    <ATMGrid
                        columns={6}
                        className={style.filters}
                        doubling
                        stackable
                    >
                        <ATMGrid.Row>
                            <ATMGrid.Column
                                width="5"
                                style={{ position: 'relative' }}
                            >
                                <label style={{ display: 'flex' }}>
                                    Display Spend Recorded Against
                                    Subcontractors with SAP ID = - or 0
                                </label>
                            </ATMGrid.Column>
                            <ATMGrid.Column>
                                <ATMField
                                    id="ATMToggle01"
                                    as={ATMToggle}
                                    name="active"
                                    style={{ marginLeft: '15px' }}
                                    defaultChecked={zeroReport}
                                    onChange={(event) => {
                                        displayZeroSpend(zeroReport);
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column
                                textAlign="left"
                                style={{ position: 'relative' }}
                            >
                                <ATMButton
                                    className="ui primary button"
                                    style={{
                                        padding: '5px 10px',
                                        position: 'absolute',
                                        top: '0',
                                    }}
                                    disabled={!zeroReport}
                                    onClick={() => generateReport()}
                                >
                                    Generate Report
                                </ATMButton>
                            </ATMGrid.Column>
                            {/* <ATMGrid.Column width="1" style={{paddingLeft: '0'}}>
                                
                            </ATMGrid.Column> */}
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMContainer>
                {/* </ATMMessage> */}
                <ATMSegment>
                    <ORGDataTable
                        celled={false}
                        columns={columns}
                        data={data}
                        loading={loading}
                        sortable
                        toolbar
                        counter
                        noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                        onChange={fetchData}
                        total={totalRecord}
                        key={totalRecord}
                        rowsPerPageOptions={[10, 20, 25]}
                    />
                </ATMSegment>
            </Container>
        </>
    );
};

export default UpdateSubcontractorSapIdView;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    ATMModal,
    ATMButton,
    ATMGrid,
    ATMInput,
    ATMCheckbox,
    ATMField,
    ATMSelect,
    ATMDropdown,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './contracts.module.scss';
import { useSpendContext } from 'src/contexts/spend.context';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import { changePO } from './utils';
import Instruction from 'src/components/atoms/instruction/instruction.component';

const ALPHANUMERIC_REGEX = /^[A-Za-z0-9 ]*$/;

export const changeDesc: any = (
    value,
    setDescription,
    setDescriptionError,
    setDescriptionErrorText
) => {
    setDescription(value);
    if (value == '') {
        setDescriptionErrorText(
            String(
                Lang.formatString(
                    Lang.ERROR.COMMON.REQUIRED,
                    'Project Description'
                )
            )
        );
        setDescriptionError(true);
    }
    const lenAddress = value?.length ?? 0;
    if (value != '' && lenAddress > 512) {
        setDescriptionErrorText(
            String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'Project Description',
                    '512'
                )
            )
        );
        setDescriptionError(true);
    }

    if (value != '' && !ALPHANUMERIC_REGEX.test(value ?? '')) {
        setDescriptionErrorText(
            String(
                Lang.formatString(
                    Lang.ERROR.COMMON.ALPHANUMERIC,
                    'Project Description'
                )
            )
        );
        setDescriptionError(true);
    }
    if (
        value != '' &&
        lenAddress < 30 &&
        ALPHANUMERIC_REGEX.test(value ?? '')
    ) {
        setDescriptionError(false);

        setDescriptionErrorText('');
    }
};

export const changeUtility: any = (
    value,
    setUtility,
    setUtilityError,
    setUtilityErrorMessage
) => {
    setUtility(value);
    if (value) {
        setUtilityError(false);
        setUtilityErrorMessage('');
    } else {
        setUtilityError(true);
        setUtilityErrorMessage('Utility is required field');
    }
};

export const cancel: any = (
    setPoError,
    setDescriptionError,
    setPoNumber,
    setDescription,
    handleModalClose,
    setChange,
    setUtility,
    setUtilityError
) => {
    setPoError(false);
    setDescriptionError(false);
    setPoNumber('');
    setDescription('');
    handleModalClose();
    setChange(false);
    setUtility('');
    setUtilityError(false);
};

export const clear: any = (
    type1,
    setPoNumber,
    setDescription,
    setPoError,
    setDescriptionError,
    handleModalClear,
    setChange,
    setUtility,
    setUtilityError
) => {
    if (type1 === 'add') {
        setPoNumber('');
        setDescription('');
        setPoError(false);
        setDescriptionError(false);
        handleModalClear();
        setChange(false);
        setUtility('');
        setUtilityError(false);
    } else if (type1 === 'edit') {
        setDescriptionError(false);
        handleModalClear();
        setChange(false);
        setUtilityError(false);
    }
};

export const done: any = (
    type,
    setChange,
    handleModalDone,
    verifyContracts,
    poNumber,
    utility
) => {
    if (type === 'edit') {
        setChange(false);
        handleModalDone();
    } else if (type === 'add') {
        verifyContracts(poNumber, utility);
    }
};
interface IDialogProps {
    handleModalClose: () => void;
    handleModalClear: () => void;
    handleModalDone: () => void;
    changeObject: (object) => void;
    type: string;
    open: boolean;
    editObject?: any;
    utilityData: [] | any;
}

const getUtilityOptions = (data) => {
    const options =
        data?.map((i) => ({
            key: i.Sempra_Company_ID,
            value: i.Sempra_Company_ID,
            text: i.Sempra_Company_Name,
        })) || [];
    return options;
};

export const Dialog: React.FC<IDialogProps> = ({
    handleModalClose,
    handleModalClear,
    handleModalDone,
    changeObject,
    type,
    open,
    editObject,
    utilityData,
}) => {
    const [poError, setPoError] = useState(false);
    const [poErrorMessage, setPoErrorMessage] = useState('');
    const [poNumber, setPoNumber] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorText, setDescriptionErrorText] = useState('');
    const [description, setDescription] = useState('');
    const [utilityError, setUtilityError] = useState(false);
    const [utilityErrorMessage, setUtilityErrorMessage] = useState('');
    const [utility, setUtility] = useState('');
    const [change, setChange] = useState(false);
    const { state, actions } = useSpendContext();
    const { actions: cActions } = useContractorPageContext();

    const po = useRef(null);
    const utilityRef = useRef(null);

    const utilityOptions = getUtilityOptions(utilityData);

    const fetchContracts = useCallback(async () => {
        await actions.listPRIMECONTRACTS({
            vendor_id: localStorage.getItem('loginCredential'),
        });
    }, [actions]);

    const verifyContracts = useCallback(
        async (contractNumber, utilityId) => {
            const response = await cActions.verifyPOST({
                contract_number: contractNumber,
                utility_id: utilityId,
                vendor_id: localStorage.getItem('loginCredential'),
            });
            if (response.error) {
                if (response.error?.data?.errorField) {
                    const errorField = response.error?.data?.errorField;
                    if (errorField === 'PO') {
                        setPoError(true);
                        setPoErrorMessage('Not a valid Contract #');
                    }
                    if (errorField === 'Utility') {
                        setUtilityError(true);
                        setUtilityErrorMessage(
                            'Selected Utility does not match with the Utility mentioned in the PO'
                        );
                    }
                } else {
                    setPoError(true);
                    setPoErrorMessage('Not a valid Contract #');
                }
            } else {
                setPoError(false);
                setPoErrorMessage('');
                setUtilityError(false);
                setUtilityErrorMessage('');
                setChange(false);
                handleModalDone();
            }
        },
        [actions]
    );

    useEffect(() => {
        if (open) {
            fetchContracts();
        }
    }, [open]);

    const checkEditDisabled = () => {
        return !change;
    };
    return (
        <ATMModal size="small" open={open} className="dim">
            <ATMModal.Header className={style.modalHeader}>
                <div>
                    {type === 'edit'
                        ? Lang.LBL_EDIT_UPDATE_CONTRACT
                        : Lang.LBL_ADD_CONTRACT}
                </div>
                <div
                    style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                    }}
                >
                    (<span style={{ color: '#D01919' }}>*</span>{' '}
                    {Lang.LBL_MONDATORY_FIELD})
                </div>
            </ATMModal.Header>
            <ATMModal.Content>
                <ATMGrid className={style.modalContent}>
                    {type === 'add--' && (
                        <ATMGrid.Row>
                            <ATMGrid.Column>
                                <Instruction
                                    title={Lang.LBL_NOTE}
                                    content={
                                        'In case you dont see your contract in the dropdown and it is not already added by you, please contact the ADMIN'
                                    }
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    )}
                    <ATMGrid.Row
                        className={style.modalRow}
                        style={{ paddingBottom: '0px' }}
                    >
                        <ATMGrid.Column
                            className={style.modalCol}
                            style={{
                                paddingBottom: '0px',
                                fontWeight: 'bold',
                                color: '#333333',
                                fontSize: 13,
                            }}
                        >
                            Contract&nbsp;
                            {type === 'add' && (
                                <span className={style.red}>*</span>
                            )}
                        </ATMGrid.Column>
                        <ATMGrid.Column
                            className={style.modalCol}
                            style={{
                                paddingBottom: '0px',
                                fontWeight: 'bold',
                                color: '#333333',
                                fontSize: 13,
                            }}
                        >
                            {Lang.LBL_CONTRACTS_PROJECT_DESC}
                            &nbsp;
                            <span className={style.red}>*</span>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row>
                        <ATMGrid.Column
                            className={style.modalCol}
                            style={{
                                paddingBottom: 0,
                                paddingTop: -10,
                                marginTop: -10,
                            }}
                        >
                            {type === 'edit' && (
                                <ATMInput
                                    fluid
                                    ref={po}
                                    error={poError}
                                    placeholder={'Contract'}
                                    defaultValue={
                                        editObject?.contract_number
                                            ? String(
                                                  editObject?.contract_number
                                              )
                                            : ''
                                    }
                                    disabled={type === 'edit'}
                                    maxLength="10"
                                    onChange={(e) => {
                                        changePO(
                                            e.target.value,
                                            setPoNumber,
                                            state,
                                            setPoError,
                                            setPoErrorMessage
                                        );
                                        changeObject((prev) => {
                                            prev['contract_number'] =
                                                e.target.value;
                                            return prev;
                                        });
                                    }}
                                ></ATMInput>
                            )}
                            {type != 'edit' && (
                                <ATMDropdown
                                    // className="ddDefault"
                                    placeholder={'Contract'}
                                    selection
                                    disabled={type === 'edit'}
                                    search
                                    options={state?.primeContracts?.data?.map(
                                        (v) => ({
                                            key: v?.contract_number,
                                            value: v?.contract_number,
                                            text: v?.contract_number,
                                        })
                                    )}
                                    onChange={(i, e) => {
                                        changePO(
                                            e.value,
                                            setPoNumber,
                                            state,
                                            setPoError,
                                            setPoErrorMessage
                                        );

                                        state?.primeContracts?.data
                                            ?.filter(
                                                (primeContract) =>
                                                    primeContract?.contract_number ==
                                                    e.value
                                            )
                                            .map((contractData) => {
                                                setChange(true);
                                                changeUtility(
                                                    contractData?.Sempra_Company_ID,
                                                    setUtility,
                                                    setUtilityError,
                                                    setUtilityErrorMessage
                                                );
                                                changeObject((prev) => {
                                                    prev['contract_number'] =
                                                        e.value;
                                                    prev['utility_id'] =
                                                        contractData?.Sempra_Company_ID;
                                                    return prev;
                                                });
                                            });
                                    }}
                                    style={{
                                        width: '100%',
                                        marginRight: 15,
                                        marginBottom: 5,
                                        height: 'fit-content',
                                    }}
                                />
                            )}
                            {poError && (
                                <div
                                    className={`ui pointing basic label contracts ${style.tooltip}`}
                                >
                                    {poErrorMessage}
                                </div>
                            )}
                        </ATMGrid.Column>
                        <ATMGrid.Column
                            className={style.modalCol}
                            style={{
                                paddingBottom: 0,
                                paddingTop: -10,
                                marginTop: -10,
                            }}
                        >
                            <ATMInput
                                fluid
                                placeholder={
                                    Lang.CONTRACTS_ENTER_PROJECT_DESCRIPTION
                                }
                                defaultValue={editObject?.description}
                                onChange={(e) => {
                                    setChange(true);
                                    changeObject((prev) => {
                                        prev['description'] = e.target.value;
                                        return prev;
                                    });
                                    changeDesc(
                                        e.target.value,
                                        setDescription,
                                        setDescriptionError,
                                        setDescriptionErrorText
                                    );
                                }}
                                style={{ marginBottop: '0px' }}
                                error={descriptionError}
                            ></ATMInput>
                            {descriptionError && (
                                <div
                                    className={`ui pointing label basic contracts ${style.tooltipErrorDesc}`}
                                    style={{
                                        width: 'max-content',
                                    }}
                                >
                                    {descriptionErrorText}
                                </div>
                            )}
                        </ATMGrid.Column>
                    </ATMGrid.Row>

                    {/* <ATMGrid.Row>&nbsp;</ATMGrid.Row> */}
                    <div style={{ height: '17px' }}></div>
                    <ATMGrid.Row
                        className={style.modalRow}
                        style={{ paddingBottom: '0px' }}
                    >
                        <ATMGrid.Column
                            className={style.modalCol}
                            style={{
                                paddingBottom: '0px',
                                fontWeight: 'bold',
                                color: '#333333',
                                fontSize: 13,
                            }}
                        >
                            Utility&nbsp;
                            <span className={style.red}>*</span>
                        </ATMGrid.Column>
                        <ATMGrid.Column />
                    </ATMGrid.Row>
                    <ATMGrid.Row>
                        <ATMGrid.Column
                            className={style.modalCol}
                            style={{
                                paddingBottom: 0,
                                paddingTop: -10,
                                marginTop: -10,
                            }}
                        >
                            {/* <ATMSelect
                                fluid
                                ref={utilityRef}
                                error={utilityError}
                                placeholder={'Select Utility'}
                                defaultValue={editObject?.utilityID}
                                name="utilityId"
                                //readonly={true}
                                //disabled={type === 'edit'}
                                // disabled={true}
                                options={utilityOptions}
                                onChange={(_, val) => {
                                    setChange(true);
                                    changeUtility(
                                        val.value,
                                        setUtility,
                                        setUtilityError,
                                        setUtilityErrorMessage
                                    );
                                    changeObject((prev) => {
                                        prev['utility_id'] = val.value;
                                        return prev;
                                    });
                                }}
                                required
                            /> */}

                            <ATMDropdown
                                placeholder={'Utility'}
                                selection
                                disabled={true}
                                value={
                                    utility ? utility : editObject?.utilityID
                                }
                                options={utilityOptions}
                                onChange={(_, val) => {
                                    setChange(true);
                                    changeUtility(
                                        val.value,
                                        setUtility,
                                        setUtilityError,
                                        setUtilityErrorMessage
                                    );
                                    changeObject((prev) => {
                                        prev['utility_id'] = val.value;
                                        return prev;
                                    });
                                }}
                                style={{
                                    width: '100%',
                                    marginRight: 15,
                                    marginBottom: 5,
                                    height: 'fit-content',
                                }}
                            />

                            {/* <ATMInput
                                fluid
                                error={poError}
                                placeholder={'Utility'}
                                defaultValue={editObject?.utilityID ? editObject?.utilityID :  '' }
                                disabled={true}
                            ></ATMInput> */}
                            {utilityError && (
                                <div
                                    className={`ui pointing basic label contracts ${style.tooltip}`}
                                >
                                    {utilityErrorMessage}
                                </div>
                            )}
                        </ATMGrid.Column>
                        <ATMGrid.Column className={style.modalCol}>
                            {type === 'edit' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <ATMCheckbox
                                        defaultChecked={
                                            editObject?.cd_hide === 1
                                                ? false
                                                : true
                                        }
                                        onChange={(e, v) => {
                                            setChange(true);

                                            changeObject((prev) => {
                                                prev['cd_hide'] = v.checked
                                                    ? 0
                                                    : 1;
                                                return prev;
                                            });
                                        }}
                                        style={{
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            color: '#333333',
                                            fontSize: 13,
                                        }}
                                    >
                                        <b>
                                            {
                                                Lang.LBL_CONTRACTS_INACTIVATE_CONTRACT
                                            }
                                        </b>
                                    </span>
                                </div>
                            )}
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton
                    secondary
                    onClick={() =>
                        cancel(
                            setPoError,
                            setDescriptionError,
                            setPoNumber,
                            setDescription,
                            handleModalClose,
                            setChange,
                            setUtility,
                            setUtilityError
                        )
                    }
                >
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    id="btnSubmit"
                    secondary
                    onClick={() =>
                        clear(
                            type,
                            setPoNumber,
                            setDescription,
                            setPoError,
                            setDescriptionError,
                            handleModalClear,
                            setChange,
                            setUtility,
                            setUtilityError
                        )
                    }
                    disabled={
                        (type === 'add' &&
                            description === '' &&
                            poNumber === '') ||
                        (type === 'edit' && checkEditDisabled())
                    }
                >
                    {Lang.LBL_CLEAR}
                </ATMButton>
                <ATMButton
                    id="btnSubmit"
                    primary
                    onClick={() =>
                        done(
                            type,
                            setChange,
                            handleModalDone,
                            verifyContracts,
                            poNumber,
                            utility
                        )
                    }
                    disabled={
                        (type === 'add' &&
                            (description === '' ||
                                poNumber === '' ||
                                description.length > 40 ||
                                poError ||
                                !utility ||
                                utilityError)) ||
                        (type === 'edit' &&
                            (description.length > 40 || checkEditDisabled()))
                    }
                >
                    {type === 'add' ? Lang.LBL_SAVE : Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
};

import React, { useEffect, useState } from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMCheckbox,
    ATMToggle,
    ATMSelect,
    ATMSegment,
    ATMHeader,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
    updateSubcontractorVonSchema,
    IVonsList,
} from 'src/models/manage-subcontractor.model';
import { ISubList } from 'src/models/manage-subcontractor.model';

import styles from './subcontractor-status-edit-modal.module.scss';

const RequiredLable = ({ children, ...props }) => (
    <label {...props} className={styles.required}>
        {children}
    </label>
);

type Iprops = {
    open: boolean;
    handleClose: () => void;
    handleClick: () => void;
    data: Partial<ISubList | undefined>;
    handleChange: (key, data) => void;
    handleSubmit: (data) => void;
    formRef: any;
    buttonDisable: boolean;
    reasonsDisabled: boolean;
    vonError: boolean;
    handleReasonChange: any;
    reasonList: any;
};
function SubcontractorStatusEditModalView({
    data,
    open,
    formRef,
    handleClose,
    handleClick,
    handleSubmit,
    handleChange,
    buttonDisable,
    reasonsDisabled,
    vonError,
    handleReasonChange,
    reasonList,
}: Iprops): any {
    const [error, setError] = useState<boolean>(false);

    const [isReasonEnable, setIsReasonEnable] = useState<boolean | undefined>();
    //editData?.hidden || false
    const [currentReasonId, setCurrentReasonId] = useState<number | undefined>(
        data?.reason
    );
    const [hidden, setHidden] = useState<boolean | undefined>(data?.hidden);
    const [dropDownError, setDropdownError] = useState(false);
    const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(false);
    // useEffect(() => {
    //     if (currentReasonId !== data?.reason) {
    //         setCurrentReasonId(data?.reason);
    //     }
    // }, [currentReasonId]);
    useEffect(() => {
        if (hidden !== data?.hidden && hidden) {
            if (currentReasonId !== data?.reason) {
                setIsUpdateEnable(true);
            } else {
                setIsUpdateEnable(false);
            }
        } else if (hidden !== data?.hidden && !hidden) {
            setIsUpdateEnable(true);
        } else if (hidden === data?.hidden) {
            if (hidden) {
                if (currentReasonId !== data?.reason) {
                    setIsUpdateEnable(true);
                } else {
                    setIsUpdateEnable(false);
                }
            } else {
                setIsUpdateEnable(false);
            }
        } else {
            setIsUpdateEnable(false);
        }
    }, [currentReasonId, hidden, setHidden, setCurrentReasonId]);
    return (
        <ATMModal
            size="small"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5" className={styles.modelHeader}>
                <div>Edit / Update Subcontractor Information</div>
                {/* <div className={styles.mandatory}>
                    (<span className={styles.required}></span>{' '}
                    {Lang.LBL_MONDATORY_FIELD})
                </div> */}
            </ATMModal.Header>

            <ATMModal.Content>
                <ATMForm
                    ref={formRef}
                    mode="onChange"
                    defaultValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={updateSubcontractorVonSchema}
                >
                    {({ control, formState: { errors } }) => (
                        <ATMGrid columns={2}>
                            <ATMGrid.Row
                                style={{ paddingBottom: '0px !important' }}
                            >
                                <ATMGrid.Column
                                    className={styles.fieldWrapper}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <div
                                        className={styles.contractorHeader}
                                        style={{ paddingLeft: 14 }}
                                    >
                                        {
                                            Lang.TTL_PRIME_CONTRACTOR_COMPANY_DETAILS
                                        }
                                    </div>
                                </ATMGrid.Column>

                                <ATMGrid.Column
                                    className={styles.fieldWrapper}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <div
                                        className={styles.contractorHeader}
                                        style={{ paddingLeft: 14 }}
                                    >
                                        {Lang.TTL_SUBCONTRACTOR_OWNERS_DETAILS}
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_CONTRACTOR_COMPANY_NAME}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="company_name"
                                        error={errors.company_name}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_SUBCONTRACTOR_OWNER_NAME}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="owner_name"
                                        error={errors.owner_name}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_CONTACT_PRIMES_ADDRESS}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="address"
                                        error={errors.address}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_PHONE_NUMBER}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="phone"
                                        error={errors.phone}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_CITY}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="city"
                                        error={errors.city}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_EMAIL}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="contact_email"
                                        error={errors.contact_email}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_STATE}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="state"
                                        error={errors.state}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_SUBCONTRACTOR_ETHINICITY}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="ethinicity"
                                        error={errors.ethinicity}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_ZIP}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="zip"
                                        error={errors.zip}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_GENDER}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="gender_name"
                                        error={errors.gender_name}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row
                                style={{ paddingBottom: '0px !important' }}
                            >
                                <ATMGrid.Column
                                    className={styles.fieldWrapper}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <div
                                        className={styles.contractorHeader}
                                        style={{ paddingLeft: 14 }}
                                    >
                                        {
                                            Lang.TTL_SUBCONTRACTOR_CONTACTS_AND_OTHER_DETAILS
                                        }
                                    </div>
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.fieldWrapper}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <div
                                        className={styles.contractorHeader}
                                        style={{ paddingLeft: 14 }}
                                    >
                                        {
                                            Lang.TTL_SUBCONTRACTOR_VERIFICATION_DETAILS
                                        }
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_SUBCONTRACTOR_CONTACT_NAME}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="contact_name"
                                        error={errors.contact_name}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {
                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_AGENCY
                                        }
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="verification_agency_name"
                                        error={errors.verification_agency_name}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_CONTRACTOR_SAP_ID}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="sap_id"
                                        error={errors.sap_id}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {
                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_NUMBER
                                        }
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="verification_number"
                                        error={errors.verification_number}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_CONTRACTOR_SUPPLIER_CODE}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="supplier_code"
                                        error={errors.supplier_code}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {
                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_EXP_DATE
                                        }
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="verificationExpirationDate"
                                        error={
                                            errors.verificationExpirationDate
                                        }
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_DOING_BUSINESS_AS}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="dba"
                                        error={errors.dba}
                                        disabled
                                        className={styles.inputDisabled}
                                    />
                                </ATMGrid.Column>
                                {/* <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>{Lang.LBL_VERIFICATION_TYPE_DESCRIPTION}</label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="company_name"
                                        error={errors.company_name}
                                        disabled

                                        className={
                                            styles.inputDisabled
                                        }

                                    />
                                </ATMGrid.Column> */}
                            </ATMGrid.Row>

                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column
                                    className={styles.fieldWrapper}
                                    style={{ width: '100%' }}
                                >
                                    <label>
                                        {
                                            Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_STATUS
                                        }
                                    </label>
                                    <ATMCheckbox
                                        className={styles.activeInactive}
                                        style={{ width: '30px' }}
                                        defaultChecked={
                                            data?.reason == 0 ? false : true
                                        }
                                        //defaultChecked={false}
                                        lable={
                                            Lang.LBL_SUBCONTRACTOR_INACTIVATE
                                        }
                                        onChange={(_, val) => {
                                            handleReasonChange(val);
                                            setHidden(val.checked);
                                            return val.checked;
                                        }}
                                    />

                                    <span
                                        style={{
                                            color: '#333333',
                                            fontSize: 13,
                                            lineHeight: '35px',
                                        }}
                                    >
                                        <label>
                                            {Lang.LBL_SUBCONTRACTOR_INACTIVATE}
                                        </label>
                                    </span>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_SUBCONTRACTOR_REASON}
                                    </label>

                                    <ATMField
                                        disabled={reasonsDisabled}
                                        className={styles.inputDisabled}
                                        control={control}
                                        as={ATMSelect}
                                        name="reason"
                                        onChange={(e) => {
                                            setDropdownError(false);
                                            handleChange(
                                                'reason',
                                                e[1].value || 0
                                            );
                                            setCurrentReasonId(e[1].value);
                                            return e[1].value;
                                        }}
                                        placeholder="Select Reason"
                                        options={
                                            !reasonsDisabled
                                                ? reasonList.map(
                                                      (reasonItem) => {
                                                          return {
                                                              key: reasonItem.Reason,
                                                              value: reasonItem.Reason_Id,
                                                              text: reasonItem.Reason,
                                                          };
                                                      }
                                                  )
                                                : []
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton basic color="blue" onClick={handleClose}>
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={handleClick}
                    disabled={!isUpdateEnable}
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
}

export default SubcontractorStatusEditModalView;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    FetchAnnouncementSchema,
    AnnouncementDropdownListSchema,
    AnnouncementResponseSchema,
} from '../models/announcement.model';

const client = httpClient();

export const services = {
    getAnnouncementList: async (params) => {
        return client.get(
            `/manageAnnouncements`,
            params,
            FetchAnnouncementSchema
        );
    },
    getAnnouncementDropdownList: async () => {
        return client.get(
            `/getAnnouncementDropDownLists`,
            {},
            AnnouncementDropdownListSchema
        );
    },
    sendAnnouncement: async (announcementData: any) => {
        return client.post(
            '/manageAnnouncements',
            {
                ...announcementData,
                sentToIds: announcementData.sentToIds.toString(),
            },
            AnnouncementResponseSchema
        );
    },
    deleteAnnouncement: async (id) => {
        return client.delete(
            `/manageAnnouncements?announcementId=${id}`,
            {},
            AnnouncementResponseSchema
        );
    },
};

export default services;

import React from 'react';
import { Menu, Icon, MenuItemProps } from 'semantic-ui-react';
import { IRoute } from 'src/libraries/router.library';
import { Link } from 'react-router-dom';
import { checkVisibility } from 'src/libraries/access.library';

type IProps = {
    routes: IRoute[];
    active: string[];
};

const NavigationView: React.FC<IProps> = ({ routes, active }) => {
    return (
        <>
            {routes.map((route) => {
                if (checkVisibility(route.access_role)) {
                    const props: MenuItemProps = {
                        as: Link,
                        key: route.title,
                        name: route.path,
                        active: active.includes(route.path ?? ''),
                    };

                    if (route.href) {
                        props.as = 'a';
                        props.href = route.href;
                        props.target = '_blank';
                    } else {
                        props.to = route.path;
                    }

                    return (
                        <Menu.Item {...props} key={route.icon}>
                            {route.icon && <Icon name={route.icon} />}
                            {route.title}
                        </Menu.Item>
                    );
                }
                return null;
            })}
        </>
    );
};

export default React.memo(NavigationView);

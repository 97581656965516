import React, { useCallback, useEffect, useState } from 'react';
import { MONTH_NAME_ARRAY } from 'src/constants';
import { useContractorContext } from 'src/contexts/contractor.context';
import Lang from 'src/libraries/language';
import {
    getUserID,
    isAdmin,
    isContact,
    isPrime,
} from 'src/libraries/users.library';
import XLSX from 'xlsx-js-style';
import { saveData } from '../../report/report.component';
import SubcontractingSummaryView from './subcontracting-summary.view';
import { useNavigate } from 'react-router-dom';

export type IProps = {
    socalSubcontractingReport: any;
    sdgeSubcontractingReport: any;
    isDashboardSubcontractorSummaryLoading: boolean;
    selectedUtilityName: string;
    selectedPrimeName: string;
};
const commonCellStyle = {
    border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
    },
};

const boldStyle = {
    ...commonCellStyle,
    font: { bold: true },
};

const rightAlignStyle = {
    ...commonCellStyle,
    alignment: { horizontal: 'right' },
};

const SubcontractingSummary: React.FC<IProps> = ({
    socalSubcontractingReport,
    sdgeSubcontractingReport,
    isDashboardSubcontractorSummaryLoading,
    selectedUtilityName,
    selectedPrimeName,
}) => {
    const navigate = useNavigate();
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear =
        currentMonthIndex === 0
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear();
    const previousMonth =
        currentMonthIndex === 0
            ? MONTH_NAME_ARRAY[11]
            : MONTH_NAME_ARRAY[currentMonthIndex - 1];

    const isUserAdmin = isAdmin();
    const isUserContact = isContact();
    const isUserPrime = isPrime();

    const [primeName, setPrimeName] = useState('');
    const { state, actions } = useContractorContext();

    useEffect(() => {
        fetchData();
    }, [actions]);

    const fetchData = useCallback(async () => {
        if (isUserPrime) {
            const userId = getUserID();
            const params = { vendor_id: userId };
            const response = await actions.listGET(params);
            setPrimeName(response.payload?.data?.company_name);
        }
    }, [actions, setPrimeName]);

    const handleClickViewIndividualContactInfo = () => {
        isUserPrime
            ? navigate('/primedashboard/individualContractInfo')
            : navigate('/dashboard/individualContractInfo');
    };

    const exportSummaryData = () => {
        if (isUserAdmin || isUserContact) {
            exportSummaryDataForAdmin();
        } else if (isUserPrime) {
            exportSummaryDataForPrime();
        }
    };

    const exportSummaryDataForAdmin = () => {
        const worksheet = {
            A1: {
                v: Lang.LBL_SUBCONTRACTING_GOALS_VS_ACTUAL_SUMMARY_REPORT,
                s: { ...boldStyle, alignment: { horizontal: 'center' } },
            },
            B1: { v: '', s: commonCellStyle },
            C1: { v: '', s: commonCellStyle },

            A2: { v: Lang.LBL_PRIME_S, s: boldStyle },
            B2: { v: selectedPrimeName, s: commonCellStyle },
            C2: { v: '', s: commonCellStyle },

            A3: { v: Lang.LBL_UTILITY, s: boldStyle },
            B3: { v: selectedUtilityName, s: commonCellStyle },
            C3: { v: '', s: commonCellStyle },

            A4: { v: Lang.LBL_PREVIOUS_MONTH, s: boldStyle },
            B4: { v: `${previousMonth}, ${currentYear}`, s: commonCellStyle },
            C4: { v: '', s: commonCellStyle },

            A5: { v: '', s: commonCellStyle },
            B5: { v: '', s: commonCellStyle },
            C5: { v: '', s: commonCellStyle },

            A6: { v: '', s: commonCellStyle },
            B6: {
                v: Lang.LBL_SDGNE,
                s: { ...boldStyle, alignment: { horizontal: 'center' } },
            },
            C6: {
                v: Lang.LBL_SOCALGAS,
                s: { ...boldStyle, alignment: { horizontal: 'center' } },
            },

            A7: { v: Lang.LBL_TOTAL_PAYMENTS_YTD, s: boldStyle },
            B7: {
                v: sdgeSubcontractingReport.totalPaymentYTD,
                s: rightAlignStyle,
            },
            C7: {
                v: socalSubcontractingReport.totalPaymentYTD,
                s: rightAlignStyle,
            },

            A8: { v: Lang.LBL_TOTAL_VERIFIED_SUBCONTRACTING, s: boldStyle },
            B8: {
                v: sdgeSubcontractingReport.totalVerifiedSubcontracting,
                s: rightAlignStyle,
            },
            C8: {
                v: socalSubcontractingReport.totalVerifiedSubcontracting,
                s: rightAlignStyle,
            },

            A9: { v: Lang.LBL_VERIFIED_SUBCONTRACTING_YTD, s: boldStyle },
            B9: {
                v: sdgeSubcontractingReport.verifiedSubcontracting,
                s: rightAlignStyle,
            },
            C9: {
                v: socalSubcontractingReport.verifiedSubcontracting,
                s: rightAlignStyle,
            },

            A10: { v: Lang.LBL_OVERALL_GOALS, s: boldStyle },
            B10: {
                v: sdgeSubcontractingReport.overallGoal,
                s: rightAlignStyle,
            },
            C10: {
                v: socalSubcontractingReport.overallGoal,
                s: rightAlignStyle,
            },

            A11: { v: Lang.LBL_GOAL_SUBCONTRACTING, s: boldStyle },
            B11: {
                v: sdgeSubcontractingReport.goalSubcontracting,
                s: rightAlignStyle,
            },
            C11: {
                v: socalSubcontractingReport.goalSubcontracting,
                s: rightAlignStyle,
            },

            A12: { v: Lang.LBL_SUBCONTRACTING_DIFFERENCE, s: boldStyle },
            B12: {
                v: sdgeSubcontractingReport.subcontractingDiff,
                s: rightAlignStyle,
            },
            C12: {
                v: socalSubcontractingReport.subcontractingDiff,
                s: rightAlignStyle,
            },

            '!merges': [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }],
            '!ref': 'A1:C12',
            '!cols': [
                { wch: 30 }, // Column A width
                { wch: 20 }, // Column B width
                { wch: 20 },
            ],
        };

        const workbook = {
            Sheets: { Sheet1: worksheet },
            SheetNames: ['Sheet1'],
        };

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const a = document.createElement('a');
        document.body.appendChild(a);
        saveData(
            excelBuffer,
            'Subcontracting Goals vs Actual Results Summary Report.xlsx'
        );
    };

    const exportSummaryDataForPrime = () => {
        const worksheet = {
            A1: {
                v: Lang.LBL_SUBCONTRACTING_GOALS_VS_ACTUAL_SUMMARY_REPORT,
                s: { ...boldStyle, alignment: { horizontal: 'center' } },
            },
            B1: { v: '', s: commonCellStyle },
            C1: { v: '', s: commonCellStyle },

            A2: { v: Lang.LBL_PRIME_S, s: boldStyle },
            B2: { v: primeName, s: commonCellStyle },
            C2: { v: '', s: commonCellStyle },

            A3: { v: Lang.LBL_PREVIOUS_MONTH, s: boldStyle },
            B3: { v: `${previousMonth}, ${currentYear}`, s: commonCellStyle },
            C3: { v: '', s: commonCellStyle },

            A4: { v: '', s: commonCellStyle },
            B4: { v: '', s: commonCellStyle },
            C4: { v: '', s: commonCellStyle },

            A5: { v: '', s: commonCellStyle },
            B5: {
                v: Lang.LBL_SDGNE,
                s: { ...boldStyle, alignment: { horizontal: 'center' } },
            },
            C5: {
                v: Lang.LBL_SOCALGAS,
                s: { ...boldStyle, alignment: { horizontal: 'center' } },
            },

            A6: { v: Lang.LBL_TOTAL_PAYMENTS_YTD, s: boldStyle },
            B6: {
                v: sdgeSubcontractingReport.totalPaymentYTD,
                s: rightAlignStyle,
            },
            C6: {
                v: socalSubcontractingReport.totalPaymentYTD,
                s: rightAlignStyle,
            },

            A7: { v: Lang.LBL_TOTAL_VERIFIED_SUBCONTRACTING, s: boldStyle },
            B7: {
                v: sdgeSubcontractingReport.totalVerifiedSubcontracting,
                s: rightAlignStyle,
            },
            C7: {
                v: socalSubcontractingReport.totalVerifiedSubcontracting,
                s: rightAlignStyle,
            },

            A8: { v: Lang.LBL_VERIFIED_SUBCONTRACTING_YTD, s: boldStyle },
            B8: {
                v: sdgeSubcontractingReport.verifiedSubcontracting,
                s: rightAlignStyle,
            },
            C8: {
                v: socalSubcontractingReport.verifiedSubcontracting,
                s: rightAlignStyle,
            },

            A9: { v: Lang.LBL_OVERALL_GOALS, s: boldStyle },
            B9: { v: sdgeSubcontractingReport.overallGoal, s: rightAlignStyle },
            C9: {
                v: socalSubcontractingReport.overallGoal,
                s: rightAlignStyle,
            },

            A10: { v: Lang.LBL_GOAL_SUBCONTRACTING, s: boldStyle },
            B10: {
                v: sdgeSubcontractingReport.goalSubcontracting,
                s: rightAlignStyle,
            },
            C10: {
                v: socalSubcontractingReport.goalSubcontracting,
                s: rightAlignStyle,
            },

            A11: { v: Lang.LBL_SUBCONTRACTING_DIFFERENCE, s: boldStyle },
            B11: {
                v: sdgeSubcontractingReport.subcontractingDiff,
                s: rightAlignStyle,
            },
            C11: {
                v: socalSubcontractingReport.subcontractingDiff,
                s: rightAlignStyle,
            },

            '!merges': [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }],
            '!ref': 'A1:C11',
            '!cols': [
                { wch: 30 }, // Column A width
                { wch: 20 }, // Column B width
                { wch: 20 },
            ],
        };

        const workbook = {
            Sheets: { Sheet1: worksheet },
            SheetNames: ['Sheet1'],
        };

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const a = document.createElement('a');
        document.body.appendChild(a);
        saveData(
            excelBuffer,
            'Subcontracting Goals vs Actual Results Summary Report.xlsx'
        );
    };

    return (
        <SubcontractingSummaryView
            previousMonth={previousMonth}
            isDashboardSubcontractorSummaryLoading={
                isDashboardSubcontractorSummaryLoading
            }
            socalSubcontractingReport={socalSubcontractingReport}
            sdgeSubcontractingReport={sdgeSubcontractingReport}
            exportSummaryData={exportSummaryData}
            handleClickViewIndividualContactInfo={
                handleClickViewIndividualContactInfo
            }
        />
    );
};

export default SubcontractingSummary;

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import DashboardReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/dashboard.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(DashboardReducer, defaultState, asyncActions);
};

type IDashboardContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const DashboardContext = createContext<Partial<IDashboardContext>>({
    state: defaultState,
}) as React.Context<IDashboardContext>;

interface AppProps {
    children?: React.ReactNode;
}

const DashboardProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <DashboardContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useDashboardContext: () => IDashboardContext = () =>
    useContext(DashboardContext);
export type IUseDashboardContext = ReturnType<typeof useDashboardContext>;

export { DashboardContext, DashboardProvider };

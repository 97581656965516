import React, { useEffect, useState } from 'react';
import {
    ATMCheckbox,
    ATMGrid,
    ATMForm,
    ATMModal,
    ATMButton,
    ATMField,
    ATMInput,
    ATMSelect,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import {
    ISubcontractorUpdate,
    SubcontractorUpdateSchema,
    ISubcontractorReasonGet,
    ISubcontractorForTesting,
} from 'src/models/subcontractor.model';
import Lang from 'src/libraries/language';
import styles from './subcontractor-update.module.scss';

export type IProps = {
    isOpen: boolean;
    loading: boolean;
    reasonList: Partial<ISubcontractorReasonGet>;
    defaultValues?: Partial<ISubcontractorUpdate>;
    fdata?: Partial<ISubcontractorForTesting>;
    editData?: Partial<ISubcontractorForTesting>;
    btndisable: boolean;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    handleClosePopUp: () => void;
    handleClick: (params: IORGDataTableQueryState) => void;
    handleSubmit: () => void;
    setValue: (fieldName, value) => void;
    queryParam?: IORGDataTableQueryState;
    page?: string;
    limit?: string;
};

const RequiredLabel = ({ children }) => (
    <label className={styles.required}>{children}</label>
);

const UpdateFormView: React.FC<IProps> = ({
    isOpen,
    loading,
    fdata,
    formRef,
    handleClosePopUp,
    handleClick,
    reasonList,
    setValue,
    queryParam,
    page,
    limit,
    handleSubmit,
}) => {
    const [isReasonEnable, setIsReasonEnable] = useState<boolean | undefined>(
        fdata?.Hidden || false
    );
    const [currentReasonId, setCurrentReasonId] = useState<number | undefined>(
        fdata?.Reason_id
    );
    const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(true);
    const [dropDownError, setDropdownError] = useState(false);

    useEffect(() => {
        setIsReasonEnable(fdata?.Hidden);
        setCurrentReasonId(fdata?.Reason_id);
        setIsUpdateEnable(true);
    }, [fdata]);

    useEffect(() => {
        if (currentReasonId !== fdata?.Reason_id) {
            setIsUpdateEnable(false);
        }
        if (isReasonEnable === false && fdata?.Hidden === false) {
            setIsUpdateEnable(true);
        }
    }, [currentReasonId, isUpdateEnable, isReasonEnable]);

    const handleUpdateButtonClick = () => {
        if (currentReasonId === 0) {
            setDropdownError(true);
            return;
        }
        queryParam
            ? handleClick({
                  ...queryParam,
                  page: parseInt(page ?? '1'),
                  limit: parseInt(limit ?? '20'),
              })
            : handleClick({
                  page: parseInt(page ?? '1'),
                  limit: parseInt(limit ?? '20'),
              });
    };

    return (
        <ATMModal
            open={isOpen}
            onClose={handleClosePopUp}
            closeOnDimmerClick={false}
            size="small"
        >
            <ATMModal.Header className={styles.modelHeader}>
                <div>{Lang.TTL_SUBCONTRACTOR_EDIT_UPDATE_SUBCONTRACTOR}</div>
                <div className={styles.mandatory}>
                    (<span className={styles.required}></span>
                    {Lang.LBL_MONDATORY_FIELD})
                </div>
            </ATMModal.Header>
            <ATMModal.Content className={styles.subContractorWrapperView}>
                <ATMForm
                    ref={formRef}
                    validationSchema={SubcontractorUpdateSchema}
                    mode="onChange"
                    defaultValues={fdata}
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    {({ control, formState: { errors } }) => (
                        <>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        label={Lang.LBL_SUBCONTRACTOR}
                                        name="name"
                                        error={errors.name}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column>
                                    <ATMCheckbox
                                        className={styles.activeInactive}
                                        defaultChecked={fdata?.Hidden == true}
                                        label={
                                            Lang.LBL_SUBCONTRACTOR_INACTIVATE
                                        }
                                        onChange={(_, val) => {
                                            if (!val.checked) {
                                                setDropdownError(false);
                                            }
                                            setValue(
                                                'Hidden',
                                                val.checked || false
                                            );
                                            if (
                                                fdata?.Hidden &&
                                                val.checked === false
                                            ) {
                                                setIsUpdateEnable(false);
                                            } else if (
                                                fdata?.Hidden === false &&
                                                val.checked === true
                                            ) {
                                                setIsUpdateEnable(false);
                                            } else {
                                                setIsUpdateEnable(true);
                                            }
                                            setIsReasonEnable(val.checked);
                                            return val.checked;
                                        }}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column className={styles.column}>
                                    <ATMField
                                        label={Lang.LBL_SUBCONTRACTOR_STATE}
                                        control={control}
                                        as={ATMInput}
                                        name="state"
                                        error={errors.state}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    className={styles.reasonColumn}
                                    style={{ paddingRight: '' }}
                                >
                                    <ATMField
                                        className={styles.formField}
                                        control={control}
                                        label={
                                            <RequiredLabel>
                                                {Lang.LBL_SUBCONTRACTOR_REASON}
                                            </RequiredLabel>
                                        }
                                        as={ATMSelect}
                                        name="Reason_id"
                                        onChange={(e) => {
                                            setDropdownError(false);
                                            setValue(
                                                'Reason_id',
                                                e[1].value || false
                                            );
                                            setCurrentReasonId(e[1].value);
                                            return e[1].value;
                                        }}
                                        placeholder="Select Reason"
                                        error={
                                            errors.Reason_id ||
                                            (dropDownError && isReasonEnable)
                                        }
                                        disabled={!isReasonEnable}
                                        options={
                                            isReasonEnable
                                                ? reasonList?.data?.map(
                                                      (reasonItem) => {
                                                          return {
                                                              key: reasonItem.Reason_Id,
                                                              value: reasonItem.Reason_Id,
                                                              text: reasonItem.Reason,
                                                          };
                                                      }
                                                  )
                                                : []
                                        }
                                    />
                                    {dropDownError && isReasonEnable && (
                                        <div
                                            className={`ui pointing label basic contracts ${styles.tooltip}`}
                                            style={{
                                                width: 'max-content',
                                                marginLeft: -21,
                                                marginTop: 10,
                                            }}
                                        >
                                            {String(
                                                Lang.ERROR.SUBCONTRACTOR
                                                    .INACTIVATION_REASON_REQUIRED
                                            )}
                                        </div>
                                    )}
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column>
                                    <ATMField
                                        label={Lang.LBL_SUBCONTRACTORS_CITY}
                                        control={control}
                                        as={ATMInput}
                                        name="city"
                                        error={errors.city}
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                        </>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton
                    basic
                    color="blue"
                    onClick={() => {
                        handleClosePopUp();
                        setIsReasonEnable(fdata?.Hidden);
                        setIsUpdateEnable(true);
                        setCurrentReasonId(fdata?.Reason_id);
                        setDropdownError(false);
                    }}
                >
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={() => handleUpdateButtonClick()}
                    loading={loading}
                    disabled={isUpdateEnable}
                    id="btnSubmit"
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
};

export default UpdateFormView;

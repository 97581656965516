import React, { useRef, useEffect, useCallback, useState } from 'react';
import {
    IORGDataTableColumn,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';

import moment from 'moment';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import { getTableListParams } from 'src/selectors/contractorpage.selector';
import { isAdmin, isPrime, isContact } from 'src/libraries/users.library';
import ContractExpirationStatusView from './contract-expiration-status.view';
import XLSX from 'xlsx-js-style';
import style from './contract-expiration-status.module.scss';
import {
    centerAlignment,
    reportHeaderBackground,
    reportHeaderFont,
    border,
    leftAlignment,
    reportCellFont,
    rightAlignment,
} from 'src/constants';

interface ITilesProps {
    value: string;
    title: string;
    border: boolean;
    infoIcon: string;
}

const ContractExpirationStatus: React.FC = () => {
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();
    const { state, actions } = useContractorPageContext();
    const [params, setParams] = useState({
        page: 1,
        limit: 200000,
    });
    const tableParamsRef = useRef({ page: 0, limit: 0 });

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchData(tableParamsRef.current);
    }, [actions]);
    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            const {
                page,
                limit,
                sort,
                order,
                sempraCompanyName,
                contractSummaryExpiringInReportingYear,
                contractSummaryExpired,
                contractSummaryException,
            } = params;

            setLoading(true);
            tableParamsRef.current = params;
            let data: any = {};
            if (params != undefined && params) {
                data = getTableListParams(params);
            }

            let user_id = localStorage.getItem('loginCredential'); //for prime user
            if (isUserContact || isUserAdmin) {
                user_id = '';
            }

            const param = {
                sort: sort || 'contract_number',
                order: order || 'ascending',
                vendor_id: user_id,
                sempraCompanyName: data?.sempraCompanyName || '',
                contractSummaryExpiringInReportingYear:
                    data?.contractSummaryExpiringInReportingYear || '',
                contractSummaryExpired: data?.contractSummaryExpired || '',
                contractSummaryException: data?.contractSummaryException || '',
            };
            await actions?.dashboardContractSummaryGET(param);
            setLoading(false);
        },
        [actions]
    );

    const adminColumns = [
        {
            title: 'Contracts',
            index: 'contract_number',
            sortable: true,
            render: (_, value) => (
                <span style={{ color: 'rgb(33, 133, 208)', fontWeight: '500' }}>
                    {value.contract_number}
                    <p style={{ color: '#878383' }}>{value.details}</p>
                </span>
            ),
        },
        {
            title: 'Expiration Date',
            index: 'expiryDate',
            sortable: true,
            render: (_, value) => <span>{value?.expiryDate}</span>,
        },
        {
            title: 'Status',
            index: 'status',
            sortable: true,
            render: (_, value) => <span>{value.status}</span>,
        },
    ];

    const primeColumns: IORGDataTableColumn<any>[] = [
        {
            title: 'Contracts',
            index: 'contract_number',
            sortable: false,
            render: (_, value) => (
                <span style={{ color: 'rgb(33, 133, 208)', fontWeight: '500' }}>
                    {value.contract_number}
                </span>
            ),
        },
        {
            title: 'Expiration Date',
            index: 'expiryDate',
            sortable: false,
            render: (_, value) => <span>{value?.expiryDate}</span>,
        },
        {
            title: 'Status',
            index: 'status',
            sortable: false,
            render: (_, value) => (
                <span>
                    {value.status != '' ? (
                        <span>
                            <i className={style.invalidStatus}></i>
                            {value.status}
                        </span>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
    ];

    const handleSetChange = (value, key) => {
        if (key === 'utility') {
            const searchFilterParams = {
                ...params,
                sempraCompanyName: value,
            };
            setParams(searchFilterParams);
            fetchData(searchFilterParams);
        } else if (key === 'contractSummaryExpiringInReportingYear') {
            const searchFilterParams = {
                ...params,
                contractSummaryExpiringInReportingYear: value,
            };
            setParams(searchFilterParams);
            fetchData(searchFilterParams);
        } else if (key === 'contractSummaryExpired') {
            const searchFilterParams = {
                ...params,
                contractSummaryExpired: value,
            };
            setParams(searchFilterParams);
            fetchData(searchFilterParams);
        } else if (key === 'contractSummaryException') {
            const searchFilterParams = {
                ...params,
                contractSummaryException: value,
            };
            setParams(searchFilterParams);
            fetchData(searchFilterParams);
        }
    };

    const saveData = (function () {
        const a = document.createElement('a');
        document.body.appendChild(a);
        return function (data, fileName) {
            const blob = new Blob([data], { type: 'octet/stream' });
            let url = '';
            try {
                url = window.URL.createObjectURL(blob);
            } catch (e) {}

            a.href = url;
            a.download = fileName;
            a.click();
            try {
                window.URL.revokeObjectURL(url);
            } catch (e) {}
        };
    })();

    const downloadContractStatus: any = async () => {
        let response: any = [];

        setLoading(true);

        let data: any = {};
        if (params != undefined && params) {
            data = getTableListParams(params);
        }
        let user_id = localStorage.getItem('loginCredential'); //for prime user
        if (isUserContact || isUserAdmin) {
            user_id = '';
        }
        const param = {
            sort: 'contract_number',
            order: 'ascending',
            vendor_id: user_id,
            sempraCompanyName: data?.sempraCompanyName || '',
            contractSummaryExpiringInReportingYear:
                data?.contractSummaryExpiringInReportingYear || '',
            contractSummaryExpired: data?.contractSummaryExpired || '',
            contractSummaryException: data?.contractSummaryException || '',
        };
        response = await actions?.dashboardContractSummaryGET(param);
        setLoading(false);
        const data1 = response?.payload?.data?.map((i) => ({
            Contracts: i.contract_number,
            Description: i.description,
            'Expiration Date':
                i.expiryDate == null
                    ? '-'
                    : new Date(i.expiryDate).toISOString().split('T')[0] !=
                        '1900-01-01'
                      ? moment(i.expiryDate).zone(0).format('M/D/YYYY')
                      : '-',
            Status: i.expiryStatus,
        }));

        let contractInfoData: any = null;
        const wscols = [{ wch: 20 }, { wch: 40 }, { wch: 20 }, { wch: 30 }];
        try {
            contractInfoData = XLSX.utils.json_to_sheet(data1);
            //Format Excel
            for (const key in contractInfoData) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    const alignment = { ...centerAlignment, wrapText: true };
                    contractInfoData[key].s = {
                        fill: reportHeaderBackground,
                        alignment: alignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = rightAlignment;
                    alignment = leftAlignment;

                    const cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };
                    contractInfoData[key].s = {
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        } catch (e) {}

        contractInfoData['!cols'] = wscols;

        const workbook = {
            Sheets: {
                contract_status_information: contractInfoData,
            },
            SheetNames: ['contract_status_information'],
        };

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const a = document.createElement('a');
        document.body.appendChild(a);
        saveData(excelBuffer, 'contract_status_information.xlsx');
    };

    //console.log('state', state?.list)
    return (
        <ContractExpirationStatusView
            data={state?.list}
            fetchData={fetchData}
            loading={loading}
            handleSetChange={handleSetChange}
            downloadContractStatus={downloadContractStatus}
        />
    );
};

export default ContractExpirationStatus;

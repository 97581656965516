import React from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
    deleteAllSubSchema,
    IVonsList,
} from 'src/models/manage-subcontractor.model';
import style from './delete-confirm-reason-modal.module.scss';

type Iprops = {
    open: boolean;
    handleClose: () => void;
    handleClick: () => void;
    handleChange: (key, data) => void;
    handleSubmit: (data) => void;
    formRef: any;
    buttonDisable: boolean;
    reasonForDeletionError: boolean;
    deleteContent: string;
};

function DeleteConfirmReasonModalView({
    open,
    formRef,
    handleClose,
    handleClick,
    handleSubmit,
    handleChange,
    buttonDisable,
    reasonForDeletionError,
    deleteContent,
}: Iprops): any {
    return (
        <ATMModal
            size="tiny"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">Confirm</ATMModal.Header>
            <ATMModal.Content>{deleteContent}</ATMModal.Content>
            <ATMModal.Content>
                <ATMForm
                    ref={formRef}
                    mode="onChange"
                    defaultValues={[]}
                    onSubmit={handleSubmit}
                    validationSchema={deleteAllSubSchema}
                >
                    {({ control, formState: { errors } }) => (
                        <ATMGrid columns={1}>
                            <ATMGrid.Row>
                                <ATMGrid.Column className={style.fieldWrapper}>
                                    <label className={style.required}>
                                        Deletion Reason
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        placeholder="Enter a Deletion Reason"
                                        name="reasonForDeletion"
                                        error={
                                            reasonForDeletionError
                                                ? 'VON # Already Exists'
                                                : errors.reasonForDeletion
                                        }
                                        required
                                        onChange={([_, val]) => {
                                            handleChange(
                                                'reasonForDeletion',
                                                val.value
                                            );
                                            return val.value;
                                        }}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton basic color="blue" onClick={handleClose}>
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={handleClick}
                    disabled={buttonDisable}
                >
                    {Lang.LBL_DELETE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
}

export default DeleteConfirmReasonModalView;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import ContactPrimesListView from './primes.view';
import { useContactPrimesContext } from 'src/contexts/contact-primes.context';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import {
    getTableListParams,
    getContactPrimesStatus,
} from 'src/selectors/contact-primes.selector';
import { actionTypes } from 'src/ducks/contact-primes.duck';
import Lang from 'src/libraries/language';
import { IContactPrimesTesting } from 'src/models/contact-primes.model';
import PrimesUpdateForm from '../primes-update/primes-update.component';
import { getUser, removeSearchParams } from 'src/libraries/auth.library';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import ContractorUpdateForm from 'src/components/pages/contractor/contractor-update/contractor-update.component';
import { isAdmin } from 'src/libraries/users.library';
import { useNavigate } from 'react-router-dom';
import PrimeUsersAuditLog from './prime-users-audit-log/prime-users-audit-log.component';
import PrimeTier3SubcontractorsAuditLog from '../prime-tier3-subcontractors-audit-log/prime-tier3-subcontractors-audit-log.component';

const ContactPrimes: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [vendorId, setVendorId] = useState(null);
    const [auditVendorId, setAuditVendorId] = useState(0);
    const [openAuditLogs, setOpenAuditLogs] = useState(false);
    const [openAuditLogsTier, setOpenAuditLogsTier] = useState(false);
    const [updateConfirm, setUpdateConfirm] = useState(false);
    const [refreshView, setRefreshView] = useState(false);
    const [editData, setEditData] = useState<IContactPrimesTesting>();
    const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);
    const [isVisible, setIsVisible] = useState({
        success: false,
        error: false,
    });
    const [toggleBool, setToggleBool] = useState(false);
    const [filterData, setFilterData] = useState<any>([]);
    const { state, actions } = useContactPrimesContext();
    const formRef = useRef<HTMLFormElement>();
    const search = window?.location?.search || '?page=1&limit=20';
    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');
    localStorage.setItem('prime_id', '');
    localStorage.setItem('primeData', '');

    useEffect(() => {
        removeSearchParams();
    }, []);

    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const [openAddPrimeContact, setopenAddPrimeContact] =
        useState<boolean>(false);
    const primeContactFormRef = useRef<HTMLFormElement>();
    const isUserAdmin = isAdmin();
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    if (queryParams) {
        // TODO
    }
    useEffect(() => {
        const page = new URLSearchParams(search).get('page');
        const limit = new URLSearchParams(search).get('limit');
        localStorage.setItem('prime_id', '');
        localStorage.setItem('primeData', '');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
            });
        }
    }, []);

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            let data: any = {};
            const user = getUser();

            if (params?.filters) {
                setFilterData(params.filters);
            } else {
                setFilterData([]);
            }
            const { page, limit, sort, order } = params;
            if (params != undefined && params) {
                data = getTableListParams(params);
            }
            tableParamsRef.current = params;
            const param = {
                page: page,
                limit: limit,
                sapId: data?.sapId || '',
                vendor_id: '',
                username: user.username,
                name_begin_with: data?.name_begin_with || '',
                state: data?.state || '',
                name_contains: data?.name_contains || '',
                sort_by: sort || '',
                sort_direction: order || '',
                subContractorType: data?.subContractorType
                    ? data?.subContractorType == 'All'
                        ? ''
                        : data?.subContractorType
                    : '',
            };
            console.log('param', param);
            await actions.listGET_Contact_Primes(param);
        },
        [actions]
    );

    const handleEdit = useCallback(
        async (data) => {
            setEditData(data);
            setVendorId(data.vendor_id);
            setConfirm(true);
        },
        [setConfirm]
    );

    const handleSubmit = () => {
        return;
    };
    const setValue = useCallback(
        (filedName, value) => {
            if (filedName === 'Fav_Id') {
                setIsChecked(value);
            }
        },
        [isChecked]
    );

    const handleFilter = useCallback(
        async (params: IORGDataTableQueryState) => {
            if (vendorId) {
                fetchData(params);
            }
        },
        [actions]
    );

    const handleClosePopUp = useCallback(() => {
        // close popup form for update form
        setConfirm(false);
    }, [setConfirm]);

    const handleSuccess = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoading(true);
            setConfirm(false);
            await fetchData(params);
            setLoading(false);
        },
        [setConfirm, setLoading]
    );

    const handleLoad = useCallback(async () => {
        const user = getUser();
        const param = {
            page: 1,
            limit: 20,
            sapId: '',
            vendor_id: '',
            username: user.username,
            name_begin_with: '',
            state: '',
            name_contains: '',
            sort_by: '',
            sort_direction: '',
            subContractorType: '',
        };
        setLoading(true);
        await fetchData(param);
        setLoading(false);
    }, [setConfirm, setLoading]);
    const handleClick = useCallback(() => {
        if (formRef && formRef.current) {
            formRef.current.handleSubmit();
        }
        setUpdateConfirm(true);
        setConfirm(false);
    }, [setUpdateConfirm]);

    const handleDismiss = useCallback(() => {
        setIsVisible({ success: false, error: false });
    }, [setIsVisible]);
    const handleOnClose = async () => {
        await setRefreshView(true);
        setUpdateConfirm(false);
        await setRefreshView(false);
    };

    const primesDetail = useCallback(
        (primeObj) => {
            localStorage.setItem('prime_id', primeObj.vendor_id);
            localStorage.setItem('primeData', JSON.stringify(primeObj));

            navigate('/primes/primecontractor');
        },
        [setIsVisible]
    );

    const handlePrimeContactClose = () => {
        setopenAddPrimeContact(false);
    };

    const handlePrimeContactDiscard = () => {
        setopenAddPrimeContact(false);
    };

    const handleOpenAuditLogs = (data) => {
        setAuditVendorId(data.vendor_id);
        setOpenAuditLogs(true);
    };

    const handleOpenTier3AuditLogs = () => {
        setOpenAuditLogsTier(true);
    };

    const handleSubmitConfirm = useCallback(
        async (params: IORGDataTableQueryState) => {
            if (vendorId) {
                const user = getUser();
                const data = {
                    vendor_id: vendorId,
                    username: user.username,
                };
                await actions.Primes_Favourite_Update(data);
                ToastSuccess(Lang.MSG_PRIMES_FAVOURITE_UPDATED_SUCCESSFULLY);
                fetchData(params);
            }
            setUpdateConfirm(false);
        },
        [vendorId, actions]
    );

    const handlePrimeTire3Toggle = useCallback(
        async (vendor_id, toggle) => {
            console.log('toggle', toggle);
            setLoading(true);
            const params = {
                vendorId: vendor_id,
                isAllow: toggle ? 1 : 0,
            };
            await actions.allowPrimeTire3SubcontractorsPost(params);
            ToastSuccess(Lang.MSG_PRIMES_TIER_3_SPEND_UPDATED_SUCCESSFULLY);
            //handleLoad()
            const page = new URLSearchParams(search).get('page');
            const limit = new URLSearchParams(search).get('limit');
            handleFilter({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
                filters: filterData,
            });
            await fetchData(tableParamsRef.current);
            setLoading(false);
        },
        [setLoading, filterData, handleFilter]
    );
    const toggleFuc = useCallback(async (data) => {
        if (data) {
            setToggleBool(false);
        } else {
            setToggleBool(true);
        }
        // const newData = { ...searchRef.current, ...{ [key]: value } };
        // searchRef.current = newData;
    }, []);
    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
        setOpenAuditLogsTier(false);
    };

    const handlePrimeSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setopenAddPrimeContact(false);
            ToastSuccess(msg);
            await fetchData(tableParamsRef.current);
            setLoading(false);
        },
        [setopenAddPrimeContact, setLoading]
    );

    return (
        <>
            {!openAddPrimeContact && (
                <ContactPrimesListView
                    handelPrimeContact={setopenAddPrimeContact}
                    // handelPrimeContact={(isOpen) => {
                    //     setopenAddPrimeContact(isOpen);
                    // }}
                    isAdmin={isUserAdmin}
                    isVisible={isVisible}
                    data={state?.list.data || []}
                    fetchData={fetchData}
                    totalRecord={state.list.total_rows || 0}
                    handleEdit={handleEdit}
                    handleDismiss={handleDismiss}
                    primesDetail={primesDetail}
                    handleOpenTier3AuditLogs={handleOpenTier3AuditLogs}
                    loading={
                        loading ||
                        getContactPrimesStatus(
                            state,
                            actionTypes.CONTACT_PRIME_LIST_FETCH
                        ).fetching
                    }
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                    handleOpenAuditLogs={handleOpenAuditLogs}
                    handlePrimeTire3Toggle={handlePrimeTire3Toggle}
                    toggleBool={toggleBool}
                    toggleFuc={toggleFuc}
                />
            )}

            {!refreshView && (
                <PrimesUpdateForm
                    isOpen={confirm}
                    handleClosePopUp={handleClosePopUp}
                    fdata={state.list?.data.find(
                        (lstData) => lstData.vendor_id == vendorId
                    )}
                    editData={editData}
                    handleClick={handleClick}
                    handleSuccess={handleSuccess}
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                />
            )}
            {updateConfirm && (
                <Confirm
                    open={updateConfirm}
                    size="tiny"
                    content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() =>
                        handleSubmitConfirm({
                            page: parseInt(page ?? '1'),
                            limit: parseInt(limit ?? '20'),
                            filters: filterData,
                        })
                    }
                />
            )}
            {openAddPrimeContact && (
                <ContractorUpdateForm
                    isOpen={openAddPrimeContact}
                    handleClose={handlePrimeContactClose}
                    handleSuccess={handlePrimeSuccess}
                    formRef={primeContactFormRef}
                    handleDiscard={handlePrimeContactDiscard}
                    isNewPrime={true}
                    contractorData={{ list: null }}
                />
            )}
            {openAuditLogs && auditVendorId && (
                <PrimeUsersAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                    vendorId={auditVendorId}
                />
            )}
            {openAuditLogsTier && (
                <PrimeTier3SubcontractorsAuditLog
                    openAuditLogs={openAuditLogsTier}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
        </>
    );
};

export default ContactPrimes;

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import LoginReducer, { defaultState, asyncActions } from 'src/ducks/login.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(LoginReducer, defaultState, asyncActions);
};

type ILoginContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const LoginContext = createContext<Partial<ILoginContext>>({
    state: defaultState,
}) as React.Context<ILoginContext>;

interface AppProps {
    children?: React.ReactNode;
}

const LoginProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <LoginContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useLoginContext: () => ILoginContext = () =>
    useContext(LoginContext);
export type IUseLoginContext = ReturnType<typeof useLoginContext>;

export { LoginContext, LoginProvider };

import React, {
    Dispatch,
    SetStateAction,
    startTransition,
    useEffect,
    useState,
} from 'react';
import {
    ATMButton,
    ATMDropdown,
    ATMGrid,
    ATMIcon,
    ATMInput,
    ATMSelect,
} from 'shared-it-appmod-ui';
import { useSpendContext } from 'src/contexts/spend.context';
import Lang from 'src/libraries/language';
import style from './update-spend.module.scss';
import { isPrime } from 'src/libraries/users.library';

import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
// spendTier
import UtilityView from './utility.view';

export const spendTier = [
    {
        key: 'tier2',
        value: 'tier2',
        text: 'Tier II',
    },
    {
        key: 'tier3',
        value: 'tier3',
        text: 'Tier III',
    },
];
export const monthOptions = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
    .map((i, index) => ({ key: index + 1, value: index + 1, text: i }))
    .filter((j) => j.value <= new Date().getMonth() + 1);

export const monthOptionsConditional = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
    .map((i, index) => ({ key: index + 1, value: index + 1, text: i }))
    .filter((j) => j.value <= 12);

export const changeYear: any = (
    year,
    setYear,
    setMonth,
    handleYearChange,
    setMonthOptionsNew,
    isJanuary,
    handleMonthChange
) => {
    setYear(year);
    if (isJanuary == 0) {
        if (year != Number(localStorage.getItem('reportYear'))) {
            const monthOptions = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
                .map((i, index) => ({
                    key: index + 1,
                    value: index + 1,
                    text: i,
                }))
                .filter((j) => j.value <= 12);

            setMonthOptionsNew(monthOptions);
            setMonth(12);
            handleMonthChange(12);
        } else {
            const monthOptions = ['January']
                .map((i, index) => ({
                    key: index + 1,
                    value: index + 1,
                    text: i,
                }))
                .filter((j) => j.value <= 1);

            setMonthOptionsNew(monthOptions);
            setMonth(1);
            handleMonthChange(1);
        }
    }

    handleYearChange(year);
};

export const changeMonth: any = (month, setMonth, handleMonthChange) => {
    setMonth(month);
    handleMonthChange(month);
};

export const filterSubcontractor: any = (
    v,
    setSubFilter,
    handleSubcontractorChange
) => {
    setSubFilter(v.value);
    if (typeof v.value === 'object') {
        handleSubcontractorChange(v?.value?.join(','));
    }
};

export const filterContractor: any = (
    v,
    setContractsFilter,
    handlePOChange
) => {
    setContractsFilter(v.value);
    if (typeof v.value === 'object') {
        handlePOChange(v?.value?.join(','));
    }
};

export const filterUtils: any = (
    v,
    setUtilsFilter,
    handleUtilsChange,
    handleSearch
) => {
    if (typeof v.value === 'string' || typeof v.value === 'number') {
        setUtilsFilter(v.value);
        handleUtilsChange(v.value);
        //handlePOChange(v.value);
        handleSearch(v.value);
    }
};
export const filterSpendAmt: any = (
    v,
    setSpendAmtFilter,
    handleSpendAmtChange
) => {
    setSpendAmtFilter(v.value);
    if (typeof v.value === 'number') {
        handleSpendAmtChange(v?.value);
    }
};

export const handleClear: any = (
    setClear,
    handleSubcontractorChange,
    handlePOChange,
    setContractsFilter,
    setSubFilter,
    handleSearch,
    handleUtilsChange,
    handleTierChange,
    setUtilsFilter,
    handleSpendAmtChange,
    utilsFilter
) => {
    const isUserPrime = isPrime();
    if (!isUserPrime) {
        window.location.reload(); // Need to check proper way
    }
    startTransition(() => setClear(false));
    handleSubcontractorChange('');
    handlePOChange('');
    setContractsFilter([]);
    setSubFilter([]);
    // handleUtilsChange('');
    //setUtilsFilter('');
    handleSpendAmtChange('');
    // setSpendAmtFilter('');
    handleSearch(utilsFilter);
    setTimeout(() => {
        startTransition(() => setClear(true));
    }, 100);
};

interface ISpendProps {
    handleSubcontractorChange: (change: string) => void;
    handlePOChange: (change: string) => void;
    handleUtilsChange: (val: string) => void;
    handleTierChange: (val: string) => void;
    handleSpendAmtChange: (spend: number | string) => void;
    handleSearch: (data: any) => void;
    handleMonthChange: (month: any) => void;
    handlePageChange: (page: string) => void;
    page: number;
    setUtility: (val: number) => void;
    isJanuary: number;
    handleYearChange: (year: any) => void;
    currentApiYear: number;
    isTier2: boolean;
    isTierChange: boolean;
    tier: string;
    setTierLabel: Dispatch<SetStateAction<string>>;
}

export const SpendHead: React.FC<ISpendProps> = ({
    handleSubcontractorChange,
    handlePOChange,
    handleUtilsChange,
    handleTierChange,
    handleSpendAmtChange,
    handleSearch,
    handleMonthChange,
    handlePageChange,
    page,
    setUtility,
    isJanuary,
    handleYearChange,
    currentApiYear,
    isTier2,
    isTierChange,
    tier,
    setTierLabel,
}) => {
    const { state } = useSpendContext();
    const isUserPrime = isPrime();
    const [subcontractorFilter, setSubFilter] = useState([]);
    const [contractsFilter, setContractsFilter] = useState([]);
    const [utilsFilter, setUtilsFilter] = useState<number | string>('');
    const [spendAmt, setSpendAmtFilter] = useState('');
    const [month1, setMonth] = useState(new Date().getMonth() + 1);
    const [clear, setClear] = useState(true);
    const [isUtilSelected, setIsUtilSelected] = useState<number>();
    const [year1, setYear] = useState(currentApiYear);
    const [yearOptions, setYearOptions] = useState([{}]);
    const [monthOptionsNew, setMonthOptionsNew] = useState([{}]);
    const currentYear = String(currentApiYear);
    const [spendStage, setSpendStage] = useState('');
    const [seed, setSeed] = useState(1);

    const clearFilters = () => {
        startTransition(() => setClear(false));
        handleSubcontractorChange('');
        handlePOChange('');
        setContractsFilter([]);
        setSubFilter([]);
        setSpendAmtFilter('');
        handleSpendAmtChange('');
        if (isJanuary == 0) {
            if (currentApiYear == new Date().getFullYear()) {
                setYear(new Date().getFullYear());
                setMonth(1);
            } else {
                setYear(new Date().getFullYear() - 1);
                setMonth(12);
            }
        } else {
            setYear(currentApiYear);
            setMonth(new Date().getMonth());
        }
        handleSearch(utilsFilter);
        setTimeout(() => {
            startTransition(() => setClear(true));
        }, 100);
    };

    useEffect(() => {
        if (isTier2) {
            setSpendStage('tier2');
        }
        if (isJanuary == 0) {
            const currentyear = currentApiYear;
            if (currentyear == year1) {
                setYear(currentApiYear);
                setMonth(1);
            } else {
                setYear(currentApiYear - 1);
                const monthOptions = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ]
                    .map((i, index) => ({
                        key: index + 1,
                        value: index + 1,
                        text: i,
                    }))
                    .filter((j) => j.value <= 12);

                setMonthOptionsNew(monthOptions);
                setMonth(12);
            }
        }
        if (typeof utilsFilter === 'number') {
            setUtility(utilsFilter);
            setIsUtilSelected(utilsFilter);
        }
        if (
            !!subcontractorFilter.length ||
            !!contractsFilter.length ||
            !!spendAmt ||
            month1 !== new Date().getMonth()
        ) {
            clearFilters();
        }
    }, [utilsFilter, isJanuary]);

    const fillText: any = {
        fontSize: 13,
        fontWeight: 'bold',
        marginRight: 5,
        marginTop: 10,
        marginBottom: 5,
        color: '#606060',
        // margin: '0px 10px 0px 15px',
    };
    const childFieldAlign: any = {
        marginRight: 10,
    };
    const fillTextLowerFilter: any = {
        fontSize: 13,
        fontWeight: 'bold',
        marginRight: 5,
        marginTop: 4,
        marginBottom: 5,
        color: '#606060',
    };

    const utilsList: any = state?.list?.UtilityData?.map((v) => ({
        key: v?.Sempra_Company_ID,
        value: v?.Sempra_Company_ID,
        text: v?.Sempra_Company_Name,
    }));

    const handleUtilityDataChange = (v) => {
        filterUtils(
            v,
            setUtilsFilter,
            handleUtilsChange,
            //handlePOChange,
            handleSearch
        );
    };

    const user_type = getUserType();

    const contractsList = state?.contracts?.data?.filter((v) => {
        // if(AccessRole.ADMIN === user_type) {
        //     return true
        // } else {
        if (!v?.isHideForSpend) {
            return true;
        } else {
            return false;
        }
        // }
    });

    if (utilsList?.length === 2) {
        utilsList.unshift({
            key: 'ALL',
            value: 'ALL',
            text: 'ALL',
        });
    }
    return (
        <ATMGrid className={style.usFilters}>
            <ATMGrid.Row>
                <ATMGrid.Column className={style.c1}>
                    <ATMGrid.Row
                        className={
                            isUserPrime ? style.filtersRow : style.utilList
                        }
                    >
                        <ATMGrid.Column
                            className={
                                isTier2
                                    ? style.tierListHide
                                    : isUserPrime
                                      ? style.spendList
                                      : style.normalContract
                            }
                            style={childFieldAlign}
                        >
                            <ATMGrid.Column className={style.lableFieldAlign}>
                                <span style={fillText}>Select Spend Tier</span>
                                <ATMSelect
                                    className="ddDefault"
                                    placeholder={'Select Spend Tier'}
                                    defaultValue={''}
                                    selection
                                    search
                                    disabled={spendTier?.length ? false : true}
                                    options={spendTier?.map((v) => ({
                                        key: v?.key,
                                        value: v?.value,
                                        text: v?.text,
                                    }))}
                                    onChange={(i, v) => {
                                        //localStorage.setItem('spend-Tier',v.value);
                                        setSpendStage(v.value);
                                        handleTierChange(v.value);
                                        clearFilters();
                                        setSeed(Math.random());
                                        v.value === 'tier2'
                                            ? setTierLabel('Tier II Spend')
                                            : v.value === 'tier3'
                                              ? setTierLabel('Tier III Spend')
                                              : setTierLabel('');
                                        //handlePOChange(v.value)
                                        //setUtilsFilter(v.value)
                                        //  filterUtils(
                                        //      v,

                                        //     setUtilsFilter,
                                        // //     handlePOChange,
                                        // //     handleUtilsChange,
                                        // //     handleSearch
                                        //  );
                                    }}
                                    style={{
                                        marginBottom: 5,
                                        marginRight: 15,
                                        height: 'fit-content',
                                    }}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Column>
                        <UtilityView
                            key={seed}
                            disabled={
                                !isTier2 ? (tier === '' ? true : false) : false
                            }
                            spendStage={spendStage}
                            fillText={fillText}
                            isUserPrime={isUserPrime}
                            utilsList={utilsList}
                            datastate={state}
                            handleUtilityDataChange={handleUtilityDataChange}
                        />
                        {/* <ATMGrid.Column
                            className={
                                spendStage =='' ? style.utilListHide :( isUserPrime
                                    ? style.utilList
                                    : style.normalContract)
                            }
                        >
              
                            <ATMGrid.Column
                                className={style.lableFieldAlign}
                            >
                                <span style={fillText}>Select Utility</span>
                                <ATMSelect
                                    className="ddDefault"
                                    placeholder={isUserPrime ? 'Select Utility' : 'ALL'}
                                    defaultValue={!isUserPrime ? 'ALL' : ''}
                                    selection
                                    search
                                    disabled={
                                        state?.list.UtilityData?.length
                                            ? false
                                            : true
                                    }
                                    options={
                                        !isUserPrime
                                            ? utilsList
                                            : state?.list?.UtilityData?.map(
                                                (v) => ({
                                                    key: v?.Sempra_Company_ID,
                                                    value: v?.Sempra_Company_ID,
                                                    text: v?.Sempra_Company_Name,
                                                })
                                            )
                                    }
                                    onChange={(i, v) => {
                                        filterUtils(
                                            v,
                                            setUtilsFilter,
                                            handleUtilsChange,
                                            //handlePOChange,
                                            handleSearch
                                        );
                                    }}
                                    style={{
                                        marginBottom: 5,
                                        marginRight: 15,
                                        height: 'fit-content',
                                    }}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Column> */}
                        {!isTierChange &&
                            (!isUserPrime || isUtilSelected) &&
                            clear && (
                                <ATMGrid.Column
                                    className={
                                        isUserPrime
                                            ? style.contractFilters
                                            : style.normalContract
                                    }
                                >
                                    <ATMGrid.Column
                                        className={style.lableFieldAlign}
                                    >
                                        {clear && (
                                            <>
                                                <span style={fillText}>
                                                    Subcontractor
                                                </span>
                                                <ATMDropdown
                                                    className="ddDefault"
                                                    placeholder="ALL"
                                                    multiple
                                                    selection
                                                    search
                                                    disabled={
                                                        state?.subContracts
                                                            ?.data?.length
                                                            ? false
                                                            : true
                                                    }
                                                    options={state?.subContracts?.data?.map(
                                                        (v) => ({
                                                            key: v?.subcontractor_id,
                                                            value: v?.subcontractor_id,
                                                            text:
                                                                v?.name &&
                                                                v?.name.toUpperCase(),
                                                        })
                                                    )}
                                                    onChange={(i, v) => {
                                                        filterSubcontractor(
                                                            v,
                                                            setSubFilter,
                                                            handleSubcontractorChange
                                                        );
                                                    }}
                                                    style={{
                                                        maxWidth: 400,
                                                        marginRight: 15,
                                                        marginBottom: 5,
                                                        height: 'fit-content',
                                                    }}
                                                />
                                            </>
                                        )}
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={style.lableFieldAlign}
                                    >
                                        <span style={fillText}>Contract</span>
                                        {clear && (
                                            <ATMDropdown
                                                className="ddDefault"
                                                placeholder="ALL"
                                                multiple
                                                selection
                                                search
                                                disabled={
                                                    state?.contracts?.data
                                                        ?.length
                                                        ? false
                                                        : true
                                                }
                                                options={state?.contracts?.data?.map(
                                                    (v) => ({
                                                        key: v?.contract_id,
                                                        value: v?.contract_id,
                                                        text: v?.contract_number,
                                                    })
                                                )}
                                                onChange={(i, v) => {
                                                    filterContractor(
                                                        v,
                                                        setContractsFilter,
                                                        handlePOChange
                                                    );
                                                }}
                                                style={{
                                                    maxWidth: 400,
                                                    marginRight: 15,
                                                    marginBottom: 5,
                                                    height: 'fit-content',
                                                }}
                                            />
                                        )}
                                    </ATMGrid.Column>
                                    {!isUserPrime && (
                                        <ATMGrid.Column
                                            className={style.lableFieldAlign}
                                        >
                                            <span style={fillText}>
                                                Spend Amount
                                            </span>
                                            <ATMSelect
                                                className="ddDefault"
                                                placeholder="ALL"
                                                // defaultValue="ALL"
                                                selection
                                                search
                                                disabled={
                                                    state?.list
                                                        ?.spendAmountFilter
                                                        ?.length
                                                        ? false
                                                        : true
                                                }
                                                options={state?.list?.spendAmountFilter?.map(
                                                    (v) => ({
                                                        key: v?.id,
                                                        value: v?.id,
                                                        text: v?.name,
                                                    })
                                                )}
                                                onChange={(i, v) => {
                                                    filterSpendAmt(
                                                        v,
                                                        setSpendAmtFilter,
                                                        handleSpendAmtChange
                                                    );
                                                }}
                                                style={{
                                                    maxWidth: 400,
                                                    marginRight: 15,
                                                    marginBottom: 5,
                                                    height: 'fit-content',
                                                }}
                                            />
                                        </ATMGrid.Column>
                                    )}

                                    <ATMGrid.Column
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ATMButton
                                            color="blue"
                                            secondary
                                            onClick={() =>
                                                handleSearch(utilsFilter)
                                            }
                                        >
                                            {Lang.LBL_SEARCH}
                                        </ATMButton>
                                        {(!!subcontractorFilter.length ||
                                            !!contractsFilter.length ||
                                            (!isUserPrime && !!utilsFilter) ||
                                            (isUserPrime &&
                                                (!!subcontractorFilter.length ||
                                                    !!contractsFilter.length)) ||
                                            typeof spendAmt === 'number') && (
                                            <ATMButton
                                                color="blue"
                                                secondary
                                                onClick={() =>
                                                    handleClear(
                                                        setClear,
                                                        handleSubcontractorChange,
                                                        handlePOChange,
                                                        setContractsFilter,
                                                        setSubFilter,
                                                        handleSearch,
                                                        handleUtilsChange,
                                                        setUtilsFilter,
                                                        handleSpendAmtChange,
                                                        setSpendAmtFilter,
                                                        utilsFilter
                                                    )
                                                }
                                            >
                                                {Lang.LBL_CLEAR}
                                            </ATMButton>
                                        )}
                                    </ATMGrid.Column>
                                </ATMGrid.Column>
                            )}
                    </ATMGrid.Row>
                </ATMGrid.Column>
            </ATMGrid.Row>
            {!isTierChange && (!isUserPrime || isUtilSelected) && (
                <ATMGrid.Row>
                    <ATMGrid.Column>
                        <ATMGrid.Row
                            style={{
                                // border: '1px solid red',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ATMGrid.Column
                                    className={style.lableFieldAlign}
                                    style={fillTextLowerFilter}
                                >
                                    Year
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    style={{ marginBottom: 5, marginRight: 5 }}
                                >
                                    <ATMInput
                                        style={{ width: 70, color: 'black' }}
                                        disabled
                                        value={currentYear}
                                    />
                                </ATMGrid.Column>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ATMGrid.Column style={fillTextLowerFilter}>
                                    Month
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    style={{ marginBottom: 5, marginRight: 5 }}
                                >
                                    <ATMDropdown
                                        placeholder="Month"
                                        compact
                                        selection
                                        value={month1}
                                        search
                                        options={
                                            new Date().getFullYear() !=
                                            currentApiYear
                                                ? monthOptionsConditional
                                                : monthOptions
                                        }
                                        //options={monthOptions}
                                        onChange={(i, v) =>
                                            changeMonth(
                                                v.value,
                                                setMonth,
                                                handleMonthChange
                                            )
                                        }
                                        style={{ width: 110 }}
                                    />
                                </ATMGrid.Column>
                            </div>

                            <div className={style.verticalLine}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ATMGrid.Column
                                    style={{ marginRight: 5, marginBottom: 5 }}
                                >
                                    <ATMButton
                                        secondary
                                        disabled={page <= 1 ? true : false}
                                        onClick={() => {
                                            handlePageChange('prev');
                                        }}
                                    >
                                        <ATMIcon
                                            name="arrow left"
                                            color={page <= 1 ? 'grey' : 'blue'}
                                        />
                                        {Lang.LBL_PREV}
                                    </ATMButton>
                                </ATMGrid.Column>

                                <ATMGrid.Column
                                    style={{ marginRight: 5, marginBottom: 5 }}
                                >
                                    <span>
                                        {`${page + ' of ' + state?.list?.contracts_last_page + ' Page(s)'}`}
                                    </span>
                                </ATMGrid.Column>

                                <ATMGrid.Column style={{ marginBottom: 5 }}>
                                    <ATMButton
                                        disabled={
                                            page >=
                                            state?.list?.contracts_last_page
                                        }
                                        secondary
                                        onClick={() => {
                                            handlePageChange('next');
                                        }}
                                    >
                                        {Lang.LBL_NEXT}
                                        <ATMIcon
                                            name="arrow right"
                                            color={
                                                page >=
                                                state?.list?.contracts_last_page
                                                    ? 'grey'
                                                    : 'blue'
                                            }
                                        />
                                    </ATMButton>
                                </ATMGrid.Column>
                            </div>
                        </ATMGrid.Row>
                    </ATMGrid.Column>
                </ATMGrid.Row>
            )}
        </ATMGrid>
    );
};

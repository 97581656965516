import yup from '../libraries/validator.library';

export const AnnouncementSchema = yup
    .object({
        announcementId: yup.number().defined(),
        description: yup.string().defined(),
        announcementType: yup.string().defined(),
        sendDate: yup.string().defined(),
        communicationGroupName: yup.string().defined(),
        duration: yup.string().defined(),
        expiryDate: yup.string().defined(),
        isExpired: yup.boolean().defined(),
        announcementTypeId: yup.number().defined(),
        sentToIds: yup.number().defined(),
    })
    .defined();

export const FetchAnnouncementSchema = yup
    .object({
        data: yup.array().of(AnnouncementSchema).defined(),
        status: yup.boolean().defined(),
        totalRows: yup.number().defined(),
        totalPages: yup.number().defined(),
    })
    .defined();

export const AnnouncementDropdownListSchema = yup
    .object({
        data: yup
            .object({
                sent_to: yup
                    .array()
                    .of(
                        yup
                            .object({
                                communication_group_id: yup.number().defined(),
                                communication_group_name: yup
                                    .string()
                                    .defined(),
                            })
                            .defined()
                    )
                    .defined(),
                announcement_type_Data: yup
                    .array()
                    .of(
                        yup
                            .object({
                                announcement_type_id: yup.number().defined(),
                                announcement_type: yup.string().defined(),
                            })
                            .defined()
                    )
                    .defined(),
                duration: yup
                    .array()
                    .of(
                        yup
                            .object({
                                id: yup.number().defined(),
                                name: yup.string().defined(),
                            })
                            .defined()
                    )
                    .defined(),
            })
            .defined(),
        status: yup.boolean().defined(),
    })
    .defined();

export const AnnouncementPostSchema = yup
    .object({
        announcement: yup.string().required('Announcement is required field'),
        announcementTypeId: yup
            .number()
            .required('Announcement type is required field'),
        duration: yup.string().required('Duration is required field'),
        sentToIds: yup.array().of(yup.string().required('To is required field')),
        createdById: yup.number(),
    })
    .defined();

export const AnnouncementResponseSchema = yup.object({
    message: yup.string(),
    status: yup.boolean(),
});

export type IAnnouncementSchema = yup.InferType<typeof AnnouncementSchema>;
export type IFetchAnnouncementSchema = yup.InferType<
    typeof FetchAnnouncementSchema
>;
export type IAnnouncementPostSchema = yup.InferType<
    typeof AnnouncementPostSchema
>;
export type IAnnouncementDropdownListSchema = yup.InferType<
    typeof AnnouncementDropdownListSchema
>;

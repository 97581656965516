import React, { useEffect, useState } from 'react';
import {
    ATMCheckbox,
    ATMGrid,
    ATMForm,
    ATMModal,
    ATMButton,
    ATMField,
    ATMInput,
    ATMSelect,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import { SubcontractorUpdateSchema } from 'src/models/subcontractor.model';
import {
    IContactPrimesTesting,
    IContactPrimesUpdate,
} from 'src/models/contact-primes.model';
import Lang from 'src/libraries/language';
import styles from './primes-update.module.scss';
import { states } from 'src/constants/common.constant';

export type IProps = {
    isOpen: boolean;
    loading: boolean;
    defaultValues?: Partial<IContactPrimesUpdate>;
    fdata?: Partial<IContactPrimesTesting>;
    editData?: Partial<IContactPrimesTesting>;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    handleClosePopUp: () => void;
    handleClick: (params: IORGDataTableQueryState) => void;
    handleSubmit: () => void;
    setValue: (fieldName, value) => void;
    queryParam?: IORGDataTableQueryState;
    page?: string;
    limit?: string;
};

const UpdateFormView: React.FC<IProps> = ({
    isOpen,
    loading,
    fdata,
    formRef,
    handleClosePopUp,
    handleClick,

    setValue,
    queryParam,
    page,
    limit,
    handleSubmit,
}) => {
    const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(true);

    useEffect(() => {
        setIsUpdateEnable(true);
    }, [fdata]);

    const handleUpdateButtonClick = () => {
        handleClick({
            ...queryParam,
            page: parseInt(page ?? '1'),
            limit: parseInt(limit ?? '10'),
        });
    };

    const stateObj = states.map((state) => {
        return {
            key: state,
            text: state,
            value: state,
        };
    });

    return (
        <ATMModal
            open={isOpen}
            onClose={handleClosePopUp}
            closeOnDimmerClick={false}
            size="small"
        >
            <ATMModal.Header className={styles.modelHeader}>
                <div>{Lang.TTL_PRIMES_EDIT_UPDATE}</div>
            </ATMModal.Header>
            <ATMModal.Content className={styles.subContractorWrapperView}>
                <ATMForm
                    ref={formRef}
                    validationSchema={SubcontractorUpdateSchema}
                    mode="onChange"
                    defaultValues={fdata}
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    {({ control, formState: { errors } }) => (
                        <>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        label={Lang.LBL_EDIT_UPDATE_SAP_ID}
                                        name="sapId"
                                        error={errors.sapId}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        label={Lang.LBL_EDIT_UPDATE_PRIME}
                                        name="name"
                                        error={errors.name}
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column>
                                    <ATMField
                                        label={Lang.LBL_EDIT_UPDATE_ADDRESS}
                                        control={control}
                                        as={ATMInput}
                                        name="address"
                                        error={errors.address}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column>
                                    <ATMField
                                        label={Lang.LBL_EDIT_UPDATE_CITY}
                                        control={control}
                                        as={ATMInput}
                                        name="city"
                                        error={errors.city}
                                        disabled
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                            <ATMGrid columns={2}>
                                <ATMGrid.Column>
                                    <ATMField
                                        label={Lang.LBL_EDIT_UPDATE_STATE}
                                        control={control}
                                        as={ATMSelect}
                                        name="state"
                                        options={stateObj}
                                        error={errors.state}
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column>
                                    <ATMCheckbox
                                        className={styles.activeInactive}
                                        defaultChecked={
                                            fdata?.Fav_Id === 0 ? false : true
                                        }
                                        label={Lang.LBL_EDIT_UPDATE_FAVOURITE}
                                        onChange={(_, val) => {
                                            setValue(
                                                'Fav_Id',
                                                val.checked || false
                                            );
                                            setIsUpdateEnable(!isUpdateEnable);
                                            return val.checked;
                                        }}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                        </>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton
                    basic
                    color="blue"
                    onClick={() => {
                        handleClosePopUp();
                        setIsUpdateEnable(true);
                    }}
                >
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={() => handleUpdateButtonClick()}
                    loading={loading}
                    disabled={isUpdateEnable}
                    id="btnSubmit"
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
};

export default UpdateFormView;

import React from 'react';
import { ATMButton, ATMContainer, ATMSegment } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import IndividualContractInfoView from './individual-contract-info.view';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import style from './individual-contract-info.module.scss';
import { useNavigate } from 'react-router-dom';
import { isPrime } from 'src/libraries/users.library';

const createPane = (menuName: string, Component: any, value: number) => ({
    menuItem: menuName,
    render: () => (
        <ATMSegment>
            <Component companyCode={value} />
        </ATMSegment>
    ),
});

const IndividualContractInfo: React.FC = () => {
    const navigate = useNavigate();
    const isUserPrime = isPrime();
    const panes = [
        createPane(Lang.LBL_SDGNE, IndividualContractInfoView, 2100),
        createPane(Lang.LBL_SOCALGAS, IndividualContractInfoView, 2200),
    ];

    const handleBack = () => {
        isUserPrime ? navigate('/primedashboard') : navigate('/dashboard');
    };

    return (
        <ATMContainer fluid>
            <ATMContainer fluid className={style.wrapper}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '15px',
                        paddingBottom: '15px',
                    }}
                >
                    <div className={style.pageTitle}>
                        {Lang.TTL_INDIVIDUAL_PO_DETAILS}
                    </div>
                    <div>
                        <ATMButton
                            style={{ marginLeft: 5 }}
                            color="blue"
                            secondary
                            onClick={handleBack}
                        >
                            {Lang.LBL_BACK}
                        </ATMButton>
                    </div>
                </div>
            </ATMContainer>
            <Tabs
                menu={{
                    fluid: false,
                    vertical: false,
                    pointing: true,
                    compact: true,
                    size: 'small',
                }}
                panes={panes}
                segment="basic"
            />
        </ATMContainer>
    );
};

export default IndividualContractInfo;

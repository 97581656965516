import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import POGuardrailsReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/po-guardrails.duck';

const useReducer = () => {
    return useReducerHook(POGuardrailsReducer, defaultState, asyncActions);
};

type IPOGuardrailsContext = ReturnType<typeof useReducer>;

const POGuardrailsContext = createContext<Partial<IPOGuardrailsContext>>({
    state: defaultState,
}) as React.Context<IPOGuardrailsContext>;

interface AppProps {
    children?: React.ReactNode;
}

const POGuardrailsProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <POGuardrailsContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </POGuardrailsContext.Provider>
    );
};

export const usePOGuardrailsContext: () => IPOGuardrailsContext = () =>
    useContext(POGuardrailsContext);
export type IUsePOGuardrailsContext = ReturnType<typeof usePOGuardrailsContext>;

export { POGuardrailsContext, POGuardrailsProvider };

/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */
import React from 'react';
import PrivateLayout from 'src/layouts/private/private.component';
import routerFactory, { IRoute } from './libraries/router.library';
import Error404 from './components/pages/error/error-404/error-404.component';
import Error403 from 'src/components/pages/error/error-403/error-403.component';
import Lang from './libraries/language';
import Report from './components/pages/report/report.component';
import Login from 'src/components/pages/login/login.component';
import CDCLoginProxy from 'src/components/pages/login/login-proxy/cdc-login-proxy.component';
import SpendSummary from 'src/components/pages/spend-summary/spend-summary.component';
import ContractorInfo from 'src/components/pages/contractor/contractor-info/contractor-info.component';
import UpdateSpend from './components/pages/update-spend/update-spend.component';
import Contracts from 'src/components/pages/contracts/contracts.component';
import Subcontractor from 'src/components/pages/subcontractor/subcontractor.component';
import SubcontractorAddSelected from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-add-selected/subcontractor-add-selected.component';
import SubcontractorCreate from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-create/subcontractor-create.component';
import PrimesContactList from 'src/components/pages/contact-user/primes-contact/primes-contact.component';
import AdminMenu from './components/pages/admin/admin-menu.component';
import ContactPrimesDetail from 'src/components/pages/contact-user/primes/primes-detail/primes-detail.component';
import ContactPrimes from 'src/components/pages/contact-user/primes/primes-info/primes.component';
import { AccessRole } from 'src/constants/access.constant';
import MassMail from './components/pages/admin/mass-mail/mass-mail.component';
import ManageNotification from './components/pages/admin/managenotification/information/manage-notification.component';
import PrimeLogin from './components/pages/login/prime-login/prime-login.component';
import PrimeResetPassword from './components/pages/contact-user/prime-reser-password/prime-reset-password.component';
import AdminSubcontractor from './components/pages/admin-subcontractor/admin-subcontractor.component';
import PrimeDashboard from './components/pages/dashboard/prime-dashboard/prime-dashboard.component';
import AdminDashboard from './components/pages/dashboard/admin-dashboard/admin-dashboard.component';
import SubcontractorSpendListAdmin from './components/pages/subcontractor/subcontractor-spend-list/admin/subcontractor-spend-list-admin.component';
import SubcontractorSpendListPrime from './components/pages/subcontractor/subcontractor-spend-list/prime/subcontractor-spend-list-prime.component';
import IndividualContractInfo from './components/pages/dashboard/subcontracting-summary/individual-contract-info/individual-contract-info.component';

/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */

const LoginCDCProxyRoute: IRoute = {
    path: '/proxy',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <CDCLoginProxy />,
        },
    ],
};
const LoginCDCRoute: IRoute = {
    path: '/login',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <CDCLoginProxy />,
        },
    ],
};
const LoginCDCErrorRoute: IRoute = {
    path: '/error',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <Login />,
        },
    ],
};
const LoginCDCSuccessRoute: IRoute = {
    path: '/success',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <Login />,
        },
    ],
};
const MainRoute: IRoute = {
    path: '/',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <Login />,
        },
    ],
};
const PrimeLoginRoute: IRoute = {
    path: '/primelogin',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <PrimeLogin />,
        },
    ],
};
const ResetPasswordRoute: IRoute = {
    path: '/resetpassword',
    element: <PrivateLayout />,
    access_role: [AccessRole.GUEST],
    children: [
        {
            index: true,
            element: <PrimeResetPassword />,
        },
    ],
};
const PrimeDashboardRoute: IRoute = {
    title: Lang.TTL_DASHBOARD,
    path: '/primedashboard',
    element: <PrivateLayout />,
    access_role: [AccessRole.PRIME],
    children: [
        {
            index: true,
            element: <PrimeDashboard />,
        },
        {
            path: '/primedashboard/subcontractorspendlist',
            element: <SubcontractorSpendListPrime />,
            access_role: [AccessRole.PRIME],
        },
        {
            path: '/primedashboard/individualContractInfo',
            element: <IndividualContractInfo />,
            access_role: [AccessRole.PRIME],
        },
    ],
};
const AdminDashboardRoute: IRoute = {
    title: Lang.TTL_DASHBOARD,
    path: '/dashboard',
    element: <PrivateLayout />,
    access_role: [AccessRole.ADMIN, AccessRole.CONTACT],
    children: [
        {
            index: true,
            element: <AdminDashboard />,
        },
        {
            path: '/dashboard/subcontractorspendlist',
            element: <SubcontractorSpendListAdmin />,
            access_role: [AccessRole.CONTACT, AccessRole.ADMIN],
        },
        {
            path: '/dashboard/individualContractInfo',
            element: <IndividualContractInfo />,
            access_role: [AccessRole.CONTACT, AccessRole.ADMIN],
        },
    ],
};
const PrimeContactorInfoRoute: IRoute = {
    title: Lang.TTL_CONTRACTOR_INFO,
    path: '/primecontractor',
    element: <PrivateLayout />,
    access_role: [AccessRole.PRIME],
    children: [
        {
            index: true,
            element: <ContractorInfo />,
        },
    ],
};
const ManageSubContractorsRoute: IRoute = {
    title: Lang.TTL_SUB_CONTRACTOR_MANAGE,
    path: '/subcontractors',
    element: <PrivateLayout />,
    access_role: [AccessRole.PRIME],
    children: [
        {
            index: true,
            element: <Subcontractor />,
        },
        {
            path: '/subcontractors/add',
            element: <SubcontractorAddSelected />,
            access_role: [AccessRole.PRIME],
        },
        {
            path: '/subcontractors/enter',
            element: <SubcontractorCreate />,
            access_role: [AccessRole.PRIME],
        },
    ],
};
const ManageContractsRoute: IRoute = {
    title: Lang.TTL_CONTRACTOR_MANAGE,
    path: '/contracts',
    element: <PrivateLayout />,
    access_role: [AccessRole.PRIME],
    children: [
        {
            index: true,
            element: <Contracts />,
        },
    ],
};

const UpdateSpendRoute: IRoute = {
    title: Lang.TTL_UPDATE_SPEND,
    path: '/updatespend',
    element: <PrivateLayout />,
    access_role: [AccessRole.PRIME],
    children: [
        {
            index: true,
            element: <UpdateSpend />,
        },
    ],
};

const ViewSpendSummaryRoute: IRoute = {
    title: Lang.TTL_VIEW_SPEND_SUMMARY,
    path: '/spendsummary',
    element: <PrivateLayout />,
    access_role: [AccessRole.PRIME],
    children: [
        {
            index: true,
            element: <SpendSummary />,
        },
    ],
};
const ContactPrimesRoute: IRoute = {
    title: Lang.TTL_CONTACT_PRIMES,
    path: '/primes',
    element: <PrivateLayout />,
    access_role: [AccessRole.CONTACT, AccessRole.ADMIN, AccessRole.DBE_ADVISOR],
    children: [
        {
            index: true,
            element: <ContactPrimes />,
        },
        {
            path: '/primes/primecontractor',
            element: <ContactPrimesDetail />,
            access_role: [
                AccessRole.CONTACT,
                AccessRole.ADMIN,
                AccessRole.DBE_ADVISOR,
            ],
        },
    ],
};

const ContactRoute: IRoute = {
    title: Lang.TTL_PRIMES_AND_CONTACT,
    path: '/primesandcontact',
    element: <PrivateLayout />,
    access_role: [AccessRole.CONTACT, AccessRole.ADMIN, AccessRole.DBE_ADVISOR],
    children: [
        {
            index: true,
            element: <PrimesContactList />,
        },
    ],
};

const ReportRoute: IRoute = {
    title: Lang.TTL_REPORTS,
    path: '/report',
    element: <PrivateLayout />,
    access_role: [
        AccessRole.PRIME,
        AccessRole.ADMIN,
        AccessRole.CONTACT,
        AccessRole.DBE_ADVISOR,
    ],
    children: [
        {
            index: true,
            element: <Report />,
        },
    ],
};
const AdminRoute: IRoute = {
    title: Lang.TTL_ADMIN,
    path: '/admin',
    element: <PrivateLayout />,
    access_role: [AccessRole.ADMIN],
    children: [
        {
            index: true,
            element: <AdminMenu />,
        },
        {
            path: '/admin/massmail',
            element: <MassMail />,
            access_role: [AccessRole.ADMIN],
        },
        {
            path: '/admin/managenotification',
            element: <ManageNotification />,
            access_role: [AccessRole.ADMIN],
        },
    ],
};

const AdminSubcontractors: IRoute = {
    title: Lang.TTL_SUB_CONTRACTOR_MANAGE,
    path: '/adminsubcontractors',
    element: <PrivateLayout />,
    access_role: [AccessRole.ADMIN],
    children: [
        {
            index: true,
            element: <AdminSubcontractor />,
        },
    ],
};

export const routes = [
    MainRoute,
    LoginCDCProxyRoute,
    LoginCDCRoute,
    PrimeDashboardRoute,
    AdminDashboardRoute,
    LoginCDCErrorRoute,
    LoginCDCSuccessRoute,
    PrimeContactorInfoRoute,
    ManageSubContractorsRoute,
    ManageContractsRoute,
    UpdateSpendRoute,
    ViewSpendSummaryRoute,
    ContactPrimesRoute,
    ContactRoute,
    AdminSubcontractors,
    ReportRoute,
    AdminRoute,
    PrimeLoginRoute,
    ResetPasswordRoute,
];
export type IRouter = (typeof routes)[number];

export default routerFactory(routes, {
    Error404: <Error404 />,
    Error403: <Error403 />,
});

import React from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
    updateSubcontractorVonSchema,
    IVonsList,
} from 'src/models/manage-subcontractor.model';
import style from './update-subcontractor-model.module.scss';

type Iprops = {
    open: boolean;
    handleClose: () => void;
    handleClick: () => void;
    data: Partial<IVonsList | undefined>;
    handleChange: (key, data) => void;
    handleSubmit: (data) => void;
    formRef: any;
    buttonDisable: boolean;
    vonError: boolean;
};

function UpdateSubcontrctorView({
    data,
    open,
    formRef,
    handleClose,
    handleClick,
    handleSubmit,
    handleChange,
    buttonDisable,
    vonError,
}: Iprops): any {
    return (
        <ATMModal
            size="tiny"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                Edit / Update Subcontractor VON
            </ATMModal.Header>

            <ATMModal.Content>
                <ATMForm
                    ref={formRef}
                    mode="onChange"
                    defaultValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={updateSubcontractorVonSchema}
                >
                    {({ control, formState: { errors } }) => (
                        <ATMGrid columns={2}>
                            <ATMGrid.Row>
                                <ATMGrid.Column className={style.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_SPEND_SUMMARY_SUBCONTRACTOR}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="name"
                                        disabled
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column className={style.fieldWrapper}>
                                    <label className={style.required}>
                                        {Lang.LBL_UPDATE_SAPID_VON}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        placeholder="Enter a VON"
                                        name="verificationNumber"
                                        error={
                                            vonError
                                                ? 'VON # Already Exists'
                                                : errors.verificationNumber
                                        }
                                        required
                                        onChange={([_, val]) => {
                                            handleChange(
                                                'verificationNumber',
                                                val.value
                                            );
                                            return val.value;
                                        }}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton basic color="blue" onClick={handleClose}>
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={handleClick}
                    disabled={buttonDisable}
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
}

export default UpdateSubcontrctorView;

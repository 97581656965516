import React from 'react';
import { Tab, TabProps } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import useTabLocation from 'src/hooks/tab-location.hook';
import styles from './tabs.module.scss';

const Tabs: React.FC<TabProps> = (props) => {
    const navigate = useNavigate();
    const { currentTab, pathname } = useTabLocation();

    return (
        <div className={styles.container}>
            <Tab
                {...props}
                activeIndex={currentTab}
                className="tab-menu"
                onTabChange={(_, data) => {
                    navigate(`${pathname}?tab=${data.activeIndex}`);
                }}
            />
        </div>
    );
};

export default Tabs;

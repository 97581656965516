import React from 'react';
import {
    ATMSegment,
    ATMGrid,
    ATMForm,
    ATMButton,
    ATMCheckbox,
    ATMHeader,
} from 'shared-it-appmod-ui';
import {
    ISubcontractorGenderNationalityAgency,
    ISubcontractorCreate,
    ISubcontractorCreateFrmErrorObj,
} from 'src/models/subcontractor.model';
import { Container, Form } from 'semantic-ui-react';
import Instruction from 'src/components/atoms/instruction/instruction.component';
import Lang from 'src/libraries/language';
import styles from './subcontractor-create.module.scss';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from 'moment';
import { states } from 'src/constants/common.constant';

export type IProps = {
    loading: boolean;
    manualEntry: boolean;
    found_in_cpuc: boolean;
    addBtnDisable: boolean;
    lookupData: any;
    mannualEntryDisable: boolean;
    frmError?: boolean;
    error?: ISubcontractorCreateFrmErrorObj;
    frmData?: ISubcontractorCreate;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    lookupFormRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    goBack: () => void;
    add: () => void;
    setValue: (fieldName, val) => void;
    handleSubmit: () => void;
    autoFill: () => void;
    lookup: () => void;
    validateField: (fieldName, e) => void;
    defaultValues?: Partial<ISubcontractorCreate>;
    GenderNationalityAgenctList: Partial<ISubcontractorGenderNationalityAgency>;
};

const RequiredLabel = ({ children }) => (
    <label className={styles.required}>{children}</label>
);
const showDisplay = false;
const SubcontractorCreateView: React.FC<IProps> = ({
    defaultValues,
    manualEntry,
    addBtnDisable,
    mannualEntryDisable,
    goBack,
    add,
    setValue,
    handleSubmit,
    autoFill,
    lookup,
    GenderNationalityAgenctList,
    frmData,
    frmError,
    error,
    validateField,
    found_in_cpuc,
}) => {
    return (
        <>
            <Container fluid className={styles.wrapperCreatesubContractor}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                        {Lang.LBL_SUBCONTRACTOR_ENTER_NEW_SUBCONTRACTOR}
                    </ATMHeader>
                    <div
                        className={styles.topButtons}
                        style={{ display: 'flex' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: '13px',
                            }}
                        >
                            <ATMButton
                                className="ui secondary button"
                                size="small"
                                onClick={() => goBack()}
                            >
                                {Lang.TTL_BACK}
                            </ATMButton>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: '13px',
                            }}
                        >
                            {manualEntry == false && found_in_cpuc === true ? (
                                <ATMButton
                                    className="ui primary button"
                                    onClick={() => add()}
                                    size="small"
                                    disabled={addBtnDisable}
                                >
                                    {Lang.TTL_ADD}
                                </ATMButton>
                            ) : manualEntry == false ? (
                                <ATMButton
                                    className="ui primary button"
                                    size="small"
                                    disabled={addBtnDisable}
                                >
                                    {Lang.TTL_ADD}
                                </ATMButton>
                            ) : (
                                <ATMButton
                                    onClick={() => add()}
                                    className="ui primary button"
                                    size="small"
                                    disabled={addBtnDisable}
                                >
                                    {Lang.TTL_ADD}
                                </ATMButton>
                            )}
                        </div>
                    </div>
                </div>

                <ATMSegment style={{ padding: '18px' }}>
                    <Instruction
                        title={Lang.LBL_NOTE}
                        content={Lang.MSG_SUBCONTRACTOR_CREATE_INSTRUCTION}
                    />

                    <Form
                        mode="onChange"
                        id="createForm"
                        onSubmit={handleSubmit}
                        validate
                        error={frmError}
                    >
                        <>
                            <ATMGrid columns={2}>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        tablet={16}
                                        computer={16}
                                        mobile={16}
                                        style={{
                                            width: '100% !important',
                                            display: 'flex',
                                        }}
                                    >
                                        <ATMForm.Group widths={14}>
                                            <ATMForm.Group
                                                widths={16}
                                                className={
                                                    styles.verificationWrapper
                                                }
                                            >
                                                <RequiredLabel>
                                                    {
                                                        Lang.LBL_SUBCONTRACTOR_VERIFICATION_NUMBER
                                                    }
                                                </RequiredLabel>

                                                <div>
                                                    <Form.Input
                                                        value={
                                                            frmData?.verification_number
                                                        }
                                                        className={
                                                            styles.fieldname
                                                        }
                                                        name="verification_number"
                                                        error={
                                                            error?.verification_number ??
                                                            false
                                                        }
                                                        placeholder={
                                                            Lang.PLACEHOLDER
                                                                .SUBCONTRACTOR_ENTER_NUMBER
                                                        }
                                                        onBlur={(e) =>
                                                            validateField(
                                                                'verification_number',
                                                                e
                                                            )
                                                        }
                                                        onChange={(e) => {
                                                            setValue(
                                                                'verification_number',
                                                                e.target.value
                                                            );
                                                            return e.target
                                                                .value;
                                                        }}
                                                    />
                                                </div>
                                            </ATMForm.Group>
                                            {manualEntry == false && (
                                                <ATMForm.Group
                                                    className={styles.createBtn}
                                                >
                                                    {/* <div style={{display: 'none'}}> <ATMButton
                                                        secondary
                                                        className={
                                                            styles.lookupBtn
                                                        }
                                                        size="small"
                                                        onClick={lookup}
                                                    >
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_LOOKUP
                                                        }
                                                    </ATMButton> </div> */}
                                                    <ATMButton
                                                        primary
                                                        size="small"
                                                        className={
                                                            styles.autofillBtn
                                                        }
                                                        onClick={autoFill}
                                                    >
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_AUTOFILL
                                                        }
                                                    </ATMButton>
                                                </ATMForm.Group>
                                            )}
                                        </ATMForm.Group>

                                        <ATMForm.Group
                                            widths={2}
                                            className={styles.requiredWrapper}
                                        >
                                            <div
                                                className={
                                                    styles.createmandatory
                                                }
                                                style={{
                                                    width: '100% !important, textAlign: right',
                                                }}
                                            >
                                                (
                                                <span
                                                    className={styles.required}
                                                ></span>{' '}
                                                {Lang.MSG_REQUIRED})
                                            </div>
                                        </ATMForm.Group>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>

                            {/* <div style={{display: 'none'}}> <ATMGrid>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        tablet={16}
                                        computer={16}
                                        mobile={16}
                                        style={{ width: '100% !important' }}
                                    >
                                        <label
                                            style={{
                                                color: '#333333DE',
                                                paddingRight: '8px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {
                                                Lang.LBL_SUBCONTRACTOR_ENABLE_MANUAL_ENTRY
                                            }
                                        </label>
                                        <ATMCheckbox
                                            toggle
                                            disabled={mannualEntryDisable}
                                            defaultChecked={false}
                                            name="manual_entry"
                                            label={
                                                manualEntry
                                                    ? Lang.LBL_SUBCONTRACTOR_ON
                                                    : Lang.LBL_SUBCONTRACTOR_OFF
                                            }
                                            onChange={(_, val) => {
                                                setValue(
                                                    'manual_entry',
                                                    val.checked
                                                );

                                                return val.checked;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid> </div> */}

                            <ATMGrid>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        tablet={16}
                                        computer={16}
                                        mobile={16}
                                        style={{ width: '100% !important' }}
                                    >
                                        <ATMForm.Group widths={4}>
                                            <Form.Input
                                                name="name"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={defaultValues?.name}
                                                error={
                                                    (manualEntry &&
                                                        error?.name) ??
                                                    false
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_CREATE_SUBCONTRACTOR_NAME
                                                        }
                                                    </RequiredLabel>
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_NAME
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'name',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <Form.Input
                                                name="address"
                                                className={
                                                    styles.leftRightPadding1
                                                }
                                                value={defaultValues?.address}
                                                error={
                                                    (manualEntry &&
                                                        error?.address) ??
                                                    false
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_ADDRESS
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_ADDRESS
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'address',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <Form.Input
                                                name="city"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={defaultValues?.city}
                                                error={
                                                    (manualEntry &&
                                                        error?.city) ??
                                                    false
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTORS_CITY
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_CITY
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'city',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <Form.Select
                                                name="state"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={defaultValues?.state}
                                                error={
                                                    (manualEntry &&
                                                        error?.state) ??
                                                    false
                                                }
                                                options={states
                                                    .map((state) => {
                                                        return {
                                                            key: state,
                                                            value: state,
                                                            text: state,
                                                        };
                                                    })
                                                    .sort((a, b) =>
                                                        a.text.localeCompare(
                                                            b.text
                                                        )
                                                    )}
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_STATE
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_STATE
                                                }
                                                onChange={(e, { value }) => {
                                                    setValue('state', value);
                                                    return value;
                                                }}
                                            />
                                        </ATMForm.Group>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                            <ATMGrid>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        tablet={16}
                                        computer={16}
                                        mobile={16}
                                        style={{ width: '100% !important' }}
                                    >
                                        <ATMForm.Group widths={4}>
                                            <Form.Input
                                                name="zip"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={defaultValues?.zip}
                                                error={
                                                    (manualEntry &&
                                                        error?.zip) ??
                                                    false
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_ZIP
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_ZIP
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'zip',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <Form.Input
                                                name="owner_name"
                                                className={
                                                    styles.leftRightPadding1
                                                }
                                                value={
                                                    defaultValues?.owner_name
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.owner_name) ??
                                                    false
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_OWNER_NAME
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_NAME
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'owner_name',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <Form.Input
                                                name="phone"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={defaultValues?.phone}
                                                error={
                                                    (manualEntry &&
                                                        error?.phone) ??
                                                    false
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_PHONE_NUMBER
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_PHONE_NUMBER
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'phone',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <div
                                                className={`ui ${styles.datePickerField} field 
                                            ${styles.leftRightPadding}`}
                                            >
                                                <SemanticDatepicker
                                                    // error={
                                                    //     (manualEntry &&
                                                    //         error?.verification_expiry_date) ??
                                                    //     false
                                                    // }
                                                    pointing="top left"
                                                    datePickerOnly={true}
                                                    type="basic"
                                                    // minDate={
                                                    //     new Date(
                                                    //         new Date().setDate(
                                                    //             new Date().getDate() -
                                                    //                 1
                                                    //         )
                                                    //     )
                                                    // }
                                                    value={
                                                        defaultValues?.verification_expiry_date
                                                            ? moment(
                                                                  defaultValues?.verification_expiry_date
                                                              ).toDate()
                                                            : null
                                                    }
                                                    placeholder="Select Date"
                                                    name="verification_expiry_date"
                                                    label={
                                                        found_in_cpuc ===
                                                        false ? (
                                                            <label>
                                                                {
                                                                    Lang.LBL_SUBCONTRACTOR_VERIFICATION_EXP_DATE
                                                                }
                                                            </label>
                                                        ) : (
                                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_EXP_DATE
                                                        )
                                                    }
                                                    onChange={(event, data) => {
                                                        setValue(
                                                            'verification_expiry_date',
                                                            data.value
                                                        );
                                                        return data.value;
                                                    }}
                                                    readOnly={!manualEntry}
                                                    className={
                                                        !manualEntry
                                                            ? styles.disableDatepicker
                                                            : styles.datepicker
                                                    }
                                                    format="MM/DD/YYYY"
                                                    disabled={
                                                        manualEntry == false
                                                            ? true
                                                            : false
                                                    }
                                                />

                                                {manualEntry &&
                                                error?.verification_expiry_date !=
                                                    undefined
                                                    ? // <div
                                                      //     className="ui pointing above prompt label"
                                                      //     role="alert"
                                                      //     aria-atomic="true"
                                                      // >
                                                      //     Verification Exp. Date is
                                                      //     required field
                                                      // </div>
                                                      ''
                                                    : ''}
                                            </div>
                                        </ATMForm.Group>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                            {/* <div style={{display: 'none'}}><ATMGrid>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        tablet={16}
                                        computer={16}
                                        mobile={16}
                                        style={{ width: '100% !important' }}
                                    >
                                        <ATMForm.Group widths={4}>
                                            <Form.Select
                                                className={
                                                    styles.leftRightPadding1
                                                }
                                                value={
                                                    defaultValues?.verification_agency_id !==
                                                    0
                                                        ? defaultValues?.verification_agency_id
                                                        : ''
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.verification_agency) ??
                                                    false
                                                }
                                                name="verificationAgency_id"
                                                options={
                                                    GenderNationalityAgenctList?.data?.verification_agencies
                                                        ?.map(
                                                            (
                                                                verificationItem
                                                            ) => {
                                                                return {
                                                                    key: verificationItem?.agency_id,
                                                                    value: verificationItem?.agency_id,
                                                                    text: verificationItem?.agency_name,
                                                                };
                                                            }
                                                        )
                                                        .sort((a, b) =>
                                                            a.text.localeCompare(
                                                                b.text
                                                            )
                                                        ) || []
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_VERIFICATION_AGENCY
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={!manualEntry}
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_SELECT_AGENCY_NAME
                                                }
                                                onChange={(e, { value }) => {
                                                    setValue(
                                                        'verification_agency_id',
                                                        value
                                                    );
                                                    return value;
                                                }}
                                            />
                                            <Form.Select
                                                name="gender_id"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={
                                                    defaultValues?.gender_id !==
                                                    0
                                                        ? defaultValues?.gender_id
                                                        : ''
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.gender) ??
                                                    false
                                                }
                                                options={
                                                    GenderNationalityAgenctList?.data?.genders
                                                        ?.map((genderItem) => {
                                                            return {
                                                                key: genderItem?.gender_id,
                                                                value: genderItem?.gender_id,
                                                                text: genderItem?.gender,
                                                            };
                                                        })
                                                        .sort((a, b) =>
                                                            a.text.localeCompare(
                                                                b.text
                                                            )
                                                        ) || []
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_GENDER
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_SELECT_GENDER
                                                }
                                                onChange={(e, { value }) => {
                                                    setValue(
                                                        'gender_id',
                                                        value
                                                    );
                                                    return value;
                                                }}
                                            />
                                            <Form.Select
                                                name="categoryId"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={
                                                    defaultValues?.categoryId !==
                                                    0
                                                        ? defaultValues?.categoryId
                                                        : ''
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.categoryId) ??
                                                    false
                                                }
                                                options={
                                                    GenderNationalityAgenctList?.data?.categories
                                                        ?.filter((x) => {
                                                            return (
                                                                x?.hide ===
                                                                false
                                                            );
                                                        })
                                                        ?.map((category) => {
                                                            return {
                                                                key: category?.categoryId,
                                                                value: category?.categoryId,
                                                                text:
                                                                    category?.category +
                                                                    ' (' +
                                                                    category?.categoryDescription +
                                                                    ')',
                                                            };
                                                        })
                                                        .sort((a, b) =>
                                                            a.text.localeCompare(
                                                                b.text
                                                            )
                                                        ) || []
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_CATEGORY
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={!manualEntry}
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_SELECT_CATEGORY
                                                }
                                                onChange={(e, { value }) => {
                                                    setValue(
                                                        'categoryId',
                                                        value
                                                    );
                                                    return value;
                                                }}
                                            />
                                            <Form.Select
                                                name="nationality_id"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                value={
                                                    defaultValues?.nationality_id !==
                                                    0
                                                        ? defaultValues?.nationality_id
                                                        : ''
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.nationality) ??
                                                    false
                                                }
                                                options={
                                                    GenderNationalityAgenctList?.data?.nationalities
                                                        ?.map(
                                                            (
                                                                nationalityItem
                                                            ) => {
                                                                return {
                                                                    key: nationalityItem?.Nationality_ID,
                                                                    value: nationalityItem?.Nationality_ID,
                                                                    text: nationalityItem?.Nationality,
                                                                };
                                                            }
                                                        )
                                                        .sort((a, b) =>
                                                            a.text.localeCompare(
                                                                b.text
                                                            )
                                                        ) || []
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_ETHINICITY
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={!manualEntry}
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_SELECT_ETHNICITY
                                                }
                                                onChange={(e, { value }) => {
                                                    setValue(
                                                        'nationality_id',
                                                        value
                                                    );
                                                    return value;
                                                }}
                                            />
                                        </ATMForm.Group>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid></div> */}
                            <ATMGrid>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        tablet={16}
                                        computer={16}
                                        mobile={16}
                                        style={{ width: '100% !important' }}
                                    >
                                        <ATMForm.Group widths={4}>
                                            <Form.Input
                                                value={
                                                    defaultValues?.contact_name
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.contact_name) ??
                                                    false
                                                }
                                                name="contact_name"
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_CONTACT_NAME
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_CONTACT_NAME
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'contact_name',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                            <Form.Input
                                                value={
                                                    defaultValues?.contact_phone_number
                                                }
                                                className={
                                                    styles.leftRightPadding
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.contact_phone_number) ??
                                                    false
                                                }
                                                name="contact_phone_number"
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_CREATE_CONTACT_PHONE_NUMBER
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_NUMBER
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'contact_phone_number',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />

                                            <Form.Input
                                                value={
                                                    defaultValues?.contact_email
                                                }
                                                className={
                                                    styles.leftRightPadding1
                                                }
                                                error={
                                                    (manualEntry &&
                                                        error?.contact_email) ??
                                                    false
                                                }
                                                name="contact_email"
                                                label={
                                                    <RequiredLabel>
                                                        {
                                                            Lang.LBL_SUBCONTRACTOR_CONTACT_EMAIL
                                                        }
                                                    </RequiredLabel>
                                                }
                                                disabled={
                                                    manualEntry == false
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    Lang.PLACEHOLDER
                                                        .SUBCONTRACTOR_ENTER_EMAIL
                                                }
                                                onChange={(e) => {
                                                    setValue(
                                                        'contact_email',
                                                        e.target.value
                                                    );
                                                    return e.target.value;
                                                }}
                                            />
                                        </ATMForm.Group>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                        </>
                    </Form>
                </ATMSegment>
            </Container>
        </>
    );
};
export default SubcontractorCreateView;

import React from 'react';
import Lang from 'src/libraries/language';
import style from './message-constant.module.scss';

export const FunctionalityDisableText: React.FC = () => {
    return (
        <p className={style.disableFunctionalityText}>
            {Lang.MSG_FUNCTIONALITY_DISABLED}
        </p>
    );
};

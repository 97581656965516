import React from 'react';
import * as Contexts from 'src/contexts';
// Initialize environment variables from ~/.env file

//disable console.log method if app is running in production env
if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase().indexOf('prod') !== -1) {
    window.console.log = () => {
        //comment
    };
}

interface AppProps {
    children?: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
    // Combines all the context providers
    const childrenWithContext = Object.keys(Contexts).reduce((child, key) => {
        const ContextProvider = Contexts[key];

        return <ContextProvider>{child}</ContextProvider>;
    }, children);

    return <>{childrenWithContext}</>;
};

export default App;

import React, { useCallback, useMemo, useEffect, useState } from 'react';
import ReportView from './report-view/report.view';
import { useReportContext } from 'src/contexts/report.context';
import XLSX from 'xlsx-js-style';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { statusTypes } from 'src/constants/access.constant';
import { getUser } from 'src/libraries/auth.library';
import { useContractorContext } from 'src/contexts/contractor.context';
import { isAdmin, isPrime, isContact } from 'src/libraries/users.library';
import {
    border,
    leftAlignment,
    rightAlignment,
    centerAlignment,
    reportHeaderBackground,
    reportCellFont,
    reportHeaderFont,
} from 'src/constants/common.constant';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { Card } from 'semantic-ui-react';
import { FunctionalityDisableText } from '../constant/message-constant.component';

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const switchReport: any = (
    report,
    selectSubcontractors,
    selectContractors,
    fetchMonthSummaryAdmin,
    fetchMonthSummaryAdminTierIII,
    fetchMonthSummary,
    fetchMonthSummaryTierIII,
    fetchVerificationFollowUp,
    fetchVerificationFollowUpAdmin,
    fetchPurchaseOrder,
    fetchSpendAmountChanges,
    fetchMonthReportByPrimeContract,
    navigate,
    fetchPrimeInformation,
    AdminfetchSpendAmountChanges
) => {
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();

    switch (Number(report)) {
        case 0:
            break;
        case 1:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            if (!isUserPrime) {
                fetchPrimeInformation({ limit: 20, page: 1 }, [], 'clear');
                // Prime information fetch call
            } else {
                fetchMonthSummary([]);
            }
            break;
        case 6:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            fetchMonthSummaryTierIII([]);
            break;
        case 2:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            if (isUserAdmin || isUserContact) {
                fetchVerificationFollowUpAdmin({ page: 1, limit: 20 });
            } else {
                fetchVerificationFollowUp({ page: 1, limit: 20 });
            }
            break;
        case 3:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            if (isUserAdmin || isUserContact)
                AdminfetchSpendAmountChanges({ page: 1, limit: 20 }, []);
            else if (isUserPrime)
                fetchSpendAmountChanges({ page: 1, limit: 20 }, [], '');
            break;
        case 4:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            if (isUserAdmin || isUserContact) {
                fetchMonthSummaryAdmin({ limit: 20, page: 1 }, [], '');
            } else if (isUserPrime) {
                fetchPurchaseOrder({ page: 1, limit: 20 }, [], '');
            }
            break;
        case 5:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            if (isUserAdmin || isUserContact) {
                fetchMonthReportByPrimeContract({ limit: 20, page: 1 }, []);
            } else if (isUserPrime) {
                fetchPurchaseOrder({ page: 1, limit: 20 }, [], '');
            }
            break;
        case 7:
            navigate('/report');
            selectSubcontractors([]);
            selectContractors([]);
            if (isUserAdmin || isUserContact) {
                fetchMonthSummaryAdminTierIII({ limit: 20, page: 1 }, [], '');
            }
            break;
    }
};

const getStatus = (value: string) => {
    return statusTypes.find((status) => status.value === value)?.key;
};

export const fetchDownload: any = (
    reportType,
    filters,
    reportActions,
    user,
    exportALL,
    FilterData,
    currentApiYear
    // isAdmin
) => {
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();
    switch (reportType) {
        case 1:
            if (filters.length === 0) {
                if (!isUserPrime) {
                    return reportActions?.listPrimeInformationDOWNLOAD({
                        report_year: currentApiYear,
                        primeListing: FilterData.primeListing,
                        username: user.username,
                        sdcType: FilterData.sdcType,
                        status: FilterData.status,
                        page: 1,
                        limit: 20,
                        export: 1,
                    });
                } else {
                    return reportActions?.listMonthSummaryDOWNLOAD({
                        report_year: currentApiYear,
                        vendor_id: localStorage.getItem('loginCredential'),
                        export: exportALL,
                    });
                }
            } else {
                if (!isUserAdmin) {
                    return reportActions?.listMonthSummaryDOWNLOAD({
                        report_year: currentApiYear,
                        vendor_id: localStorage.getItem('loginCredential'),
                        subcontractors_ids: filters.join(),
                        export: exportALL,
                    });
                } else {
                    return reportActions?.listMonthSummaryAdminDOWNLOAD({
                        report_year: currentApiYear,
                        username: user.username,
                        primeListing: filters.join(),
                        export: exportALL,
                        utility: FilterData?.utility ?? 'ALL',
                    });
                }
            }

        case 2:
            if (isUserAdmin || isUserContact) {
                return reportActions?.listNeedingVerificationFollowUpAdminDOWNLOAD(
                    {
                        username: user.username,
                        spend_year: currentApiYear,
                        prime_names: FilterData.primeListing
                            ? FilterData.primeListing === 'ALL'
                                ? ''
                                : FilterData.primeListing
                            : '',
                        verification_expiration_dates:
                            FilterData.verificationExpDates,
                        sdc_names: FilterData.sdcType
                            ? FilterData.sdcType === 'ALL'
                                ? ''
                                : FilterData.sdcType
                            : '',
                        spend_amounts: FilterData.spendData,
                        export: exportALL,
                    }
                );
            } else {
                return reportActions?.listNeedingVerificationFollowUpDOWNLOAD({
                    spend_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    export: exportALL,
                    expDates: FilterData.verificationExpDates,
                });
            }
        case 3:
            if (isUserPrime) {
                return reportActions?.listChangesToSpendAmountsDOWNLOAD({
                    spend_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    subcontractors_ids:
                        FilterData.subcontractors_ids === 'ALL'
                            ? ''
                            : FilterData.subcontractors_ids,
                    contract_numbers:
                        FilterData.contract_numbers === 'ALL'
                            ? ''
                            : FilterData.contract_numbers,
                    spend_date_range: FilterData.spend_date_range,
                    export: exportALL,
                    utility: FilterData?.utility ?? 'ALL',
                });
            } else if (isUserAdmin || isUserContact) {
                return reportActions?.listAdminChangesToSpendAmountsDOWNLOAD({
                    username: user.username,
                    spend_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    page: 1,
                    limit: 20,
                    export: exportALL,
                    sort: 'prime',
                    order: 'ascending',
                    prime_listing: FilterData.primeListing,
                    sdc_type: FilterData.sdcType,
                    utility: FilterData?.utility ?? 'ALL',
                });
            }
            break;

        case 4:
            if (isUserAdmin || isUserContact) {
                return reportActions?.listMonthSummaryAdminDOWNLOAD({
                    report_year: currentApiYear,
                    username: user.username,
                    primeListing:
                        FilterData.primeListing === 'ALL'
                            ? ''
                            : FilterData.primeListing,
                    sdc_names:
                        FilterData.sdcType === 'ALL' ? '' : FilterData.sdcType,
                    export: exportALL,
                    utility: FilterData?.utility ?? 'ALL',
                });
            } else {
                // Prime user
                if (filters.length === 0) {
                    return reportActions?.listByPurchaseOrderDOWNLOAD({
                        spend_year: currentApiYear,
                        vendor_id: localStorage.getItem('loginCredential'),
                        export: exportALL,
                        utility: FilterData?.utility ?? 'ALL',
                    });
                } else {
                    return reportActions?.listByPurchaseOrderDOWNLOAD({
                        spend_year: currentApiYear,
                        vendor_id: localStorage.getItem('loginCredential'),
                        export: exportALL,
                        contract_number: filters.join(),
                        utility: FilterData?.utility ?? 'ALL',
                    });
                }
            }
        case 7:
            // if (isUserAdmin || isUserContact) {
            return reportActions?.listMonthSummaryAdminTierIIIDOWNLOAD({
                report_year: currentApiYear,
                username: user.username,
                primeListing:
                    FilterData.primeListing === 'ALL'
                        ? ''
                        : FilterData.primeListing,
                sdc_names:
                    FilterData.sdcType === 'ALL' ? '' : FilterData.sdcType,
                export: exportALL,
                utility: FilterData?.utility ?? 'ALL',
            });
        // }
        case 5:
            if (isUserAdmin || isUserContact) {
                return reportActions?.listMonthReportByPrimeContractDOWNLOAD({
                    spend_year: currentApiYear,
                    username: user.username,
                    prime_ids: FilterData?.primeListing ?? 'ALL',
                    contract_numbers: FilterData?.contract_numbers ?? 'ALL',
                    utility: FilterData?.utility ?? 'ALL',
                    contact_users: FilterData?.sdcType ?? 'ALL',
                    page: 1,
                    limit: 20,
                    export: 1,
                });
            } else {
                // Prime user
                return <></>;
            }
        case 6:
            if (filters.length === 0) {
                return reportActions?.listMonthSummaryTierIIIDOWNLOAD({
                    report_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    export: exportALL,
                });
            } else {
                return reportActions?.listMonthSummaryTierIIIDOWNLOAD({
                    report_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    subcontractors_ids: filters.join(),
                    export: exportALL,
                });
            }
        default:
            return <></>;
    }
};

export const getMonthSortedData: any = (verifiedData) => {
    return verifiedData
        .filter((i) => i.total > 0)
        .map((i) => ({
            'Spend Type':
                i.subcontractor !== 'Month Totals'
                    ? i.isTier3Spend
                        ? 'Tier III'
                        : 'Tier II'
                    : '',
            Subcontractor:
                i.subcontractor !== 'Month Totals'
                    ? i.subcontractor
                    : i.subcontractor,
            Jan: i.jan == 0 ? '-' : i.jan,
            Feb: i.feb == 0 ? '-' : i.feb,
            Mar: i.mar == 0 ? '-' : i.mar,
            Apr: i.apr == 0 ? '-' : i.apr,
            May: i.may == 0 ? '-' : i.may,
            Jun: i.jun == 0 ? '-' : i.jun,
            Jul: i.jul == 0 ? '-' : i.jul,
            Aug: i.aug == 0 ? '-' : i.aug,
            Sep: i.sep == 0 ? '-' : i.sep,
            Oct: i.oct == 0 ? '-' : i.oct,
            Nov: i.nov == 0 ? '-' : i.nov,
            Dec: i.dec == 0 ? '-' : i.dec,
            Total: i.total == 0 ? '-' : i.total,
        }));
};

//Prime month summary report
export const downloadMonthSummaryReport: any = async (
    subcontractorsSelected,
    fetchDownloadReport
) => {
    let data: any = [];
    if (subcontractorsSelected) {
        data = await fetchDownloadReport(1, subcontractorsSelected);
    } else {
        data = await fetchDownloadReport(1, []);
    }

    let verifiedData: any = [];
    let unVerifiedData: any = [];

    try {
        data.payload.data[0].data.verifySpendTotal.subcontractor =
            'Month Totals';
        data.payload.data[0].data.unVerifySpendTotal.subcontractor =
            'Month Totals';

        verifiedData = [
            ...data?.payload?.data[0]?.data?.verifySpend,
            data?.payload?.data[0]?.data?.verifySpendTotal,
        ];
        unVerifiedData = [
            ...data?.payload?.data[0]?.data?.UnVerifySpend,
            data?.payload?.data[0]?.data?.unVerifySpendTotal,
        ];
    } catch (e) {}

    verifiedData = getMonthSortedData(verifiedData);

    unVerifiedData = getMonthSortedData(unVerifiedData);

    let verified: any = null;
    let unverified: any = null;

    const wscols = [
        { wch: 20 },
        { wch: 40 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
    ];
    try {
        verified = XLSX.utils.json_to_sheet(verifiedData);
        //Format Excel
        for (const key in verified) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                const alignment = { ...centerAlignment, wrapText: true };
                verified[key].s = {
                    fill: reportHeaderBackground,
                    alignment: alignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (key.charAt(0) === 'A' || key.charAt(0) === 'B') {
                    alignment = leftAlignment;
                }
                verified[key].s = {
                    font: reportCellFont,
                    border: border,
                    alignment: alignment,
                };
                if (
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'C' ||
                    key.charAt(0) === 'D' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'E'
                ) {
                    verified[key].z = '#,##0';
                }
            }
        }
    } catch (e) {}
    try {
        unverified = XLSX.utils.json_to_sheet(unVerifiedData);
        //Format Excel
        for (const key in unverified) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                const alignment = { ...centerAlignment, wrapText: true };
                unverified[key].s = {
                    fill: reportHeaderBackground,
                    alignment: alignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (key.charAt(0) === 'A' || key.charAt(0) === 'B') {
                    alignment = leftAlignment;
                }
                let cellFont = {
                    ...reportCellFont,
                    color: { rgb: '000000' },
                };
                if (
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'C' ||
                    key.charAt(0) === 'D' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'E'
                ) {
                    unverified[key].z = '#,##0';
                    cellFont = {
                        ...reportCellFont,
                        color: { rgb: 'FF0000' },
                    };
                }
                unverified[key].s = {
                    font: cellFont,
                    border: border,
                    alignment: alignment,
                };
            }
        }
    } catch (e) {}

    verified['!cols'] = wscols;
    unverified['!cols'] = wscols;

    const workbook = {
        Sheets: {
            'Verified Spend': verified,
            'Non Verified Spend': unverified,
        },
        SheetNames: ['Verified Spend', 'Non Verified Spend'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'sub_by_month_summary_report.xlsx');
};

const downloadMonthSummaryReportTierIII: any = async (
    subcontractorsSelected,
    fetchDownloadReport
) => {
    let data: any = [];
    if (subcontractorsSelected) {
        data = await fetchDownloadReport(6, subcontractorsSelected);
    } else {
        data = await fetchDownloadReport(6, []);
    }

    let verifiedData: any = [];
    let unVerifiedData: any = [];

    try {
        data.payload.data[0].data.verifySpendTotal.subcontractor =
            'Month Totals';
        data.payload.data[0].data.unVerifySpendTotal.subcontractor =
            'Month Totals';

        verifiedData = [
            ...data?.payload?.data[0]?.data?.verifySpend,
            data?.payload?.data[0]?.data?.verifySpendTotal,
        ];
        unVerifiedData = [
            ...data?.payload?.data[0]?.data?.UnVerifySpend,
            data?.payload?.data[0]?.data?.unVerifySpendTotal,
        ];
    } catch (e) {}

    verifiedData = getMonthSortedData(verifiedData);

    unVerifiedData = getMonthSortedData(unVerifiedData);

    verifiedData.map((verify) => {
        delete verify['Spend Type'];
    });

    unVerifiedData.map((unVerify) => {
        delete unVerify['Spend Type'];
    });

    let verified: any = null;
    let unverified: any = null;

    const wscols = [
        { wch: 40 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
    ];
    try {
        verified = XLSX.utils.json_to_sheet(verifiedData);
        //Format Excel
        for (const key in verified) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                const alignment = { ...centerAlignment, wrapText: true };
                verified[key].s = {
                    fill: reportHeaderBackground,
                    alignment: alignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (key.charAt(0) === 'A') {
                    alignment = leftAlignment;
                }
                verified[key].s = {
                    font: reportCellFont,
                    border: border,
                    alignment: alignment,
                };
                if (
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'C' ||
                    key.charAt(0) === 'D' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'E'
                ) {
                    verified[key].z = '#,##0';
                }
            }
        }
    } catch (e) {}
    try {
        unverified = XLSX.utils.json_to_sheet(unVerifiedData);
        //Format Excel
        for (const key in unverified) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                const alignment = { ...centerAlignment, wrapText: true };
                unverified[key].s = {
                    fill: reportHeaderBackground,
                    alignment: alignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (key.charAt(0) === 'A') {
                    alignment = leftAlignment;
                }
                let cellFont = {
                    ...reportCellFont,
                    color: { rgb: '000000' },
                };
                if (
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'C' ||
                    key.charAt(0) === 'D' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'E'
                ) {
                    unverified[key].z = '#,##0';
                    cellFont = {
                        ...reportCellFont,
                        color: { rgb: 'FF0000' },
                    };
                }
                unverified[key].s = {
                    font: cellFont,
                    border: border,
                    alignment: alignment,
                };
            }
        }
    } catch (e) {}

    verified['!cols'] = wscols;
    unverified['!cols'] = wscols;

    const workbook = {
        Sheets: {
            'Verified Spend': verified,
            'Non Verified Spend': unverified,
        },
        SheetNames: ['Verified Spend', 'Non Verified Spend'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'sub_by_month_summary_tier_III_report.xlsx');
};

const getFormattedData = (data) => {
    return data?.map((item) => {
        return {
            data: {
                UnVerifySpend: item?.spend_data?.unVerify_spend,
                unVerifySpendTotal: item?.spend_data?.unVerify_spend_total,
                verifySpend: item?.spend_data?.verify_spend,
                verifySpendTotal: item?.spend_data?.verify_spend_total,
            },
            sdgeAdvisorName: item?.sdge_adv_name,
            socalAdvisorName: item?.socal_adv_name,
            c1: item?.c1,
            c2: item?.c2,
            c3: item?.c3,
            primeName: item?.prime_name,
            contractNumber: item?.contract_number,
            contractDescription: item?.contract_description,
            utility: item?.utility,
            vendorId: item?.vendor_id,
        };
    });
};

export const formatAmount: any = (value) => {
    return Number(value) !== 0 ? value : '0';
};

//Admin prime subcontracting details
export const downloadMonthSummaryAdminReport: any = async (
    reportType,
    primesSelected,
    fetchDownloadReport
) => {
    let data: any = [];
    if (primesSelected) {
        data = await fetchDownloadReport(reportType, primesSelected);
    } else {
        data = await fetchDownloadReport(reportType, []);
    }

    const data1 =
        reportType === 5
            ? getFormattedData(data?.payload?.data) || []
            : data?.payload?.data || [];
    const summeryRawData =
        data?.payload?.filters_data.totalVerifyAndNonverifySpend || [];
    const filtersData = data?.payload?.filters_data || [];
    let verifiedData: any = [];
    let unVerifiedData: any = [];
    data1.map((i) => {
        i.data.verifySpendTotal.subcontractor = 'Month Totals';
        i.data.unVerifySpendTotal.subcontractor = 'Month Totals';
        i.data.verifySpendTotal.primeName = i.primeName;
        i.data.unVerifySpendTotal.primeName = i.primeName;
        i.data.verifySpendTotal.sdgeAdvisorName =
            reportType === 4 ? i.sdge_adv_name : i.sdgeAdvisorName;
        i.data.unVerifySpendTotal.sdgeAdvisorName =
            reportType === 4 ? i.sdge_adv_name : i.sdgeAdvisorName;
        i.data.verifySpendTotal.socalAdvisorName =
            reportType === 4 ? i.socal_adv_name : i.socalAdvisorName;
        i.data.unVerifySpendTotal.socalAdvisorName =
            reportType === 4 ? i.socal_adv_name : i.socalAdvisorName;
        i.data.verifySpendTotal.contact1Name = reportType === 4 ? i.c1 : i.c1;
        i.data.unVerifySpendTotal.contact1Name = reportType === 4 ? i.c1 : i.c1;
        i.data.verifySpendTotal.contact2Name = reportType === 4 ? i.c2 : i.c2;
        i.data.unVerifySpendTotal.contact2Name = reportType === 4 ? i.c2 : i.c2;
        i.data.verifySpendTotal.contact3Name = reportType === 4 ? i.c3 : i.c3;
        i.data.unVerifySpendTotal.contact3Name = reportType === 4 ? i.c3 : i.c3;
        i.data.verifySpendTotal.contractNumber = i.contractNumber;
        i.data.unVerifySpendTotal.contractNumber = i.contractNumber;
        i.data.verifySpendTotal.contractDescription = i.contractDescription;
        i.data.unVerifySpendTotal.contractDescription = i.contractDescription;
        i.data.verifySpendTotal.utility = i.utility;
        i.data.unVerifySpendTotal.utility = i.utility;

        i.data.UnVerifySpend = i.data.UnVerifySpend.map((j) => {
            j.prime = i.primeName;
            return {
                ...j,
                primeName: i.primeName,
                sdgeAdvisorName:
                    reportType === 4 ? i.sdge_adv_name : i.sdgeAdvisorName,
                socalAdvisorName:
                    reportType === 4 ? i.socal_adv_name : i.socalAdvisorName,
                contact1Name: reportType === 4 ? i.c1 : i.c1,
                contact2Name: reportType === 4 ? i.c2 : i.c2,
                contact3Name: reportType === 4 ? i.c3 : i.c3,
                contractNumber: i.contractNumber,
                contractDescription: i.contractDescription,
                utility: i.utility,
            };
        });
        i.data.verifySpend = i.data.verifySpend.map((j) => {
            j.prime = i.primeName;

            return {
                ...j,
                primeName: i.primeName,
                sdgeAdvisorName:
                    reportType === 4 ? i.sdge_adv_name : i.sdgeAdvisorName,
                socalAdvisorName:
                    reportType === 4 ? i.socal_adv_name : i.socalAdvisorName,
                contact1Name: reportType === 4 ? i.c1 : i.c1,
                contact2Name: reportType === 4 ? i.c2 : i.c2,
                contact3Name: reportType === 4 ? i.c3 : i.c3,
                contractNumber: i.contractNumber,
                contractDescription: i.contractDescription,
                utility: i.utility,
            };
        });

        verifiedData.push(...i.data.verifySpend, i.data.verifySpendTotal);
        unVerifiedData.push(...i.data.UnVerifySpend, i.data.unVerifySpendTotal);
    });

    const getContactNames = (data) => {
        let contactNames = data.sdgeAdvisorName
            ? data.sdgeAdvisorName + '\n'
            : '';
        contactNames += data.socalAdvisorName
            ? data.socalAdvisorName + '\n'
            : '';
        contactNames += data.contact1Name ? data.contact1Name + '\n' : '';
        contactNames += data.contact2Name ? data.contact2Name + '\n' : '';
        contactNames += data.contact3Name ? data.contact3Name : '';
        return contactNames;
    };

    verifiedData = verifiedData
        .filter((ver) => ver.total > 0)
        .map((i) => {
            return {
                Utility: i.utility,
                Prime: i.primeName,
                'Supplier Diversity Contact/Contacts': getContactNames(i),
                'Contract Number': i.contractNumber,
                'Project Description': i?.contractDescription,
                'Spend Type':
                    i.subcontractor === 'Month Totals'
                        ? ''
                        : i.isTier3Spend
                          ? 'Tier III'
                          : 'Tier II',
                Subcontractor:
                    i.subcontractor != 'Month Totals'
                        ? i.subcontractor
                        : i.subcontractor,
                Jan: i.jan != 0 ? i.jan : '-',
                Feb: i.feb != 0 ? i.feb : '-',
                Mar: i.mar != 0 ? i.mar : '-',
                Apr: i.apr != 0 ? i.apr : '-',
                May: i.may != 0 ? i.may : '-',
                Jun: i.jun != 0 ? i.jun : '-',
                Jul: i.jul != 0 ? i.jul : '-',
                Aug: i.aug != 0 ? i.aug : '-',
                Sep: i.sep != 0 ? i.sep : '-',
                Oct: i.oct != 0 ? i.oct : '-',
                Nov: i.nov != 0 ? i.nov : '-',
                Dec: i.dec != 0 ? i.dec : '-',
                Total: i.total != 0 ? i.total : '-',
            };
        });

    unVerifiedData = unVerifiedData
        .filter((unVer) => unVer.total > 0)
        .map((i) => ({
            Utility: i.utility,
            Prime: i.primeName,
            'Supplier Diversity Contact/Contacts': getContactNames(i),
            'Contract Number': i.contractNumber,
            'Project Description': i?.contractDescription,
            'Spend Type':
                i.subcontractor === 'Month Totals'
                    ? ''
                    : i.isTier3Spend
                      ? 'Tier III'
                      : 'Tier II',
            Subcontractor:
                i.subcontractor != 'Month Totals'
                    ? i.subcontractor
                    : i.subcontractor,
            Jan: i.jan != 0 ? i.jan : '-',
            Feb: i.feb != 0 ? i.feb : '-',
            Mar: i.mar != 0 ? i.mar : '-',
            Apr: i.apr != 0 ? i.apr : '-',
            May: i.may != 0 ? i.may : '-',
            Jun: i.jun != 0 ? i.jun : '-',
            Jul: i.jul != 0 ? i.jul : '-',
            Aug: i.aug != 0 ? i.aug : '-',
            Sep: i.sep != 0 ? i.sep : '-',
            Oct: i.oct != 0 ? i.oct : '-',
            Nov: i.nov != 0 ? i.nov : '-',
            Dec: i.dec != 0 ? i.dec : '-',
            Total: i.total != 0 ? i.total : '-',
        }));

    if (reportType === 4) {
        verifiedData.forEach(function (v) {
            delete v['Project Description'],
                delete v['Contract Number'],
                delete v['Contract Description'],
                delete v['Utility'];
        });
        unVerifiedData.forEach(function (v) {
            delete v['Project Description'],
                delete v['Contract Number'],
                delete v['Contract Description'],
                delete v['Utility'];
        });
    }

    let verified: any = null;
    let unverified: any = null;
    let summary: any = null;

    const wscolsSubcontractorSummary = [
        { wch: 30 },
        { wch: 45 },
        { wch: 45 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
    ];
    const wscolsSubcontractorDetails = [
        { wch: 30 },
        { wch: 45 },
        { wch: 25 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
    ];

    const wscolsSummary = [
        { wch: 45 },
        { wch: 45 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 45 },
    ];
    let utilityName = 'ALL';
    if (filtersData.utilityID != 'ALL') {
        // utilityName = filtersData?.UtilityData.filter(x => x.Sempra_Company_ID == filtersData?.utilityID).map(x => x.Sempra_Company_Name);
        if (filtersData.hasOwnProperty('UtilityData')) {
            filtersData.UtilityData.forEach((element) => {
                if (element.Sempra_Company_ID == filtersData.utilityID) {
                    utilityName = element.Sempra_Company_Name;
                }
            });
        }

        // utilityName = utilityName[0];
    }

    const summaryData = [
        {
            Utility: utilityName,
            'Verified/Non-Verified Spend': 'Total Verified Spend',
            Jan: formatAmount(summeryRawData.janTotal),
            Feb: formatAmount(summeryRawData.febTotal),
            Mar: formatAmount(summeryRawData.marTotal),
            Apr: formatAmount(summeryRawData.aprTotal),
            May: formatAmount(summeryRawData.mayTotal),
            Jun: formatAmount(summeryRawData.junTotal),
            Jul: formatAmount(summeryRawData.julTotal),
            Aug: formatAmount(summeryRawData.augTotal),
            Sep: formatAmount(summeryRawData.sepTotal),
            Oct: formatAmount(summeryRawData.octTotal),
            Nov: formatAmount(summeryRawData.novTotal),
            Dec: formatAmount(summeryRawData.decTotal),
            Total: formatAmount(summeryRawData.total_verify_spend),
        },
        {
            Utility: utilityName,
            'Verified/Non-Verified Spend': 'Total Non-Verified Spend',
            Jan: formatAmount(summeryRawData.unveify_janTotal),
            Feb: formatAmount(summeryRawData.unveify_febTotal),
            Mar: formatAmount(summeryRawData.unveify_marTotal),
            Apr: formatAmount(summeryRawData.unveify_aprTotal),
            May: formatAmount(summeryRawData.unveify_mayTotal),
            Jun: formatAmount(summeryRawData.unveify_junTotal),
            Jul: formatAmount(summeryRawData.unveify_julTotal),
            Aug: formatAmount(summeryRawData.unveify_augTotal),
            Sep: formatAmount(summeryRawData.unveify_sepTotal),
            Oct: formatAmount(summeryRawData.unveify_octTotal),
            Nov: formatAmount(summeryRawData.unveify_novTotal),
            Dec: formatAmount(summeryRawData.unveify_decTotal),
            Total: formatAmount(summeryRawData.total_unverify_spend),
        },
        {
            Utility: utilityName,
            'Verified/Non-Verified Spend': 'Grand Total',
            Jan: formatAmount(summeryRawData.grand_janTotal),
            Feb: formatAmount(summeryRawData.grand_febTotal),
            Mar: formatAmount(summeryRawData.grand_marTotal),
            Apr: formatAmount(summeryRawData.grand_aprTotal),
            May: formatAmount(summeryRawData.grand_mayTotal),
            Jun: formatAmount(summeryRawData.grand_junTotal),
            Jul: formatAmount(summeryRawData.grand_julTotal),
            Aug: formatAmount(summeryRawData.grand_augTotal),
            Sep: formatAmount(summeryRawData.grand_sepTotal),
            Oct: formatAmount(summeryRawData.grand_octTotal),
            Nov: formatAmount(summeryRawData.grand_novTotal),
            Dec: formatAmount(summeryRawData.grand_decTotal),
            Total: formatAmount(summeryRawData.grand_total_verify_n_nonverify),
        },
    ];

    //Format Excel Verified
    try {
        verified = XLSX.utils.json_to_sheet(verifiedData);
        if (reportType === 5) {
            //Admin prime subcontracting details
            let bgColor = 'FFFFFF';
            for (const key in verified) {
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    verified[key].s = {
                        fill: reportHeaderBackground,
                        alignment: centerAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    if (verified[key].v === 'Tier III') {
                        // bgColor = 'F3FFEB';
                    } else if (verified[key].v === 'Tier II') {
                        bgColor = 'FFFFFF';
                    }
                    let alignment = rightAlignment;
                    if (
                        key.charAt(0) === 'A' ||
                        key.charAt(0) === 'B' ||
                        key.charAt(0) === 'C' ||
                        key.charAt(0) === 'D' ||
                        key.charAt(0) === 'E' ||
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'G'
                    ) {
                        alignment = leftAlignment;
                    }
                    verified[key].s = {
                        font: reportCellFont,
                        border: border,
                        alignment: alignment,
                    };
                    if (key.charAt(0) === 'G') {
                        verified[key].s = {
                            ...verified[key].s,
                            fill: {
                                fgColor: { rgb: bgColor },
                                patternType: 'solid',
                            },
                        };
                    }
                    if (
                        key.charAt(0) === 'Q' ||
                        key.charAt(0) === 'R' ||
                        key.charAt(0) === 'S' ||
                        key.charAt(0) === 'T' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'I' ||
                        key.charAt(0) === 'J' ||
                        key.charAt(0) === 'K' ||
                        key.charAt(0) === 'L' ||
                        key.charAt(0) === 'M' ||
                        key.charAt(0) === 'N' ||
                        key.charAt(0) === 'O' ||
                        key.charAt(0) === 'P'
                    ) {
                        verified[key].s = {
                            ...verified[key].s,
                            fill: {
                                fgColor: { rgb: bgColor },
                                patternType: 'solid',
                            },
                        };
                        verified[key].z = '#,##0';
                    }
                }
            }
        } else {
            //Admin prime subcontracting summary
            for (const key in verified) {
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    verified[key].s = {
                        fill: reportHeaderBackground,
                        alignment: centerAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = rightAlignment;
                    if (
                        key.charAt(0) === 'A' ||
                        key.charAt(0) === 'B' ||
                        key.charAt(0) === 'C' ||
                        key.charAt(0) === 'D'
                    ) {
                        alignment = leftAlignment;
                    }
                    verified[key].s = {
                        font: reportCellFont,
                        border: border,
                        alignment: alignment,
                    };
                    if (
                        key.charAt(0) === 'Q' ||
                        key.charAt(0) === 'E' ||
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'I' ||
                        key.charAt(0) === 'J' ||
                        key.charAt(0) === 'K' ||
                        key.charAt(0) === 'L' ||
                        key.charAt(0) === 'M' ||
                        key.charAt(0) === 'N' ||
                        key.charAt(0) === 'O' ||
                        key.charAt(0) === 'P'
                    ) {
                        verified[key].z = '#,##0';
                    }
                }
            }
        }
    } catch (e) {}

    //Format Excel Non-Verified
    try {
        unverified = XLSX.utils.json_to_sheet(unVerifiedData);
        if (reportType === 5) {
            let bgColor = 'FFFFFF';
            //Admin prime subcontracting details
            for (const key in unverified) {
                //The first line

                if (key.replace(/[^0-9]/gi, '') === '1') {
                    unverified[key].s = {
                        fill: reportHeaderBackground,
                        alignment: centerAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    if (unverified[key].v === 'Tier III') {
                        // bgColor = 'F3FFEB';
                    } else if (unverified[key].v === 'Tier II') {
                        bgColor = 'FFFFFF';
                    }
                    let alignment = rightAlignment;
                    if (
                        key.charAt(0) === 'A' ||
                        key.charAt(0) === 'B' ||
                        key.charAt(0) === 'D' ||
                        key.charAt(0) === 'E' ||
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'C' ||
                        key.charAt(0) === 'G'
                    ) {
                        alignment = leftAlignment;
                    }
                    let cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };
                    if (key.charAt(0) === 'G') {
                        unverified[key].s = {
                            ...unverified[key].s,
                            fill: {
                                fgColor: { rgb: bgColor },
                                patternType: 'solid',
                            },
                        };
                    }
                    if (
                        key.charAt(0) === 'Q' ||
                        key.charAt(0) === 'R' ||
                        key.charAt(0) === 'S' ||
                        key.charAt(0) === 'T' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'I' ||
                        key.charAt(0) === 'J' ||
                        key.charAt(0) === 'K' ||
                        key.charAt(0) === 'L' ||
                        key.charAt(0) === 'M' ||
                        key.charAt(0) === 'N' ||
                        key.charAt(0) === 'O' ||
                        key.charAt(0) === 'P'
                    ) {
                        unverified[key].z = '#,##0';
                        cellFont = {
                            ...reportCellFont,
                            color: { rgb: 'FF0000' },
                        };
                    }
                    unverified[key].s = {
                        fill: {
                            fgColor: { rgb: bgColor },
                            patternType: 'solid',
                        },
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        } else {
            //Admin prime subcontracting summary
            for (const key in unverified) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    unverified[key].s = {
                        fill: reportHeaderBackground,
                        alignment: centerAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = rightAlignment;
                    if (
                        key.charAt(0) === 'A' ||
                        key.charAt(0) === 'B' ||
                        key.charAt(0) === 'C' ||
                        key.charAt(0) === 'D'
                    ) {
                        alignment = leftAlignment;
                    }
                    let cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };

                    if (
                        key.charAt(0) === 'Q' ||
                        key.charAt(0) === 'E' ||
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'I' ||
                        key.charAt(0) === 'J' ||
                        key.charAt(0) === 'K' ||
                        key.charAt(0) === 'L' ||
                        key.charAt(0) === 'M' ||
                        key.charAt(0) === 'N' ||
                        key.charAt(0) === 'O' ||
                        key.charAt(0) === 'P'
                    ) {
                        unverified[key].z = '#,##0';
                        cellFont = {
                            ...reportCellFont,
                            color: { rgb: 'FF0000' },
                        };
                    }
                    unverified[key].s = {
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        }
    } catch (e) {}

    // format excel summery
    try {
        summary = XLSX.utils.json_to_sheet(summaryData);

        //subcontracting summery details
        for (const key in summary) {
            if (key.replace(/[^0-9]/gi, '') === '1') {
                summary[key].s = {
                    fill: reportHeaderBackground,
                    alignment: centerAlignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (key.charAt(0) === 'A' || key.charAt(0) === 'B') {
                    alignment = leftAlignment;
                } else {
                    summary[key].z = '#,##0';
                }
                summary[key].s = {
                    font: reportCellFont,
                    border: border,
                    alignment: alignment,
                };

                const cellFontVerified = {
                    ...reportCellFont,
                    color: { rgb: '008000' },
                };
                const cellFontNonVerified = {
                    ...reportCellFont,
                    color: { rgb: 'FF0000' },
                };
                summary['B2'].s = {
                    font: cellFontVerified,
                    border: border,
                    alignment: leftAlignment,
                };
                summary['B3'].s = {
                    font: cellFontNonVerified,
                    border: border,
                    alignment: leftAlignment,
                };
            }
        }
    } catch (e) {}

    verified['!cols'] =
        reportType === 5
            ? wscolsSubcontractorDetails
            : wscolsSubcontractorSummary;
    unverified['!cols'] =
        reportType === 5
            ? wscolsSubcontractorDetails
            : wscolsSubcontractorSummary;

    summary['!cols'] = wscolsSummary;

    const workbook = {
        Sheets: {
            'Total Spend': summary,
            'Verified Spend': verified,
            'Non Verified Spend': unverified,
        },
        SheetNames: ['Total Spend', 'Verified Spend', 'Non Verified Spend'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(
        excelBuffer,
        reportType === 4
            ? 'prime_subcontracting_summary.xlsx'
            : 'prime_subcontracting_details.xlsx'
    );
};

export const downloadMonthSummaryAdminTierIIIReport: any = async (
    reportType,
    primesSelected,
    fetchDownloadReport
) => {
    let data: any = [];
    if (primesSelected) {
        data = await fetchDownloadReport(reportType, primesSelected);
    } else {
        data = await fetchDownloadReport(reportType, []);
    }

    const data1 = data?.payload?.data || [];

    const summeryRawData =
        data?.payload?.filters_data.totalVerifyAndNonverifySpend || [];

    const filtersData = data?.payload?.filters_data || [];
    let verifiedData: any = [];
    let unVerifiedData: any = [];
    data1.map((i) => {
        i.data.verifySpendTotal.subcontractor = 'Month Totals';
        i.data.unVerifySpendTotal.subcontractor = 'Month Totals';
        i.data.verifySpendTotal.primeName = i.primeName;
        i.data.unVerifySpendTotal.primeName = i.primeName;
        i.data.verifySpendTotal.sdgeAdvisorName = i.sdge_adv_name;
        i.data.unVerifySpendTotal.sdgeAdvisorName = i.sdge_adv_name;
        i.data.verifySpendTotal.socalAdvisorName = i.socal_adv_name;
        i.data.unVerifySpendTotal.socalAdvisorName = i.socal_adv_name;
        i.data.verifySpendTotal.contact1Name = i.c1;
        i.data.unVerifySpendTotal.contact1Name = i.c1;
        i.data.verifySpendTotal.contact2Name = i.c2;
        i.data.unVerifySpendTotal.contact2Name = i.c2;
        i.data.verifySpendTotal.contact3Name = i.c3;
        i.data.unVerifySpendTotal.contact3Name = i.c3;
        i.data.verifySpendTotal.contractNumber = i.contractNumber;
        i.data.unVerifySpendTotal.contractNumber = i.contractNumber;
        i.data.verifySpendTotal.contractDescription = i.contractDescription;
        i.data.unVerifySpendTotal.contractDescription = i.contractDescription;
        i.data.verifySpendTotal.utility = i.utility;
        i.data.unVerifySpendTotal.utility = i.utility;

        i.data.UnVerifySpend = i.data.UnVerifySpend.map((j) => {
            j.prime = i.primeName;
            return {
                ...j,
                primeName: i.primeName,
                sdgeAdvisorName: i.sdge_adv_name,
                socalAdvisorName: i.socal_adv_name,
                contact1Name: i.c1,
                contact2Name: i.c2,
                contact3Name: i.c3,
                contractNumber: i.contractNumber,
                contractDescription: i.contractDescription,
                utility: i.utility,
            };
        });
        i.data.verifySpend = i.data.verifySpend.map((j) => {
            j.prime = i.primeName;

            return {
                ...j,
                primeName: i.primeName,
                sdgeAdvisorName: i.sdge_adv_name,
                socalAdvisorName: i.socal_adv_name,
                contact1Name: i.c1,
                contact2Name: i.c2,
                contact3Name: i.c3,
                contractNumber: i.contractNumber,
                contractDescription: i.contractDescription,
                utility: i.utility,
            };
        });

        verifiedData.push(...i.data.verifySpend, i.data.verifySpendTotal);
        unVerifiedData.push(...i.data.UnVerifySpend, i.data.unVerifySpendTotal);
    });
    const getContactNames = (data) => {
        let contactNames = data.sdgeAdvisorName
            ? data.sdgeAdvisorName + '\n'
            : '';
        contactNames += data.socalAdvisorName
            ? data.socalAdvisorName + '\n'
            : '';
        contactNames += data.contact1Name ? data.contact1Name + '\n' : '';
        contactNames += data.contact2Name ? data.contact2Name + '\n' : '';
        contactNames += data.contact3Name ? data.contact3Name : '';
        return contactNames;
    };

    verifiedData = verifiedData
        .filter((ver) => ver.total > 0)
        .map((i) => {
            return {
                Utility: i.utility,
                Prime: i.primeName,
                'Supplier Diversity Contact/Contacts': getContactNames(i),
                'Contract Number': i.contractNumber,
                'Project Description': i?.contractDescription,
                // 'Spend Type': i.isTier3Spend ? 'Tier III' : 'Tier II',
                Subcontractor:
                    i.subcontractor != 'Month Totals'
                        ? i.subcontractor
                        : i.subcontractor,
                Jan: i.jan != 0 ? i.jan : '-',
                Feb: i.feb != 0 ? i.feb : '-',
                Mar: i.mar != 0 ? i.mar : '-',
                Apr: i.apr != 0 ? i.apr : '-',
                May: i.may != 0 ? i.may : '-',
                Jun: i.jun != 0 ? i.jun : '-',
                Jul: i.jul != 0 ? i.jul : '-',
                Aug: i.aug != 0 ? i.aug : '-',
                Sep: i.sep != 0 ? i.sep : '-',
                Oct: i.oct != 0 ? i.oct : '-',
                Nov: i.nov != 0 ? i.nov : '-',
                Dec: i.dec != 0 ? i.dec : '-',
                Total: i.total != 0 ? i.total : '-',
            };
        });

    unVerifiedData = unVerifiedData
        .filter((unVer) => unVer.total > 0)
        .map((i) => ({
            Utility: i.utility,
            Prime: i.primeName,
            'Supplier Diversity Contact/Contacts': getContactNames(i),
            'Contract Number': i.contractNumber,
            'Project Description': i?.contractDescription,
            Subcontractor:
                i.subcontractor != 'Month Totals'
                    ? i.subcontractor
                    : i.subcontractor,
            Jan: i.jan != 0 ? i.jan : '-',
            Feb: i.feb != 0 ? i.feb : '-',
            Mar: i.mar != 0 ? i.mar : '-',
            Apr: i.apr != 0 ? i.apr : '-',
            May: i.may != 0 ? i.may : '-',
            Jun: i.jun != 0 ? i.jun : '-',
            Jul: i.jul != 0 ? i.jul : '-',
            Aug: i.aug != 0 ? i.aug : '-',
            Sep: i.sep != 0 ? i.sep : '-',
            Oct: i.oct != 0 ? i.oct : '-',
            Nov: i.nov != 0 ? i.nov : '-',
            Dec: i.dec != 0 ? i.dec : '-',
            Total: i.total != 0 ? i.total : '-',
        }));

    verifiedData.forEach(function (v) {
        delete v['Project Description'],
            delete v['Contract Number'],
            delete v['Contract Description'],
            delete v['Utility'];
    });
    unVerifiedData.forEach(function (v) {
        delete v['Project Description'],
            delete v['Contract Number'],
            delete v['Contract Description'],
            delete v['Utility'];
    });

    let verified: any = null;
    let unverified: any = null;
    let summary: any = null;

    const wscolsSubcontractorSummary = [
        { wch: 30 },
        { wch: 45 },
        { wch: 45 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
    ];
    let utilityName = 'ALL';
    if (filtersData.utilityID != 'ALL') {
        // utilityName = filtersData?.UtilityData.filter(x => x.Sempra_Company_ID == filtersData?.utilityID).map(x => x.Sempra_Company_Name);
        if (filtersData.hasOwnProperty('UtilityData')) {
            filtersData.UtilityData.forEach((element) => {
                if (element.Sempra_Company_ID == filtersData.utilityID) {
                    utilityName = element.Sempra_Company_Name;
                }
            });
        }

        // utilityName = utilityName[0];
    }

    const summaryData = [
        {
            Utility: utilityName,
            'Verified/Non-Verified Spend': 'Total Verified Spend',
            Jan: formatAmount(summeryRawData.janTotal),
            Feb: formatAmount(summeryRawData.febTotal),
            Mar: formatAmount(summeryRawData.marTotal),
            Apr: formatAmount(summeryRawData.aprTotal),
            May: formatAmount(summeryRawData.mayTotal),
            Jun: formatAmount(summeryRawData.junTotal),
            Jul: formatAmount(summeryRawData.julTotal),
            Aug: formatAmount(summeryRawData.augTotal),
            Sep: formatAmount(summeryRawData.sepTotal),
            Oct: formatAmount(summeryRawData.octTotal),
            Nov: formatAmount(summeryRawData.novTotal),
            Dec: formatAmount(summeryRawData.decTotal),
            Total: formatAmount(summeryRawData.total_verify_spend),
        },
        {
            Utility: utilityName,
            'Verified/Non-Verified Spend': 'Total Non-Verified Spend',
            Jan: formatAmount(summeryRawData.unveify_janTotal),
            Feb: formatAmount(summeryRawData.unveify_febTotal),
            Mar: formatAmount(summeryRawData.unveify_marTotal),
            Apr: formatAmount(summeryRawData.unveify_aprTotal),
            May: formatAmount(summeryRawData.unveify_mayTotal),
            Jun: formatAmount(summeryRawData.unveify_junTotal),
            Jul: formatAmount(summeryRawData.unveify_julTotal),
            Aug: formatAmount(summeryRawData.unveify_augTotal),
            Sep: formatAmount(summeryRawData.unveify_sepTotal),
            Oct: formatAmount(summeryRawData.unveify_octTotal),
            Nov: formatAmount(summeryRawData.unveify_novTotal),
            Dec: formatAmount(summeryRawData.unveify_decTotal),
            Total: formatAmount(summeryRawData.total_unverify_spend),
        },
        {
            Utility: utilityName,
            'Verified/Non-Verified Spend': 'Grand Total',
            Jan: formatAmount(summeryRawData.grand_janTotal),
            Feb: formatAmount(summeryRawData.grand_febTotal),
            Mar: formatAmount(summeryRawData.grand_marTotal),
            Apr: formatAmount(summeryRawData.grand_aprTotal),
            May: formatAmount(summeryRawData.grand_mayTotal),
            Jun: formatAmount(summeryRawData.grand_junTotal),
            Jul: formatAmount(summeryRawData.grand_julTotal),
            Aug: formatAmount(summeryRawData.grand_augTotal),
            Sep: formatAmount(summeryRawData.grand_sepTotal),
            Oct: formatAmount(summeryRawData.grand_octTotal),
            Nov: formatAmount(summeryRawData.grand_novTotal),
            Dec: formatAmount(summeryRawData.grand_decTotal),
            Total: formatAmount(summeryRawData.grand_total_verify_n_nonverify),
        },
    ];

    //Format Excel Verified
    try {
        verified = XLSX.utils.json_to_sheet(verifiedData);

        //Admin prime subcontracting summary
        for (const key in verified) {
            if (key.replace(/[^0-9]/gi, '') === '1') {
                verified[key].s = {
                    fill: reportHeaderBackground,
                    alignment: centerAlignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (
                    key.charAt(0) === 'A' ||
                    key.charAt(0) === 'B' ||
                    key.charAt(0) === 'C'
                ) {
                    alignment = leftAlignment;
                }
                verified[key].s = {
                    font: reportCellFont,
                    border: border,
                    alignment: alignment,
                };
                if (
                    key.charAt(0) === 'Q' ||
                    key.charAt(0) === 'E' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'P'
                ) {
                    verified[key].z = '#,##0';
                }
            }
        }
    } catch (e) {}

    //Format Excel Non-Verified
    try {
        unverified = XLSX.utils.json_to_sheet(unVerifiedData);

        //Admin prime subcontracting summary
        for (const key in unverified) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                unverified[key].s = {
                    fill: reportHeaderBackground,
                    alignment: centerAlignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (
                    key.charAt(0) === 'A' ||
                    key.charAt(0) === 'B' ||
                    key.charAt(0) === 'C'
                ) {
                    alignment = leftAlignment;
                }
                let cellFont = {
                    ...reportCellFont,
                    color: { rgb: '000000' },
                };

                if (
                    key.charAt(0) === 'Q' ||
                    key.charAt(0) === 'E' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'P'
                ) {
                    unverified[key].z = '#,##0';
                    cellFont = {
                        ...reportCellFont,
                        color: { rgb: 'FF0000' },
                    };
                }
                unverified[key].s = {
                    font: cellFont,
                    border: border,
                    alignment: alignment,
                };
            }
        }
    } catch (e) {}

    // format excel summery
    try {
        summary = XLSX.utils.json_to_sheet(summaryData);

        //subcontracting summery details
        for (const key in summary) {
            if (key.replace(/[^0-9]/gi, '') === '1') {
                summary[key].s = {
                    fill: reportHeaderBackground,
                    alignment: centerAlignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = rightAlignment;
                if (key.charAt(0) === 'A' || key.charAt(0) === 'B') {
                    alignment = leftAlignment;
                } else {
                    summary[key].z = '#,##0';
                }
                summary[key].s = {
                    font: reportCellFont,
                    border: border,
                    alignment: alignment,
                };

                const cellFontVerified = {
                    ...reportCellFont,
                    color: { rgb: '008000' },
                };
                const cellFontNonVerified = {
                    ...reportCellFont,
                    color: { rgb: 'FF0000' },
                };
                summary['B2'].s = {
                    font: cellFontVerified,
                    border: border,
                    alignment: leftAlignment,
                };
                summary['B3'].s = {
                    font: cellFontNonVerified,
                    border: border,
                    alignment: leftAlignment,
                };
            }
        }
    } catch (e) {}

    verified['!cols'] = wscolsSubcontractorSummary;
    unverified['!cols'] = wscolsSubcontractorSummary;

    // summary['!cols'] = wscolsSummary;

    const workbook = {
        Sheets: {
            // 'Total Spend': summary,
            'Verified Spend': verified,
            'Non Verified Spend': unverified,
        },
        SheetNames: ['Verified Spend', 'Non Verified Spend'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'prime_subcontracting_summary_tier_III.xlsx');
};

export const downloadVerificationFollowUp: any = async (
    fetchDownloadReport
) => {
    const isUserPrime = isPrime();
    let data: any = [];
    data = await fetchDownloadReport(2, []);
    let data1 = [];
    if (isUserPrime) {
        data1 = data?.payload?.data?.map((j) => ({
            Subcontractor: j.subcontractor_name,
            'Subcontractor Contact Name': j.contactName,
            'Subcontractor Email': j.contactEmail,
            'Subcontractor Phone': j.contactPhone,
            VON: j.verification_number,
            'Verification Expiration Date': j.verification_expiration_date
                ? `${moment(j.verification_expiration_date).format(
                      'MM/DD/YYYY'
                  )}`
                : `-`,
            'Verification Expiration Status': j.verificationStatus?.label,
            'Subcontractor Spend YTD': j.spendAmount,
        }));
    } else {
        const createFollowUpReportData = (
            primeInformation,
            sdcName,
            subcontractorsList
        ) => {
            return subcontractorsList?.map((data) => ({
                Prime: primeInformation?.primeName,
                'Primary Contact': primeInformation?.contactName,
                'Primary Phone': primeInformation?.phone,
                'Primary Email': primeInformation?.email,
                // Address: primeInformation?.address,
                // State: primeInformation?.state,
                // City: primeInformation?.city,
                // Zip: primeInformation?.zip,
                'Supplier Diversity Contact/Contacts': `${
                    sdcName?.contact1 ? sdcName?.contact1 + '\n' : ''
                }${sdcName?.contact2 ? sdcName?.contact2 + '\n' : ''}${
                    sdcName?.contact3 ? sdcName?.contact3 + '\n' : ''
                }${sdcName?.sdgeName ? sdcName.sdgeName + '\n' : ''}${
                    sdcName.socalName
                }`,
                Subcontractor: data.subContractorName,
                'Subcontractor Contact Name': data.contactName,
                'Subcontractor Email': data.contactEmail,
                'Subcontractor Phone': data.contactPhone,
                'Diversity Status': `${data.nationality + '/' + data.gender}`,
                VON: data?.verificationNumber,
                'Verification Expiration Date': data.verificationExpirationDate
                    ? `${moment(data.verificationExpirationDate).format(
                          'MM/DD/YYYY'
                      )}`
                    : `-`,
                'Verification Expiration Status':
                    data?.verificationStatus?.label,
                'Subcontractor Spend YTD': data?.totalSpendAmount,
            }));
        };

        data1 = data?.payload?.data?.flatMap((j) => {
            return createFollowUpReportData(
                j?.primeInformation,
                j?.sdcName,
                j?.subcontractorsList
            );
        });
    }

    let followUp: any = [];
    const wscolsNonPrime = [
        { wch: 40 },
        { wch: 25 },
        { wch: 15 },
        { wch: 35 },
        { wch: 35 },
        { wch: 40 },
        { wch: 20 },
        { wch: 30 },
        { wch: 25 },
        { wch: 40 },
        { wch: 15 },
        { wch: 20 },
        { wch: 30 },
        { wch: 20 },
    ];
    const wscolsPrime = [
        { wch: 40 },
        { wch: 35 },
        { wch: 35 },
        { wch: 25 },
        { wch: 12 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
    ];

    try {
        followUp = XLSX.utils.json_to_sheet(data1);
        console.log('*******', JSON.stringify(followUp));
        //Format Excel
        if (isUserPrime) {
            for (const key in followUp) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    followUp[key].s = {
                        fill: reportHeaderBackground,
                        alignment: leftAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = leftAlignment;

                    if (key.charAt(0) === 'H') {
                        alignment = rightAlignment;
                    }

                    let cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };

                    if (key.charAt(0) === 'H') {
                        cellFont = {
                            ...reportCellFont,
                            color: { rgb: 'FF0000' },
                        };
                        followUp[key].z = '#,##0';
                    }
                    followUp[key].s = {
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        } else {
            for (const key in followUp) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    followUp[key].s = {
                        fill: reportHeaderBackground,
                        alignment: leftAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = leftAlignment;
                    if (key.charAt(0) === 'N') {
                        alignment = rightAlignment;
                    }
                    followUp[key].s = {
                        font: reportCellFont,
                        border: border,
                        alignment: alignment,
                    };
                    if (key.charAt(0) === 'N') {
                        followUp[key].z = '#,##0';
                    }
                }
            }
        }
    } catch (e) {}

    followUp['!cols'] = isUserPrime ? wscolsPrime : wscolsNonPrime;
    const workbook = {
        Sheets: {
            Subs_Needing_Verif_Follow_up: followUp,
        },
        SheetNames: ['Subs_Needing_Verif_Follow_up'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'Subs_Needing_Verif_Follow_up.xlsx');
};

export const getVerified: any = (i, contract_number, utility) => {
    return {
        Utility: utility,
        Contract: contract_number,
        'Project Description': i?.description,
        'Spend Type': i?.isTier3Spend ? 'Tier III' : 'Tier II',
        Subcontractor: i?.subcontractor,
        Jan: parseFloat(i?.janSpend) || 0,
        Feb: parseFloat(i?.febSpend) || 0,
        Mar: parseFloat(i?.marSpend) || 0,
        Apr: parseFloat(i?.aprSpend) || 0,
        May: parseFloat(i?.maySpend) || 0,
        Jun: parseFloat(i?.junSpend) || 0,
        Jul: parseFloat(i?.julSpend) || 0,
        Aug: parseFloat(i?.augSpend) || 0,
        Sep: parseFloat(i?.sepSpend) || 0,
        Oct: parseFloat(i?.octSpend) || 0,
        Nov: parseFloat(i?.novSpend) || 0,
        Dec: parseFloat(i?.decSpend) || 0,
        Total: parseFloat(i?.totalSpend),
    };
};

export const getUnVerified: any = (i, contract_number: number, utility) => {
    return {
        Utility: utility,
        Contract: contract_number,
        'Project Description': i?.description,

        'Spend Type': i?.isTier3Spend ? 'Tier III' : 'Tier II',
        Subcontractor: i?.subcontractor,
        Jan: parseFloat(i?.unverifiedJanSpend) || 0,
        Feb: parseFloat(i?.unverifiedFebSpend) || 0,
        Mar: parseFloat(i?.unverifiedMarSpend) || 0,
        Apr: parseFloat(i?.unverifiedAprSpend) || 0,
        May: parseFloat(i?.unverifiedMaySpend) || 0,
        Jun: parseFloat(i?.unverifiedJunSpend) || 0,
        Jul: parseFloat(i?.unverifiedJulSpend) || 0,
        Aug: parseFloat(i?.unverifiedAugSpend) || 0,
        Sep: parseFloat(i?.unverifiedSepSpend) || 0,
        Oct: parseFloat(i?.unverifiedOctSpend) || 0,
        Nov: parseFloat(i?.unverifiedNovSpend) || 0,
        Dec: parseFloat(i?.unverifiedDecSpend) || 0,
        Total: parseFloat(i?.unverifiedTotal),
    };
};

export const getTotal: any = (j, contract_number: number) => {
    return {
        Utility: j?.info?.utility,
        Contract: contract_number,
        'Project Description': j?.info?.description,
        'Spend Type': '',
        Subcontractor: 'Contract Month Totals',
        Jan: parseFloat(j?.poMonthTotal?.janTotal),
        Feb: parseFloat(j?.poMonthTotal?.febTotal),
        Mar: parseFloat(j?.poMonthTotal?.marchTotal),
        Apr: parseFloat(j?.poMonthTotal?.aprilTotal),
        May: parseFloat(j?.poMonthTotal?.mayTotal),
        Jun: parseFloat(j?.poMonthTotal?.junTotal),
        Jul: parseFloat(j?.poMonthTotal?.julytotal),
        Aug: parseFloat(j?.poMonthTotal?.augTotal),
        Sep: parseFloat(j?.poMonthTotal?.septTotal),
        Oct: parseFloat(j?.poMonthTotal?.octTotal),
        Nov: parseFloat(j?.poMonthTotal?.novTotal),
        Dec: parseFloat(j?.poMonthTotal?.decTotal),
        Total: parseFloat(j?.poMonthTotal?.totalAmount),
    };
};

//Prime subcontracting by contract API purchase order
export const downloadSubByPurchaseOrder: any = async (
    contractorsSelected,
    fetchDownloadReport
) => {
    let data: any = [];
    if (contractorsSelected) {
        data = await fetchDownloadReport(4, contractorsSelected);
    } else {
        data = await fetchDownloadReport(4, []);
    }
    let data1: any = [];
    try {
        for (const j of data?.payload?.data) {
            for (const i of j.subcontractors) {
                if (i.isVerified) {
                    let verified = getVerified(
                        i,
                        j.info.contract_number,
                        j.info.utility
                    );
                    verified = { ...verified, status: 'verified' };
                    data1.push(verified);
                } else {
                    let unVerified = getUnVerified(
                        i,
                        j.info.contract_number,
                        j.info.utility
                    );
                    unVerified = { ...unVerified, status: 'unverified' };
                    data1.push(unVerified);
                }
            }

            let total = getTotal(j, j.info.contract_number);
            total = { ...total, status: 'verified' };
            data1.push(total);
        }
    } catch (e) {}
    data1 = data1.filter((k) => k.Total !== 0);
    const contractingData = data1.map((dt) => {
        const newData = {
            ...dt,
            Jan: dt.Jan == 0 ? '-' : dt.Jan,
            Feb: dt.Feb == 0 ? '-' : dt.Feb,
            Mar: dt.Mar == 0 ? '-' : dt.Mar,
            Apr: dt.Apr == 0 ? '-' : dt.Apr,
            May: dt.May == 0 ? '-' : dt.May,
            Jun: dt.Jun == 0 ? '-' : dt.Jun,
            Jul: dt.Jul == 0 ? '-' : dt.Jul,
            Aug: dt.Aug == 0 ? '-' : dt.Aug,
            Sep: dt.Sep == 0 ? '-' : dt.Sep,
            Oct: dt.Oct == 0 ? '-' : dt.Oct,
            Nov: dt.Nov == 0 ? '-' : dt.Nov,
            Dec: dt.Dec == 0 ? '-' : dt.Dec,
            Total: dt.Total == 0 ? '-' : dt.Total,
        };
        return newData;
    });
    let followUp: any = null;
    const wscols = [
        { wch: 40 },
        { wch: 25 },
        { wch: 40 },
        { wch: 25 },
        { wch: 40 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
    ];
    const merge: any = [];
    const counts = {};
    contractingData.forEach(function (v, i) {
        counts[v.Contract] = (counts[v.Contract] || 0) + 1;
    });
    let counter = 1;
    for (const count in counts) {
        const xdata = {
            s: { r: counter, c: 0 },
            e: { r: counter + counts[count] - 1, c: 0 },
        };
        const x2data = {
            s: { r: counter, c: 1 },
            e: { r: counter + counts[count] - 1, c: 1 },
        };
        const x3data = {
            s: { r: counter, c: 2 },
            e: { r: counter + counts[count] - 1, c: 2 },
        };
        counter = counter + counts[count];
        merge.push(xdata);
        merge.push(x2data);
        merge.push(x3data);
    }

    try {
        followUp = XLSX.utils.json_to_sheet(contractingData);
        //Format Excel
        const unverifiedData = [0];
        for (const k in followUp) {
            if (k != '!ref') {
                const keyId = k.replace(/[^0-9]/gi, '');
                if (k.charAt(0) === 'S') {
                    if (followUp[k].v === 'unverified') {
                        unverifiedData[keyId] = 1;
                    } else {
                        unverifiedData[keyId] = 0;
                    }
                } else {
                    unverifiedData[keyId] = 0;
                }
            }
        }
        for (const key in followUp) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                if (key.charAt(0) !== 'S') {
                    followUp[key].s = {
                        fill: reportHeaderBackground,
                        alignment: centerAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                }
                if (key.charAt(0) === 'S') {
                    followUp[key].v = '';
                }
            } else {
                const i = key.replace(/[^0-9]/gi, '');
                let alignment = rightAlignment;
                if (
                    key.charAt(0) === 'B' ||
                    key.charAt(0) === 'C' ||
                    key.charAt(0) === 'A' ||
                    key.charAt(0) === 'D' ||
                    key.charAt(0) === 'E'
                ) {
                    alignment = leftAlignment;
                }
                if (key.charAt(0) !== 'S') {
                    if (unverifiedData[i] == 1) {
                        if (
                            key.charAt(0) === 'Q' ||
                            key.charAt(0) === 'R' ||
                            key.charAt(0) === 'F' ||
                            key.charAt(0) === 'G' ||
                            key.charAt(0) === 'H' ||
                            key.charAt(0) === 'I' ||
                            key.charAt(0) === 'J' ||
                            key.charAt(0) === 'K' ||
                            key.charAt(0) === 'L' ||
                            key.charAt(0) === 'M' ||
                            key.charAt(0) === 'N' ||
                            key.charAt(0) === 'O' ||
                            key.charAt(0) === 'P'
                        ) {
                            const cellFonta = {
                                ...reportCellFont,
                                color: { rgb: 'FF0000' },
                            };

                            followUp[key].s = {
                                font: cellFonta,
                                border: border,
                                alignment: alignment,
                            };
                        } else {
                            followUp[key].s = {
                                font: reportCellFont,
                                border: border,
                                alignment: alignment,
                            };
                        }
                    } else {
                        followUp[key].s = {
                            font: reportCellFont,
                            border: border,
                            alignment: alignment,
                        };
                    }
                }

                if (
                    key.charAt(0) === 'Q' ||
                    key.charAt(0) === 'R' ||
                    key.charAt(0) === 'F' ||
                    key.charAt(0) === 'G' ||
                    key.charAt(0) === 'H' ||
                    key.charAt(0) === 'I' ||
                    key.charAt(0) === 'J' ||
                    key.charAt(0) === 'K' ||
                    key.charAt(0) === 'L' ||
                    key.charAt(0) === 'M' ||
                    key.charAt(0) === 'N' ||
                    key.charAt(0) === 'O' ||
                    key.charAt(0) === 'P'
                ) {
                    followUp[key].z = '#,##0';
                }
                if (key.charAt(0) === 'S') {
                    followUp[key].v = '';
                }
            }
        }
    } catch (e) {}

    followUp['!cols'] = wscols;
    followUp['!merges'] = merge;
    const workbook = {
        Sheets: {
            subcontracting_by_contract: followUp,
        },
        SheetNames: ['subcontracting_by_contract'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'subcontracting_by_contract.xlsx');
};

export const downloadSpendAmountChanges: any = async (
    subcontractorsSelected,
    fetchDownloadReport
) => {
    const isUserPrime = isPrime();
    let data: any = [];
    if (subcontractorsSelected) {
        data = await fetchDownloadReport(3, subcontractorsSelected);
    } else {
        data = await fetchDownloadReport(3, []);
    }
    const data1 = data?.payload?.data?.map((i) => ({
        Utility: i.utility,
        Prime: i.primeName,
        Subcontractor: i.sub,
        'PO/Contract': i.contract_number,
        'Contact Name': i.contactName,
        'Date of Change': i?.changeDate,
        // 'Verification Agency': i.primeName,
        'Previous Amount': i.originalSpendAmt,
        'New Amount': i.newSpendAmt,
        Difference: i.diff,
        // i.diff > 0
        //     ? formatter.format(i.diff)
        //     : `(${formatter.format(i.absDiff)})`,
        'Mon/Yr': `${i?.spendMonth?.trim()}/${i?.spendYear?.trim()}`,
        'Spend Type': i.isTier3Spend ? 'Tier III' : 'Tier II',
    }));

    if (isUserPrime)
        data1.forEach(function (v) {
            delete v.Prime;
        });

    let followUp: any = [];
    const wscolsNonPrime = [
        { wch: 40 },
        { wch: 30 },
        { wch: 40 },
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 20 },
    ];
    const wscolsPrime = [
        { wch: 40 },
        { wch: 40 },
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 20 },
    ];

    try {
        followUp = XLSX.utils.json_to_sheet(data1);
        //Format Excel
        if (isUserPrime) {
            for (const key in followUp) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    followUp[key].s = {
                        fill: reportHeaderBackground,
                        alignment: leftAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = leftAlignment;
                    if (
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'I'
                    ) {
                        alignment = rightAlignment;
                    }
                    let cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };
                    if (key.charAt(0) === 'H' && followUp[key].v < 0) {
                        cellFont = {
                            ...reportCellFont,
                            color: { rgb: 'FF0000' },
                        };
                    }
                    followUp[key].s = {
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };
                    if (
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'H'
                    ) {
                        followUp[key].z = '#,##0';
                    }
                }
            }
        } else {
            for (const key in followUp) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    followUp[key].s = {
                        fill: reportHeaderBackground,
                        alignment: leftAlignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = leftAlignment;
                    if (
                        key.charAt(0) === 'J' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'I'
                    ) {
                        alignment = rightAlignment;
                    }
                    let cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };
                    if (key.charAt(0) === 'I' && followUp[key].v < 0) {
                        cellFont = {
                            ...reportCellFont,
                            color: { rgb: 'FF0000' },
                        };
                    }
                    followUp[key].s = {
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };
                    if (
                        key.charAt(0) === 'I' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'H'
                    ) {
                        followUp[key].z = '#,##0';
                    }
                }
            }
        }
    } catch (e) {}

    followUp['!cols'] = isUserPrime ? wscolsPrime : wscolsNonPrime;

    const workbook = {
        Sheets: {
            changes_to_spend_amounts: followUp,
        },
        SheetNames: ['changes_to_spend_amounts'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'changes_to_spend_amounts.xlsx');
};

export const saveData = (function () {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return function (data, fileName) {
        const blob = new Blob([data], { type: 'octet/stream' });
        let url = '';
        try {
            url = window.URL.createObjectURL(blob);
        } catch (e) {}

        a.href = url;
        a.download = fileName;
        a.click();
        try {
            window.URL.revokeObjectURL(url);
        } catch (e) {}
    };
})();

export const getFirstTable: any = (_, type: string) => {
    return [
        ...(type === 'verified' ? _?.verifySpend : _?.UnVerifySpend),
        type === 'verified' ? _?.verifySpendTotal : _?.unVerifySpendTotal,
    ];
};

export const structureData: any = (data: [{ data }] | [], type) => {
    if (!data) return [];
    const _ = data[0]?.data;
    if (_) {
        return getFirstTable(_, type);
    } else {
        return [];
    }
};

export const structureAdminData: any = (data) => {
    return data;
};

export const structurePurchaseOrderData: any = (data) => {
    return data;
};

export const handleSubcontractorFilter: any = (
    values,
    selectSubcontractors,
    fetchSpendAmountChanges,
    AdminfetchSpendAmountChanges,
    fetchMonthSummary,
    fetchMonthSummaryTierIII,
    report,
    type
) => {
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();
    selectSubcontractors(values);
    if (report === 3) {
        if (isUserPrime) {
            fetchSpendAmountChanges(
                { limit: 20, page: 1 },
                values,
                'subContractors'
            );
        } else if (isUserAdmin || isUserContact) {
            AdminfetchSpendAmountChanges(
                { limit: 20, page: 1 },
                values,
                type ?? 'sdcType'
            );
        }
    } else if (report === 1) {
        fetchMonthSummary(values);
    } else if (report === 6) {
        fetchMonthSummaryTierIII(values);
    }
};

export const handlePrimesFilter: any = (
    values,
    selectPrimes,
    fetchCallback
) => {
    selectPrimes(values);
    fetchCallback({ limit: 20, page: 1 }, values, 'prime_ids');
};

export const handlePrimesAdminFilter: any = (
    values,
    selectPrimes,
    fetchVerificationFollowUpAdmin
) => {
    selectPrimes(values);
    fetchVerificationFollowUpAdmin({ limit: 20, page: 1 }, values);
};

export const handleContractorFilter: any = (
    values,
    selectContractors,
    fetchPurchaseOrder
) => {
    selectContractors(values);
    fetchPurchaseOrder({ limit: 20, page: 1 }, values, '');
};

export const handleSupplierDiversityFilter: any = (
    values,
    selectSupplierDiversity,
    fetchPrimeInformation,
    type
) => {
    selectSupplierDiversity(values);
    fetchPrimeInformation({}, values, type ?? 'sdcType');
};

export const downloadPrimeInformation: any = async (fetchDownloadReport) => {
    let response: any = [];
    response = await fetchDownloadReport(1, []);
    const data1 = response?.payload?.data?.map((i) => ({
        Prime: i.name,
        'SAP ID': i.sapId,
        'Supplier Code': i.supplierCode,
        Address: i.address,
        'City State Zip': `${i.city}, ${i.state} ${i.zip}`,
        'Prime Primary Contact': `${i.contactName ?? ''}`,
        'Prime Primary Contact Email': `${i.contactEmail ?? ''}`,
        'Prime Primary Contact Phone': `${i.contactPhone ?? ''}`,
        'Prime Secondary Contact': `${i.contactName2 ?? ''}`,
        'Prime Secondary Contact Email': `${i.contactEmail2 ?? ''}`,
        'Prime Secondary Contact Phone': `${i.contactPhone2 ?? ''}`,
        'Prime Optional Contact': `${i.contactName3 ?? ''}`,
        'Prime Optional Contact Email': `${i.contactEmail3 ?? ''}`,
        'Prime Optional Contact Phone': `${i.contactPhone3 ?? ''}`,
        'Supplier Diversity Contact (SDG&E)': i.sdgAdvisor ?? '',
        'Supplier Diversity Contact (SoCalGas)': i.socalGasAdvisor ?? '',
        Contact1: i.contact1?.trim().length ? i.contact1 : '',
        Contact2: i.contact2?.trim().length ? i.contact2 : '',
        Contact3: i.contact3?.trim().length ? i.contact3 : '',
        Status: i.active ? 'Active' : 'Inactive',
        'Agreements Allowed': i.allowAgreements ? 'Yes' : 'No',
        'Current YTD Subcontracting': i.spend ? parseFloat(i.spend) : 0,
    }));

    let primeInfoData: any = null;
    const wscols = [
        { wch: 40 },
        { wch: 25 },
        { wch: 12 },
        { wch: 40 },
        { wch: 40 }, //E
        { wch: 20 },
        { wch: 25 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 }, //J
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 }, //O
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 10 }, //T
        { wch: 25 },
        { wch: 25 },
    ];
    try {
        primeInfoData = XLSX.utils.json_to_sheet(data1);
        //Format Excel
        for (const key in primeInfoData) {
            //The first line
            if (key.replace(/[^0-9]/gi, '') === '1') {
                const alignment = { ...leftAlignment, wrapText: true };
                primeInfoData[key].s = {
                    fill: reportHeaderBackground,
                    alignment: alignment,
                    font: reportHeaderFont,
                    border: border,
                };
            } else {
                let alignment = { ...leftAlignment, wrapText: true };
                if (key.charAt(0) === 'V') {
                    alignment = { ...rightAlignment, wrapText: true };
                }
                primeInfoData[key].s = {
                    font: reportCellFont,
                    border: border,
                    alignment: alignment,
                };
                if (key.charAt(0) === 'V') {
                    primeInfoData[key].z = '#,##0';
                }
            }
        }
    } catch (e) {}

    primeInfoData['!cols'] = wscols;

    const workbook = {
        Sheets: {
            prime_information: primeInfoData,
        },
        SheetNames: ['prime_information'],
    };

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const a = document.createElement('a');
    document.body.appendChild(a);
    saveData(excelBuffer, 'prime_information.xlsx');
};

export const handleDownload: any = (
    reportType,
    subcontractorsSelected,
    contractorsSelected,
    fetchDownloadReport,
    primesSelected
) => {
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();
    if (reportType === 1) {
        if (!isUserPrime) {
            downloadPrimeInformation(fetchDownloadReport);
        } else {
            downloadMonthSummaryReport(
                subcontractorsSelected,
                fetchDownloadReport
            );
        }
    } else if (reportType === 6) {
        downloadMonthSummaryReportTierIII(
            subcontractorsSelected,
            fetchDownloadReport
        );
    } else if (reportType === 2) {
        downloadVerificationFollowUp(fetchDownloadReport);
    } else if (reportType === 3) {
        downloadSpendAmountChanges(subcontractorsSelected, fetchDownloadReport);
    } else if (reportType === 4) {
        if (isUserAdmin || isUserContact) {
            // Call
            downloadMonthSummaryAdminReport(
                reportType,
                primesSelected,
                fetchDownloadReport
            );
        } else {
            downloadSubByPurchaseOrder(
                contractorsSelected,
                fetchDownloadReport
            );
        }
    } else if (reportType === 7) {
        downloadMonthSummaryAdminTierIIIReport(
            reportType,
            primesSelected,
            fetchDownloadReport
        );
    } else if (reportType === 5) {
        if (isUserAdmin || isUserContact) {
            // Call
            downloadMonthSummaryAdminReport(
                reportType,
                primesSelected,
                fetchDownloadReport
            );
        }
    }
};

export const onVerificationPageChange: any = async (
    i,
    vfuPage,
    vfuLimit,
    setVfuPage,
    setVfuLimit,
    fetchVerificationFollowUp,
    fetchVerificationFollowUpDataAdmin
) => {
    const isUserPrime = isPrime();
    if (!(i.page === vfuPage && i.limit === vfuLimit)) {
        await setVfuPage(i.page);
        await setVfuLimit(i.limit);
        if (isUserPrime) {
            await fetchVerificationFollowUp(i);
        } else {
            await fetchVerificationFollowUpDataAdmin(i, [], '');
        }
    }
};

export const onChangesPageChange: any = async (
    i,
    changesPage,
    changesLimit,
    setChangesPage,
    setChangesLimit,
    fetchSpendAmountChanges,
    subcontractorsSelected,
    AdminfetchSpendAmountChanges,
    setChangeSort,
    setChangeOrder,
    changeSort,
    changeOrder
) => {
    const isUserPrime = isPrime();
    if (
        !(
            i.page === changesPage &&
            i.limit === changesLimit &&
            i.order === changeOrder &&
            i.sort === changeSort
        )
    ) {
        setChangesPage(i.page);
        setChangesLimit(i.limit);
        setChangeOrder(i.order);
        setChangeSort(i.sort);
        if (isUserPrime) {
            await fetchSpendAmountChanges(i, [], '');
        } else await AdminfetchSpendAmountChanges(i, subcontractorsSelected);
    }
};

export const onPurchaseOrderPageChange: any = async (
    i,
    purchaseOrderPage,
    purchaseOrderLimit,
    setPurchaseOrderPage,
    setPurchaseOrderLimit,
    fetchPurchaseOrder,
    contractorsSelected
) => {
    if (!(i.page === purchaseOrderPage && i.limit === purchaseOrderLimit)) {
        await setPurchaseOrderPage(i.page);
        await setPurchaseOrderLimit(i.limit);
        await fetchPurchaseOrder(i, contractorsSelected, '');
    }
};
// handle pagination
export const onMonthSummaryOrderAdminPageChange: any = async (
    i,
    report,
    monthSummaryAdminPage,
    monthSummaryAdminLimit,
    setMonthSummaryAdminPage,
    setMonthSummaryAdminLimit,
    fetchMonthSummaryAdmin,
    fetchMonthSummaryAdminTierIII,
    fetchMonthReportByPrimeContract,
    primesSelected
) => {
    if (
        !(
            i.page === monthSummaryAdminPage &&
            i.limit === monthSummaryAdminLimit
        )
    ) {
        await setMonthSummaryAdminPage(i.page);
        await setMonthSummaryAdminLimit(i.limit);
        if (report === 4) {
            await fetchMonthSummaryAdmin(i, [], '');
        } else if (report === 5) {
            await fetchMonthReportByPrimeContract(i, primesSelected);
        } else if (report === 7) {
            await fetchMonthSummaryAdminTierIII(i, [], '');
        }
    }
};

const Report: React.FC = () => {
    const [resetPagination, setResetPagination] = useState(false);
    const { state: reportState, actions: reportActions } = useReportContext();
    const { state, actions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();

    const [report, setReport] = useState(0);
    const [vfuPage, setVfuPage] = useState(1);
    const [vfuLimit, setVfuLimit] = useState(20);
    const [changesPage, setChangesPage] = useState(1);
    const [changesLimit, setChangesLimit] = useState(20);
    const [changeOrder, setChangeOrder] = useState('');
    const [changeSort, setChangeSort] = useState('');
    const [purchaseOrderPage, setPurchaseOrderPage] = useState(1);
    const [purchaseOrderLimit, setPurchaseOrderLimit] = useState(20);
    const [monthSummaryAdminPage, setMonthSummaryAdminPage] = useState(1);
    const [monthSummaryAdminLimit, setMonthSummaryAdminLimit] = useState(20);
    const [subcontractorsSelected, selectSubcontractors] = useState([]);
    const [contractorsSelected, selectContractors] = useState([]);
    const [selectedUtility, setSelectedUtility] = useState('');
    const [primesSelected, selectPrimes] = useState([]);
    const [supplierDiversitySelected, selectSupplierDiversity] = useState([]);
    const [pipage, setPiPage] = useState(1);
    const [piSort, setPiSort] = useState('');
    const [piOrder, setPiOrder] = useState('');
    const [piLimit, setPiLimit] = useState(20);

    const [verificationExpDates, setVerificationExpDates] = useState([]);
    const [spendAmount, setSpendAmount] = useState('');
    const [spendDateRange, setSpendDateRange] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');

    const [currentApiYear, setCurrentApiYear] = useState(
        Number(localStorage.getItem('reportYear'))
    );
    const [num, setNum] = useState(0);

    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] =
        useState(false);

    const [loadEvent, setLoadEvent] = useState(false);
    const navigate = useNavigate();

    const user = getUser();
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setLoadEvent(true);
        } else {
            setLoadEvent(false);
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning]);

    const checkSCHFileStatus = async () => {
        const response = await actions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress);
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress);
        }
    };

    const fetchMonthSummary = useCallback(
        async (i) => {
            if (i.length === 0) {
                await reportActions.listMonthSummaryGET({
                    report_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                });
            } else {
                await reportActions.listMonthSummaryGET({
                    report_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    subcontractors_ids: i.join(),
                });
            }
        },
        [reportActions]
    );
    const fetchMonthSummaryTierIII = useCallback(
        async (i) => {
            if (i.length === 0) {
                await reportActions.listMonthSummaryTierIIIGET({
                    report_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                });
            } else {
                await reportActions.listMonthSummaryTierIIIGET({
                    report_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    subcontractors_ids: i.join(),
                });
            }
        },
        [reportActions]
    );
    // Admin Month report Api call
    const fetchMonthSummaryAdmin = useCallback(
        async (i, value, type) => {
            let utility = selectedUtility;
            if (i.hasOwnProperty('utility')) {
                utility = 'ALL';
            }
            const obj = {
                report_year: currentApiYear,
                primeListing:
                    primesSelected.length > 0 ? primesSelected.join() : '',
                sdc_names:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected.join()
                        : '',
                utility: utility ? utility : 'ALL',
                username: user.username,
                page: i?.page ?? 1,
                limit: i?.limit ?? 20,
            };
            if (type == 'prime_listing')
                obj.primeListing = value.length > 0 ? value.join() : '';
            if (type == 'sdc_type')
                obj.sdc_names = value.length > 0 ? value.join() : '';
            else if (type === 'clear') {
                obj.primeListing = '';
                obj.sdc_names = '';
            } else if (type === 'utility') {
                obj.utility = value > 0 ? value : 'ALL';
            }
            await reportActions.listMonthSummaryAdminGET(obj);
        },
        [
            reportActions,
            primesSelected,
            supplierDiversitySelected,
            selectedUtility,
        ]
    );

    // Admin Month report teir III Api call
    const fetchMonthSummaryAdminTierIII = useCallback(
        async (i, value, type) => {
            let utility = selectedUtility;
            if (i.hasOwnProperty('utility')) {
                utility = 'ALL';
            }
            const obj = {
                report_year: currentApiYear,
                primeListing:
                    primesSelected.length > 0 ? primesSelected.join() : '',
                sdc_names:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected.join()
                        : '',
                utility: utility ? utility : 'ALL',
                username: user.username,
                page: i?.page ?? 1,
                limit: i?.limit ?? 20,
            };
            if (type == 'prime_listing')
                obj.primeListing = value.length > 0 ? value.join() : '';
            if (type == 'sdc_type')
                obj.sdc_names = value.length > 0 ? value.join() : '';
            else if (type === 'clear') {
                obj.primeListing = '';
                obj.sdc_names = '';
            } else if (type === 'utility') {
                obj.utility = value > 0 ? value : 'ALL';
            }
            await reportActions.listMonthSummaryAdminTierIIIGET(obj);
        },
        [
            reportActions,
            primesSelected,
            supplierDiversitySelected,
            selectedUtility,
        ]
    );

    const fetchMonthReportByPrimeContract = useCallback(
        async (i, values, type) => {
            const objMonthReportByPrimeContact = {
                spend_year: currentApiYear,
                prime_ids:
                    primesSelected.length > 0 ? primesSelected.join() : 'ALL',
                contract_numbers:
                    contractorsSelected.length > 0
                        ? contractorsSelected.join()
                        : 'ALL',
                utility: selectedUtility ? selectedUtility : 'ALL',
                page: i?.page ?? 1,
                limit: i?.limit ?? 20,
                contact_users:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected.join()
                        : 'ALL',
                export: 0,
                username: user.username,
            };
            if (type === 'utility')
                objMonthReportByPrimeContact[type] =
                    values > 0 ? values : 'ALL';
            else if (type === 'clear') {
                objMonthReportByPrimeContact.prime_ids = 'ALL';
                objMonthReportByPrimeContact.contract_numbers = 'ALL';
                objMonthReportByPrimeContact.utility = 'ALL';
                objMonthReportByPrimeContact.contact_users = 'ALL';
            } else if (type)
                objMonthReportByPrimeContact[type] =
                    values.length > 0 ? values.join() : 'ALL';
            await reportActions.listMonthReportByPrimeContractGET(
                objMonthReportByPrimeContact
            );
        },
        [
            reportActions,
            primesSelected,
            supplierDiversitySelected,
            contractorsSelected,
            selectedUtility,
        ]
    );

    const fetchDownloadReport = useCallback(
        async (reportType, filters) => {
            const exportALL = 1;
            const primeInfoObj = {
                primeListing:
                    primesSelected.length > 0 ? primesSelected.join() : 'ALL',
                status: selectedStatus ? getStatus(selectedStatus) : 'All',
                sdcType:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected?.join()
                        : 'ALL',
                contract_numbers:
                    contractorsSelected.length > 0
                        ? contractorsSelected.join()
                        : 'ALL',
                utility: selectedUtility ? selectedUtility : 'ALL',
                verificationExpDates:
                    verificationExpDates.length > 0
                        ? verificationExpDates.join()
                        : '',
                spendData: spendAmount ? spendAmount : '0',
                subcontractors_ids: filters.length > 0 ? filters.join() : '',
                spend_date_range:
                    spendDateRange.length > 0 ? spendDateRange.join() : '',
            };
            return fetchDownload(
                reportType,
                filters,
                reportActions,
                user,
                exportALL,
                primeInfoObj,
                currentApiYear
                // isAdmin
            );
        },
        [
            reportActions,
            primesSelected,
            selectedStatus,
            supplierDiversitySelected,
            contractorsSelected,
            selectedUtility,
            verificationExpDates,
            spendAmount,
            spendDateRange,
        ]
    );

    const fetchVerificationFollowUp = useCallback(
        async (i, expDates) => {
            const objVerificationFollowup = {
                spend_year: currentApiYear,
                vendor_id: localStorage.getItem('loginCredential'),
                sort: '',
                order: 'ascending',
                page: i?.page ?? 1,
                limit: i?.limit ?? 20,
                expDates: '',
            };
            if (expDates?.length > 0)
                objVerificationFollowup.expDates = expDates.join();
            await reportActions.listNeedingVerificationFollowUpGET(
                objVerificationFollowup
            );
        },
        [reportActions]
    );

    const fetchVerificationFollowUpAdmin = useCallback(
        async (i, prime_names) => {
            await reportActions.listNeedingVerificationFollowUpAdminGET({
                spend_year: currentApiYear,
                page: i?.page ?? 1,
                prime_names: prime_names?.length > 0 ? prime_names?.join() : '',
                sdc_names:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected.join()
                        : '',
                verification_expiration_dates:
                    verificationExpDates.length > 0
                        ? verificationExpDates.join()
                        : '',
                spend_amounts: spendAmount ? spendAmount : '0',
                limit: i?.limit ?? 20,
                username: user.username,
            });
        },
        [reportActions]
    );

    const fetchVerificationFollowUpDataAdmin = useCallback(
        async (i, value, type) => {
            const objFilters = {
                username: user.username,
                spend_year: currentApiYear,
                page: i?.page ?? 1,
                prime_names:
                    primesSelected?.length > 0 ? primesSelected?.join() : '',
                sdc_names:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected.join()
                        : '',
                verification_expiration_dates:
                    verificationExpDates.length > 0
                        ? verificationExpDates.join()
                        : '',
                spend_amounts: spendAmount ? spendAmount : '0',
                limit: i?.limit ?? 20,
            };
            if (type == 'prime')
                objFilters.prime_names = value.length > 0 ? value.join() : '';
            if (type == 'sde')
                objFilters.sdc_names = value.length > 0 ? value.join() : '';
            if (type == 'expVerDates')
                objFilters.verification_expiration_dates =
                    value.length > 0 ? value.join() : '';
            if (type == 'spendAmount')
                objFilters.spend_amounts = value ? value : '0';
            if (type == 'clear') {
                objFilters.prime_names = '';
                objFilters.sdc_names = '';
                objFilters.verification_expiration_dates = '';
                objFilters.spend_amounts = '';
            }

            await reportActions.listNeedingVerificationFollowUpAdminGET(
                objFilters
            );
        },
        [
            reportActions,
            primesSelected,
            supplierDiversitySelected,
            verificationExpDates,
            spendAmount,
        ]
    );

    const fetchSpendAmountChanges = useCallback(
        async (i, value, type) => {
            let utility = selectedUtility;

            if (type === 'utility') {
                utility = value;
            }

            const objFilters = {
                spend_year: currentApiYear,
                vendor_id: localStorage.getItem('loginCredential'),
                page: i?.page ?? 1,
                limit: i?.limit ?? 20,
                sort: i?.sort,
                order: i?.order,
                utility: utility ? utility : 'ALL',
                subcontractors_ids:
                    subcontractorsSelected.length > 0
                        ? subcontractorsSelected.join()
                        : '',
                contract_numbers:
                    contractorsSelected.length > 0
                        ? contractorsSelected.join()
                        : '',
                spend_date_range:
                    spendDateRange.length > 0 ? spendDateRange.join() : '',
            };
            if (type === 'utility') {
                objFilters.utility = value;
            }
            if (type == 'subContractors')
                objFilters.subcontractors_ids =
                    value.length > 0 ? value.join() : '';
            if (type == 'contractNumbers')
                objFilters.contract_numbers =
                    value.length > 0 ? value.join() : '';
            if (type == 'dateRange')
                objFilters.spend_date_range =
                    value.length > 0 ? value.join() : '';
            if (type == 'clear') {
                objFilters.subcontractors_ids = '';
                objFilters.contract_numbers = '';
                objFilters.spend_date_range = '';
                objFilters.utility = 'ALL';
            }

            await reportActions.listChangesToSpendAmountsGET(objFilters);
        },
        [
            reportActions,
            subcontractorsSelected,
            contractorsSelected,
            spendDateRange,
            selectedUtility,
        ]
    );

    const AdminfetchSpendAmountChanges = useCallback(
        async (i, value, type) => {
            let utility = selectedUtility;

            if (type === 'utility') {
                utility = value;
            }
            const changeToSpend = {
                username: user.username,
                spend_year: currentApiYear,
                vendor_id: localStorage.getItem('loginCredential'),
                page: i?.page ?? 1,
                limit: i?.limit ?? 20,
                export: 0,
                sort: i?.sort,
                order: i?.order,
                utility: utility ? utility : 'ALL',
                prime_listing:
                    primesSelected.length > 0 && type != 'clear'
                        ? primesSelected.join()
                        : 'ALL',
                sdc_type:
                    supplierDiversitySelected.length > 0 && type != 'clear'
                        ? supplierDiversitySelected?.join()
                        : 'ALL',
            };
            if (type === 'utility') {
                changeToSpend.utility = value;
            }
            if (type && type != 'clear') {
                if (type != 'utility') {
                    changeToSpend[type] =
                        value.length > 0 ? value.join() : 'ALL';
                }
            }
            if (type == 'clear') {
                changeToSpend.sdc_type = 'ALL';
                changeToSpend.prime_listing = 'ALL';
                changeToSpend.utility = 'ALL';
            }
            await reportActions.listAdminChangesToSpendAmountsGET(
                changeToSpend
            );
        },
        [
            reportActions,
            supplierDiversitySelected,
            primesSelected,
            selectedUtility,
        ]
    );
    const fetchPurchaseOrder = useCallback(
        async (i, contracts, type) => {
            let utility = selectedUtility;
            if (type === 'utility') {
                utility = contracts;
            }
            if (utility === '') {
                utility = 'ALL';
            }
            if (type === 'utility') {
                await reportActions.listByPurchaseOrderGET({
                    spend_year: currentApiYear,
                    vendor_id: localStorage.getItem('loginCredential'),
                    page: i?.page ?? 1,
                    limit: i?.limit ?? 20,
                    utility: utility,
                });
            } else {
                if (contracts.length === 0) {
                    await reportActions.listByPurchaseOrderGET({
                        spend_year: currentApiYear,
                        vendor_id: localStorage.getItem('loginCredential'),
                        page: i?.page ?? 1,
                        limit: i?.limit ?? 20,
                        utility: utility,
                    });
                } else {
                    await reportActions.listByPurchaseOrderGET({
                        spend_year: currentApiYear,
                        vendor_id: localStorage.getItem('loginCredential'),
                        page: i?.page ?? 1,
                        limit: i?.limit ?? 20,
                        contract_number: contracts.join(),
                        utility: utility,
                    });
                }
            }
        },
        [reportActions]
    );

    const fetchPrimeInformation = useCallback(
        async (i, contracts, type) => {
            const objPrimeFilter = {
                report_year: currentApiYear,
                primeListing:
                    primesSelected.length > 0 ? primesSelected.join() : 'ALL',
                username: user.username,
                sdcType:
                    supplierDiversitySelected.length > 0
                        ? supplierDiversitySelected?.join()
                        : 'All',
                status:
                    i?.status === 0 || i?.status
                        ? i?.status
                        : selectedStatus
                          ? getStatus(selectedStatus)
                          : 'All',
                page: !isNaN(i?.page) ? i?.page - 1 : 0,
                limit: i?.limit ?? piLimit ?? 20,
                export: i?.export ?? 0,
                sort: i?.sort || '',
                order: i?.order || '',
            };
            if (type === 'sdcType')
                objPrimeFilter.sdcType =
                    contracts.length > 0 ? contracts?.join() : 'All';
            if (type === 'primes')
                objPrimeFilter.primeListing =
                    contracts.length > 0 ? contracts?.join() : 'ALL';
            if (type === 'clear') {
                objPrimeFilter.sdcType = 'All';
                objPrimeFilter.status = 'All';
                objPrimeFilter.primeListing = 'ALL';
            }
            await reportActions.listPrimeInformationGET(objPrimeFilter);
        },
        [
            reportActions,
            selectedStatus,
            primesSelected,
            supplierDiversitySelected,
        ]
    );

    const clearAllFilters = () => {
        fetchYear();
        setSelectedStatus('');
        setSelectedUtility('');
        setReport(0);
        setVfuPage(1);
        setVfuLimit(20);
        setChangesPage(1);
        setChangesLimit(20);
        setPurchaseOrderPage(1);
        setPurchaseOrderLimit(20);
        setMonthSummaryAdminPage(1);
        setMonthSummaryAdminLimit(20);
        selectSubcontractors([]);
        selectContractors([]);
        selectPrimes([]);
        selectSupplierDiversity([]);
        setVerificationExpDates([]);
        setSpendAmount('');
        setPiPage(1);
        setPiLimit(20);
    };

    const handleReportChange = useCallback(
        async (report) => {
            clearAllFilters();
            setReport(report);
            switchReport(
                report,
                selectSubcontractors,
                selectContractors,
                fetchMonthSummaryAdmin,

                fetchMonthSummaryAdminTierIII,
                fetchMonthSummary,
                fetchMonthSummaryTierIII,
                fetchVerificationFollowUp,
                fetchVerificationFollowUpAdmin,
                fetchPurchaseOrder,
                fetchSpendAmountChanges,
                fetchMonthReportByPrimeContract,
                navigate,
                fetchPrimeInformation,
                AdminfetchSpendAmountChanges,
                fetchVerificationFollowUpDataAdmin
                // isAdmin
            );
        },
        [setReport]
    );

    const handleSubcontractorFilterCall = (values) =>
        handleSubcontractorFilter(
            values,
            selectSubcontractors,
            fetchSpendAmountChanges,
            AdminfetchSpendAmountChanges,
            fetchMonthSummary,
            fetchMonthSummaryTierIII,
            report
        );

    const handleSpendAmountFilterCall = (values) => {
        setSpendAmount(values);

        if (isUserAdmin || isUserContact) {
            fetchVerificationFollowUpDataAdmin({}, values, 'spendAmount');
        }
    };

    const handleSupplierDiversityFilterCall = (values, type: string) => {
        if (type === 'clear') {
            selectPrimes([]);
            setSelectedStatus('');
            setSelectedUtility('');
            selectSupplierDiversity([]);
            selectContractors([]);
        } else selectSupplierDiversity(values);

        if (report === 5) {
            fetchMonthReportByPrimeContract(
                {},
                values,
                type ?? 'contact_users'
            );
        } else if (report === 3) {
            AdminfetchSpendAmountChanges(
                { limit: 20, page: 1 },
                values,
                type ?? 'sdc_type'
            );
        } else if (!isUserPrime && report === 4) {
            if (type === 'clear') {
                fetchMonthSummaryAdmin(
                    { limit: 20, page: 1, utility: 'ALL' },
                    values,
                    type ?? 'sdc_type'
                );
            } else {
                fetchMonthSummaryAdmin(
                    { limit: 20, page: 1 },
                    values,
                    type ?? 'sdc_type'
                );
            }
        } else if (!isUserPrime && report === 7) {
            if (type === 'clear') {
                fetchMonthSummaryAdminTierIII(
                    { limit: 20, page: 1, utility: 'ALL' },
                    values,
                    type ?? 'sdc_type'
                );
            } else {
                fetchMonthSummaryAdminTierIII(
                    { limit: 20, page: 1 },
                    values,
                    type ?? 'sdc_type'
                );
            }
        } else
            handleSupplierDiversityFilter(
                values,
                selectSupplierDiversity,
                fetchPrimeInformation,
                type
            );
    };

    const handleChangeVerificationExpDateCall = (values) => {
        setVerificationExpDates(values);

        if (isUserAdmin || isUserContact) {
            fetchVerificationFollowUpDataAdmin({}, values, 'expVerDates');
        } else {
            fetchVerificationFollowUp({ limit: 20, page: 1 }, values);
        }
    };

    const handlestatusFilterCall = (
        value,
        setSelectedStatus,
        fetchPrimeInformation
    ) => {
        setSelectedStatus(value === 'All' ? '' : value);
        fetchPrimeInformation(
            { status: value ? getStatus(value) : 'All' },
            [],
            'status'
        );
    };

    const handleStatusChangeCall = (value) => {
        const status = value;
        handlestatusFilterCall(
            status,
            setSelectedStatus,
            fetchPrimeInformation
        );
    };

    const handlePrimeFilterCall = (values: [], type: string) => {
        if (values.length) {
            fetchPrimeSupplierDiversityContact(values);
        }
        if (report === 1) {
            selectPrimes(values);
            fetchPrimeInformation({}, values, 'primes');
        } else if (report === 5 && !isUserPrime) {
            handlePrimesFilter(
                values,
                selectPrimes,
                fetchMonthReportByPrimeContract
            );
        } else if (report === 3) {
            if (isUserAdmin || isUserContact) {
                selectPrimes(values);
                AdminfetchSpendAmountChanges(
                    { limit: 20, page: 1 },
                    values,
                    type ?? 'prime_listing'
                );
            }
        } else if (report === 4) {
            if (isUserAdmin || isUserContact) {
                selectPrimes(values);
                fetchMonthSummaryAdmin(
                    { limit: 20, page: 1 },
                    values,
                    type ?? 'prime_listing'
                );
            }
        } else if (report === 7) {
            if (isUserAdmin || isUserContact) {
                selectPrimes(values);
                fetchMonthSummaryAdminTierIII(
                    { limit: 20, page: 1 },
                    values,
                    type ?? 'prime_listing'
                );
            }
        } else
            handlePrimesFilter(
                values,
                selectPrimes,
                fetchMonthSummaryAdminTierIII
            );
    };

    const handlePrimeFilterAdminCall = (values: []) => {
        selectPrimes(values);
        setSelectedStatus('');

        if (isUserAdmin || isUserContact) {
            handlePrimesAdminFilter(
                values,
                selectPrimes,
                fetchVerificationFollowUpAdmin
            );
        } else {
            handlePrimesAdminFilter(
                values,
                selectPrimes,
                fetchVerificationFollowUp
            );
        }
    };
    const fetchPrimeSupplierDiversityContact = useCallback(
        async (value) => {
            await actions?.primeAdvisorAndContactUserGET({
                prime_listing: value.join(),
                username: user.username,
            });
        },
        [actions, primesSelected]
    );

    const handlePrimesAdminNeedFollowUpFilterCall = (values: []) => {
        selectPrimes(values);
        if (values.length) {
            fetchPrimeSupplierDiversityContact(values);
        }
        if (isUserAdmin || isUserContact) {
            fetchVerificationFollowUpDataAdmin({}, values, 'prime');
        }
    };

    const handleClearAdminVerificationFollowUp = () => {
        selectPrimes([]);
        selectSupplierDiversity([]);
        setVerificationExpDates([]);
        setSpendAmount('');
        fetchVerificationFollowUpDataAdmin({}, '', 'clear');
    };

    const handleClearPrimeChangestoSpendAmount = () => {
        selectContractors([]);
        selectSubcontractors([]);
        setSpendDateRange([]);
        fetchSpendAmountChanges({}, '', 'clear');
    };

    const handleSupplierDiversityAdminFilterCall = (values: []) => {
        selectSupplierDiversity(values);

        if (isUserAdmin || isUserContact) {
            fetchVerificationFollowUpDataAdmin({}, values, 'sde');
        }
    };

    const handleContractFilterCall = (values) => {
        if (report === 5) {
            selectContractors(values);
            fetchMonthReportByPrimeContract({}, values, 'contract_numbers');
        } else if (report === 3 && isUserPrime) {
            selectContractors(values);
            fetchSpendAmountChanges(
                { limit: 20, page: 1 },
                values,
                'contractNumbers'
            );
        } else
            handleContractorFilter(
                values,
                selectContractors,
                fetchPurchaseOrder
            );
    };

    const handleSpendDateRangeFilterCall = (values) => {
        if (report === 3 && isUserPrime) {
            values = values ? values : [];
            setSpendDateRange(values);
            fetchSpendAmountChanges(
                { limit: 20, page: 1 },
                values,
                'dateRange'
            );
        }
    };

    const handleUtilityFilterCall = (values) => {
        if (report === 5) {
            setSelectedUtility(values > 0 ? values : '');
            fetchMonthReportByPrimeContract({}, values, 'utility');
        } else if (report === 4) {
            setSelectedUtility(values > 0 ? values : '');
            if (isUserPrime) {
                fetchPurchaseOrder({}, values, 'utility');
            } else {
                fetchMonthSummaryAdmin({}, values, 'utility');
            }
        } else if (report === 3) {
            setSelectedUtility(values > 0 ? values : '');
            if (isUserAdmin || isUserContact) {
                AdminfetchSpendAmountChanges({}, values, 'utility');
            } else if (isUserPrime) {
                fetchSpendAmountChanges({}, values, 'utility');
            }
        }
    };

    const handleDownloadCall = (reportType) =>
        handleDownload(
            reportType,
            subcontractorsSelected,
            contractorsSelected,
            fetchDownloadReport,
            primesSelected
        );

    const onVerificationPageChangeCall = (i) =>
        onVerificationPageChange(
            i,
            vfuPage,
            vfuLimit,
            setVfuPage,
            setVfuLimit,
            fetchVerificationFollowUp,
            fetchVerificationFollowUpDataAdmin
        );

    const onChangesPageChangeCall = (i) =>
        onChangesPageChange(
            i,
            changesPage,
            changesLimit,
            setChangesPage,
            setChangesLimit,
            fetchSpendAmountChanges,
            subcontractorsSelected,
            AdminfetchSpendAmountChanges,
            setChangeSort,
            setChangeOrder,
            changeSort,
            changeOrder
        );

    const onPurchaseOrderPageChangeCall = (i) =>
        onPurchaseOrderPageChange(
            i,
            purchaseOrderPage,
            purchaseOrderLimit,
            setPurchaseOrderPage,
            setPurchaseOrderLimit,
            fetchPurchaseOrder,
            contractorsSelected
        );

    const onPrimeInfoPageChangeCall = (i) => {
        onPrimeInfoPageChange(i, fetchPrimeInformation);
    };

    const onPrimeInfoPageChange = (i, fetchPrimeInformation) => {
        if (
            !(
                i.page === pipage &&
                i.limit === piLimit &&
                i.sort === piSort &&
                i.order === piOrder
            )
        ) {
            setPiPage(i.page);
            setPiLimit(i.limit);
            setPiOrder(i.order);
            setPiSort(i.sort);
            fetchPrimeInformation(i, [], '');
        }
    };

    const onMonthSummaryAdminPageChangeCall = (i) => {
        onMonthSummaryOrderAdminPageChange(
            i,
            report,
            monthSummaryAdminPage,
            monthSummaryAdminLimit,
            setMonthSummaryAdminPage,
            setMonthSummaryAdminLimit,
            fetchMonthSummaryAdmin,
            fetchMonthSummaryAdminTierIII,
            fetchMonthReportByPrimeContract,
            primesSelected
        );
    };

    useEffect(() => {
        fetchYear();
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus();

        if (!isUserPrime && primesSelected.length === 0)
            actions?.advisorAndContactUserGET();
    }, [isUserAdmin, report, primesSelected]);

    const fetchYear = async () => {
        const data = await actions.reportingyearsGet();
        localStorage.setItem(
            'reportYearUpdate',
            data.payload.data[0].report_year
        );
        if (
            localStorage.getItem('reportYear') !=
            localStorage.getItem('reportYearUpdate')
        ) {
            localStorage.setItem(
                'reportYear',
                data.payload.data[0].report_year
            );
            setCurrentApiYear(data.payload.data[0].report_year);
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    };

    const supplierDiversityOptions = useMemo(() => {
        const options: any =
            state.advisorAndContactUserList?.data &&
            state.advisorAndContactUserList?.data.sdge
                ? state.advisorAndContactUserList?.data.sdge?.map((type) => ({
                      key: type?.userID,
                      value: type?.userID,
                      text: `${type?.firstName} ${type?.lastName}` || '',
                  }))
                : [];

        const socalOptions: any =
            state.advisorAndContactUserList?.data &&
            state.advisorAndContactUserList?.data.socal &&
            state.advisorAndContactUserList?.data.socal.length > 0
                ? state.advisorAndContactUserList?.data.socal?.map((type) => ({
                      key: type?.userID,
                      value: type?.userID,
                      text: `${type?.firstName} ${type?.lastName}` || '',
                  }))
                : [];

        if (socalOptions) {
            options.push(...socalOptions);
        }

        return options;
    }, [state.advisorAndContactUserList, reportState?.listPrimeInformation]);
    return loadEvent ? (
        <Card
            style={{
                display: 'flex',
                marginTop: '100px',
                width: '100%',
                height: '100px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <FunctionalityDisableText />
        </Card>
    ) : (
        <ReportView
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            verificationFollowupList={
                isUserPrime
                    ? reportState?.listNeedingVerificationFollowUpGET
                    : reportState?.listNeedingVerificationFollowUpAdmin
            }
            structureData={structureData}
            structureAdminData={structureAdminData}
            structurePurchaseOrderData={structurePurchaseOrderData}
            listByPurchaseOrder={reportState?.listByPurchaseOrder}
            listChangesToSpendAmounts={reportState?.listChangesToSpendAmounts}
            listAdminChangesToSpendAmountsGET={
                reportState?.listAdminChangesToSpendAmountsGET
            }
            monthSummaryList={reportState?.monthSummaryList}
            monthSummaryAdminList={reportState?.monthSummaryAdminList}
            monthReportByPrimeContract={reportState?.monthReportByPrimeContract}
            handleSubcontractorFilter={handleSubcontractorFilterCall}
            handleContractorFilter={handleContractFilterCall}
            subcontractorsSelected={subcontractorsSelected}
            contractorsSelected={contractorsSelected}
            handleDownload={handleDownloadCall}
            onVerificationPageChange={onVerificationPageChangeCall}
            onChangesPageChange={onChangesPageChangeCall}
            onPurchaseOrderPageChange={onPurchaseOrderPageChangeCall}
            handleReportChange={handleReportChange}
            handlePrimesFilter={handlePrimeFilterCall}
            handlePrimesAdminFilter={handlePrimeFilterAdminCall}
            primesSelected={primesSelected}
            onMonthSummaryOrderAdminPageChange={
                onMonthSummaryAdminPageChangeCall
            }
            handleSupplierDiversityFilter={handleSupplierDiversityFilterCall}
            supplierDiversitySelected={supplierDiversitySelected}
            supplierDiversityOptions={supplierDiversityOptions}
            handleStatusChange={handleStatusChangeCall}
            onPrimeInfoPageChange={onPrimeInfoPageChangeCall}
            selectedStatus={selectedStatus}
            primeInfoData={reportState?.listPrimeInformation}
            handleUtilityFilter={handleUtilityFilterCall}
            selectedUtility={selectedUtility}
            verificationExpDates={verificationExpDates}
            handleChangeVerificationExpDate={
                handleChangeVerificationExpDateCall
            }
            handleSpendAmountFilter={handleSpendAmountFilterCall}
            handleSupplierDiversityAdminFilter={
                handleSupplierDiversityAdminFilterCall
            }
            handlePrimesAdminNeedFollowUpFilter={
                handlePrimesAdminNeedFollowUpFilterCall
            }
            spendAmount={spendAmount}
            handleClearAdminVerificationFollowUp={
                handleClearAdminVerificationFollowUp
            }
            handleClearPrimeChangestoSpendAmount={
                handleClearPrimeChangestoSpendAmount
            }
            handleSpendDateRangeFilter={handleSpendDateRangeFilterCall}
            spendDateRange={spendDateRange}
            currentApiYear={currentApiYear}
            selectSupplierDiversity={selectSupplierDiversity}
        />
    );
};

export default Report;

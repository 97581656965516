import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import AnnouncementReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/announcement.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(AnnouncementReducer, defaultState, asyncActions);
};

type IAnnouncementContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const AnnouncementContext = createContext<Partial<IAnnouncementContext>>({
    state: defaultState,
}) as React.Context<IAnnouncementContext>;

interface AppProps {
    children?: React.ReactNode;
}

const AnnouncementProvider: React.FC<AppProps> = ({ children }) => {
    const reducer = useReducer();
    return (
        <AnnouncementContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </AnnouncementContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useAnnouncementContext: () => IAnnouncementContext = () =>
    useContext(AnnouncementContext);
export type IUseAnnouncementContext = ReturnType<typeof useAnnouncementContext>;

export { AnnouncementContext, AnnouncementProvider };

import services from 'src/services/subcontractor.service';
import {
    ISubcontractor,
    ISubcontractorNotPrimeDetail,
    ISubcontractorLookupGetData,
    ISubcontractorReasonGet,
    ISubcontractorNotPrimeListPayload,
    ISubcontractorGenderNationalityAgency,
    SubcontractorDelectSchemaObject,
    AdminSubcontractorSchemaObject,
    ISubcontractorSpendsObject,
    IToggleTier2And3SubcontractorUnderPrime,
} from 'src/models/subcontractor.model';
import { IReportingyearsGetSchema } from '../models/manage-subcontractor.model';

import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import {
    IContractor
} from 'src/models/contractor.model';
export const actionTypes = {
    SUBCONTRACTOR_LIST_PRIME_FETCH: 'SUBCONTRACTOR_LIST_PRIME_FETCH',
    SUBCONTRACTOR_LIST_NOT_PRIME_FETCH: 'SUBCONTRACTOR_LIST_NOT_PRIME_FETCH',
    EMPLOYEE_REPLACE_UPDATE: 'EMPLOYEE_REPLACE_UPDATE',
    SUBCONTRACTOR_ADD_SELECTED: 'SUBCONTRACTOR_ADD_SELECTED',
    SUBCONTRACTOR_DETAIL_NOT_PRIME_FETCH:
        'SUBCONTRACTOR_DETAIL_NOT_PRIME_FETCH',
    SUBCONTRACTOR_UPDATE: 'SUBCONTRACTOR_UPDATE',
    SUBCONTRACTOR_AUTOFILL: 'SUBCONTRACTOR_AUTOFILL',
    SUBCONTRACTOR_REASON_LIST: 'SUBCONTRACTOR_REASON_LIST',
    SUBCONTRACTOR_GENDER_NATIONALITY_AGENCY_LIST:
        'SUBCONTRACTOR_GENDER_NATIONALITY_AGENCY_LIST',
    SUBCONTRACTOR_CREATE: 'SUBCONTRACTOR_CREATE',
    SUBCONTRACTOR_DELETE: 'SUBCONTRACTOR_DELETE',
    ADMIN_SUBCONTRACTOR: 'ADMIN_SUBCONTRACTOR',
    SUBCONTRACTOR_UPDATE_STATUS_ADMIN: 'SUBCONTRACTOR_UPDATE_STATUS_ADMIN',
    SUBCONTRACTOR_SPEND: 'SUBCONTRACTOR_SPEND',
    TOGGLE_TIER_II_AND_III_SUB_CONTRACTOR_UNDER_PRIME: 'TOGGLE_TIER_II_AND_III_SUB_CONTRACTOR_UNDER_PRIME',
    PEIME_CONTRACTOR: 'PEIME_CONTRACTOR',
    REPORTING_YEAR: 'REPORTING_YEAR'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listGET_Prime: {
        type: actionTypes.SUBCONTRACTOR_LIST_PRIME_FETCH,
        service: services.listGET_Prime,
        status: {} as never,
    },
    listGET_Not_Prime: {
        type: actionTypes.SUBCONTRACTOR_LIST_NOT_PRIME_FETCH,
        service: services.listGET_Not_Prime,
        status: {} as never,
    },
    DetailGET_Not_Prime: {
        type: actionTypes.SUBCONTRACTOR_DETAIL_NOT_PRIME_FETCH,
        service: services.DetailGET_Not_Prime,
        status: {} as never,
    },
    AutoFill: {
        type: actionTypes.SUBCONTRACTOR_AUTOFILL,
        service: services.AutoFill,
        status: {} as never,
    },

    GenderNationalityAgenctData: {
        type: actionTypes.SUBCONTRACTOR_GENDER_NATIONALITY_AGENCY_LIST,
        service: services.GenderNationalityAgencyList,
        status: {} as never,
    },

    Reason_getList: {
        type: actionTypes.SUBCONTRACTOR_REASON_LIST,
        service: services.ReasonList,
        status: {} as never,
    },
    AddSelectedSub_POST: {
        type: actionTypes.SUBCONTRACTOR_ADD_SELECTED,
        service: services.AddSelectedSub_POST,
        status: {} as never,
    },
    updateSubcontractor: {
        type: actionTypes.SUBCONTRACTOR_UPDATE,
        service: services.updateSubcontractor,
        status: {} as never,
    },
    updateSubcontractorStatusAdmin: {
        type: actionTypes.SUBCONTRACTOR_UPDATE_STATUS_ADMIN,
        service: services.updateSubcontractorStatusAdmin,
        status: {} as never,
    },

    createSubcontractor_POST: {
        type: actionTypes.SUBCONTRACTOR_CREATE,
        service: services.createSubcontractor_POST,
        status: {} as never,
    },

    replacePUT: {
        type: actionTypes.EMPLOYEE_REPLACE_UPDATE,
        service: services.replacePUT,
        status: {} as never,
    },

    subcontractorDelete: {
        type: actionTypes.SUBCONTRACTOR_DELETE,
        service: services.subcontractorDelete,
        status: {} as never,
    },

    adminSubcontractors: {
        type: actionTypes.ADMIN_SUBCONTRACTOR,
        service: services.adminSubcontractors,
        status: {} as never,
    },
    getSubcontractorSpendList: {
        type: actionTypes.SUBCONTRACTOR_SPEND,
        service: services.getSubcontractorSpendList,
        status: {} as never,
    },
    postToggleTier2And3SubcontractorUnderPrime: {
        type: actionTypes.TOGGLE_TIER_II_AND_III_SUB_CONTRACTOR_UNDER_PRIME,
        service: services.toggleTier2And3SubcontractorUnderPrime,
        status: {} as never,
    },
    primeContractorGet: {
        type: actionTypes.PEIME_CONTRACTOR,
        service: services.primeContractor,
        status: {} as never,
    },
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: services.reportingyearsGet,
        status: {} as never,
    },
};
export type ISubcontractorAsync = typeof asyncActions;

export type ISubcontractorReducerAction = IAsyncActions<ISubcontractorAsync>;
export interface ISubcontractorState
    extends ICommonState<ISubcontractorReducerAction> {
    list: ISubcontractor;
    notPrimeList: ISubcontractorNotPrimeListPayload;
    notPrimeDetail?: Partial<ISubcontractorNotPrimeDetail>;
    lookupData?: Partial<ISubcontractorLookupGetData>;
    reasons?: ISubcontractorReasonGet;
    GenderNationalityAgenctList?: ISubcontractorGenderNationalityAgency;
    deleteSubcontractor?: SubcontractorDelectSchemaObject;
    adminSucbontractorList?: AdminSubcontractorSchemaObject;
    subcontractorSpendList?: ISubcontractorSpendsObject;
    toggleTier2And3SubcontractorUnderPrime?: IToggleTier2And3SubcontractorUnderPrime;
    primeContractorGet: Partial<IContractor>;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
}

export const defaultState: any = {
    status: {},
    list: { data: [], total_pages: 0, total_rows: 0 },
    notPrimeList: {
        data: [],
        total_pages: 0,
        total_rows: 0,
        filters_data: { verificationExpirationStatus: [] },
    },

    adminSucbontractorList: {
        data: [],
        totalPages: 0,
        totalRows: 0,
        user_type: [],
    },
    notPrimeDetail: {},
    lookupData: { data: [] },
    reasons: { data: [] },
    GenderNationalityAgenctList: {
        data: {
            genders: [],
            nationalities: [],
            verification_agencies: [],
        },
    },
    subcontractorSpendList: {} as ISubcontractorSpendsObject,
    toggleTier2And3SubcontractorUnderPrime: {},
    primeContractorGet: {},
    reportingyearsGet: {},
};

const SubcontractorReducer = (
    state: ISubcontractorState = defaultState,
    action: ICommonAction<ISubcontractorReducerAction>
): ISubcontractorState => {
    switch (action.type) {
        case actionTypes.PEIME_CONTRACTOR: {
            return {
                ...state,
                primeContractorGet: action.payload || {},
            };
        }
        case actionTypes.SUBCONTRACTOR_LIST_PRIME_FETCH: {
            return {
                ...state,
                list: action.payload ?? {
                    data: [],
                    total_pages: 0,
                    total_rows: 0,
                },
            };
        }
        case actionTypes.SUBCONTRACTOR_LIST_NOT_PRIME_FETCH: {
            return {
                ...state,
                notPrimeList: action.payload ?? {
                    data: [],
                    total_pages: 0,
                    total_rows: 0,
                    filters_data: { verificationExpirationStatus: [] },
                },
            };
        }
        case actionTypes.SUBCONTRACTOR_DETAIL_NOT_PRIME_FETCH: {
            return {
                ...state,
                notPrimeDetail: action.payload ?? {},
            };
        }
        case actionTypes.SUBCONTRACTOR_AUTOFILL: {
            return {
                ...state,
                lookupData: action.payload ?? { data: [] },
            };
        }
        case actionTypes.SUBCONTRACTOR_REASON_LIST: {
            return {
                ...state,
                reasons: action.payload ?? { data: [] },
            };
        }
        case actionTypes.SUBCONTRACTOR_GENDER_NATIONALITY_AGENCY_LIST: {
            return {
                ...state,
                GenderNationalityAgenctList: action?.payload ?? {
                    data: {
                        genders: [],
                        nationalities: [],
                        verification_agencies: [],
                    },
                },
            };
        }
        case actionTypes.SUBCONTRACTOR_DELETE: {
            return {
                ...state,
                deleteSubcontractor: action.payload ?? { data: [] },
            };
        }
        case actionTypes.ADMIN_SUBCONTRACTOR: {
            return {
                ...state,
                adminSucbontractorList: action.payload ?? { data: [] },
            };
        }
        case actionTypes.SUBCONTRACTOR_SPEND: {
            return {
                ...state,
                subcontractorSpendList: action.payload,
            };
        }
        case actionTypes.TOGGLE_TIER_II_AND_III_SUB_CONTRACTOR_UNDER_PRIME: {
            return {
                ...state,
                toggleTier2And3SubcontractorUnderPrime: action.payload,
            };
        }
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default SubcontractorReducer;

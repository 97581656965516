import React, { useEffect, useCallback } from 'react';
import { ATMDivider, ATMButton } from 'shared-it-appmod-ui';
import { Container } from 'semantic-ui-react';
import styles from './announcement.module.scss';
import { useAnnouncementContext } from 'src/contexts/announcement.context';
import Lang from 'src/libraries/language';
import { isAdmin } from 'src/libraries/users.library';
import { useNavigate } from 'react-router-dom';

type JProps = {
    key: any;
    content: any;
};

const AnnouncementSegment: React.FC<JProps> = (props) => {
    return (
        <>
            <div>{props.content}</div>
            <ATMDivider />
        </>
    );
};

const Announcemnt: React.FC = () => {
    const { state, actions } = useAnnouncementContext();
    const isUserAdmin = isAdmin();
    const navigate = useNavigate();

    const handleSwitchRout = () => {
        localStorage.setItem('selectedTab', 'ManageAnnouncements');
        navigate('/admin');
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = useCallback(async () => {
        const params = { page: 1, limit: 200000000 };
        await actions?.getAnnouncementList(params);
    }, [actions]);

    return (
        <Container fluid className={styles.mainContainer}>
            <header
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 15,
                    fontWeight: 'bold',
                }}
            >
                <span>{Lang.TTL_ANNOUNCEMENTS}</span>
                {isUserAdmin && (
                    <ATMButton
                        secondary
                        floated="right"
                        onClick={handleSwitchRout}
                    >
                        {Lang.LBL_MANAGE_ANNOUNCEMENT}
                    </ATMButton>
                )}
            </header>
            <div
                style={{
                    textAlign: 'left',
                    overflow: 'auto',
                    height: 140,
                    paddingBottom: 15,
                }}
            >
                {state?.announcementList?.data?.map((announcement, index) => {
                    if (!announcement.isExpired) {
                        return (
                            <AnnouncementSegment
                                key={index}
                                content={announcement.description}
                            />
                        );
                    } else {
                        return <React.Fragment key={index}></React.Fragment>;
                    }
                })}
            </div>
        </Container>
    );
};

export default Announcemnt;

import React, { useCallback, useEffect, useState } from 'react';
import {
    ATMButton,
    ATMDropdown,
    ATMInput,
    ATMLoader,
    ATMPagination,
    ATMSelect,
    ATMTable,
} from 'shared-it-appmod-ui';
import { useContactPrimesContext } from 'src/contexts/contact-primes.context';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import Lang from 'src/libraries/language';
import {
    getUserID,
    isAdmin,
    isContact,
    isPrime,
} from 'src/libraries/users.library';
import XLSX from 'xlsx-js-style';
import style from './individual-contract-info.module.scss';
import { saveData } from 'src/components/pages/report/report.component';
import {
    border,
    leftAlignment,
    reportCellFont,
    reportHeaderBackground,
    reportHeaderFont,
    rightAlignment,
} from 'src/constants';

export type IProps = {
    companyCode: number;
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const fillText: any = {
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: 5,
    marginTop: 10,
    marginBottom: 5,
    color: '#606060',
    marginLeft: 10,
};

const IndividualContractInfoView: React.FC<IProps> = ({ companyCode }) => {
    const isUserAdmin = isAdmin();
    const isUserContact = isContact();
    const isUserPrime = isPrime();
    const { state: poState, actions: poActions } = useContractorPageContext();
    const [isLoading, setLoading] = useState(true);
    const [isDownloading, setDownloading] = useState(false);

    const [po, setPO] = useState<string>('');
    const [poAfterSearch, setPOAfterSearch] = useState<string>('');
    const [sapId, setSapId] = useState<string>('');
    const [sapIdAfterSearch, setSapIdAfterSearch] = useState<string>('');

    const { state: primeState, actions: primeActions } =
        useContactPrimesContext();
    const [primeDropdownOptions, setPrimeDropdownOptions] = useState<any>([]);

    const primeArray: any = [];
    const defaultTotalRows = 20;
    const defaultPage = 1;
    const [pageOption, selectPageOption] = useState(defaultTotalRows);
    const [pageNumber, setPageNumber] = useState(defaultPage);
    const [totalRows, setTotalRows] = useState(defaultTotalRows);
    const pageOptions = [10, 20, 25].map((i) => ({
        key: i,
        value: i,
        text: i,
    }));
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    const [poInfo, setPOInfo] = useState<any>([]);
    const [poTotalInfo, setPOTotalInfo] = useState<any>({});
    const [disableDownload, setDisableDownload] = useState(
        isUserAdmin ? true : false
    );
    const [compCode, setCompCode] = useState(companyCode);

    useEffect(() => {
        setPO('');
        setSapId('');
        setPOAfterSearch('');
        setSapIdAfterSearch('');
        setDisableDownload(isUserAdmin ? true : false);
        setCompCode(companyCode);
    }, [companyCode]);

    const fetchPrimeData = async () => {
        const response = await primeActions.getAllPrimes();
        const primes = response?.payload?.data;

        primes &&
            primes.length > 0 &&
            primes.map((prime) => {
                primeArray.push({
                    key: prime.sapId,
                    value: prime.sapId,
                    text: prime.name,
                });
            });
        setPrimeDropdownOptions(primeArray);
    };

    const fetchIndividualPOInfo = async (isDownload) => {
        !isDownload && setLoading(true);
        const filterData = {
            page: pageNumber,
            limit: pageOption,
            compCode: compCode,
            poNumber: poAfterSearch,
            export: isDownload ? 1 : 0,
        };
        const response = await callViewIndividualPOAPI(filterData);
        const poInfoData = response?.payload?.data;
        const individualContractInfoArr: any = [];
        if (poInfoData && !isDownload) {
            poInfoData?.individualContracts?.length > 0 &&
                poInfoData?.individualContracts.map((po) => {
                    const poObj = {
                        compCode: po.compCode ?? '-',
                        primeName: po.primeName ?? '-',
                        poNumber: po.poNumber ?? '-',
                        description: po.description ?? '-',
                        POYTD:
                            po?.POYTD >= 0
                                ? po.POYTD.toLocaleString()
                                : `(${Math.abs(po.POYTD).toLocaleString()})`,
                        overallGoal:
                            po?.overallGoal >= 0
                                ? `${po.overallGoal.toLocaleString()}%`
                                : `(${Math.abs(po.overallGoal).toLocaleString()})%`,
                        POGoal:
                            po?.POGoal >= 0
                                ? po.POGoal.toLocaleString()
                                : `(${Math.abs(po.POGoal).toLocaleString()})`,
                        verifiedSub:
                            po?.verifiedSub >= 0
                                ? po.verifiedSub.toLocaleString()
                                : `(${Math.abs(po.verifiedSub).toLocaleString()})`,
                        verifiedSubcontracting:
                            po?.verifiedSubcontracting >= 0
                                ? `${po.verifiedSubcontracting.toLocaleString()}%`
                                : `(${Math.abs(po.verifiedSubcontracting).toLocaleString()})%`,
                        subcontractingDiff:
                            po?.subcontractingDiff >= 0
                                ? po.subcontractingDiff.toLocaleString()
                                : `(${Math.abs(po.subcontractingDiff).toLocaleString()})`,
                    };
                    individualContractInfoArr.push(poObj);
                });

            setPOInfo(individualContractInfoArr);
            poInfoData?.totals
                ? setPOTotalInfo({
                      POYTD:
                          poInfoData?.totals?.POYTD >= 0
                              ? poInfoData?.totals?.POYTD.toLocaleString()
                              : `(${Math.abs(poInfoData?.totals?.POYTD).toLocaleString()})`,
                      overallGoal:
                          poInfoData?.totals?.overallGoal >= 0
                              ? `${poInfoData?.totals.overallGoal.toLocaleString()}%`
                              : `(${Math.abs(poInfoData?.totals.overallGoal).toLocaleString()})%`,
                      POGoal:
                          poInfoData?.totals?.POGoal >= 0
                              ? poInfoData?.totals?.POGoal.toLocaleString()
                              : `(${Math.abs(poInfoData?.totals?.POGoal).toLocaleString()})`,
                      verifiedSub:
                          poInfoData?.totals?.verifiedSub >= 0
                              ? poInfoData?.totals?.verifiedSub.toLocaleString()
                              : `(${Math.abs(poInfoData?.totals?.verifiedSub).toLocaleString()})`,
                      verifiedSubcontracting:
                          poInfoData?.totals?.verifiedSubcontracting >= 0
                              ? `${poInfoData?.totals?.verifiedSubcontracting.toLocaleString()}%`
                              : `(${Math.abs(poInfoData?.totals?.verifiedSubcontracting).toLocaleString()})%`,
                      subcontractingDiff:
                          poInfoData?.totals?.subcontractingDiff >= 0
                              ? poInfoData?.totals?.subcontractingDiff.toLocaleString()
                              : `(${Math.abs(poInfoData?.totals?.subcontractingDiff).toLocaleString()})`,
                  })
                : setPOTotalInfo({});
            response?.payload?.total_rows
                ? setTotalRows(response?.payload?.total_rows)
                : setTotalRows(0);
        }
        !isDownload && setLoading(false);
        return response;
    };

    const callViewIndividualPOAPI = async (filterData) => {
        if (isUserPrime) {
            const userId = getUserID();
            const filterOptionModified = { ...filterData, vendorId: userId };
            return await poActions.getSubcontractingIndividualContractDetailsPrime(
                filterOptionModified
            );
        } else {
            let filterOptionModified = {
                ...filterData,
                primefilter: sapIdAfterSearch === '' ? 'ALL' : 'Individual',
            };
            if (sapIdAfterSearch !== '') {
                setDisableDownload(false);
                filterOptionModified = {
                    ...filterOptionModified,
                    primeSapId: sapIdAfterSearch,
                };
            } else {
                setDisableDownload(true);
            }
            return await poActions.getSubcontractingIndividualPODetails(
                filterOptionModified
            );
        }
    };

    useEffect(() => {
        fetchIndividualPOInfo(false);
    }, [compCode]);

    useEffect(() => {
        fetchIndividualPOInfo(false);
    }, [pageOption, pageNumber]);

    useEffect(() => {
        (isUserAdmin || isUserContact) && fetchPrimeData();
    }, []);

    useEffect(() => {
        fetchIndividualPOInfo(false);
    }, [poAfterSearch, sapIdAfterSearch, pageNumber]);

    const handleSearch = () => {
        setPOAfterSearch(po);
        setSapIdAfterSearch(sapId);
        setPageNumber(defaultPage);
    };

    const handleClear = useCallback(() => {
        setPO('');
        setSapId('');
        setPOAfterSearch('');
        setSapIdAfterSearch('');
        setPageNumber(defaultPage);
        fetchIndividualPOInfo(false);
    }, [compCode]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window]);

    const handleDownload = async () => {
        setDownloading(true);
        const response = await fetchIndividualPOInfo(true);
        const poInfoData = response?.payload?.data;
        let data: any = [];
        data = poInfoData?.individualContracts?.map((po, index) => ({
            [Lang.LBL_UTILITY]:
                index === 0
                    ? (compCode === 2100 && Lang.LBL_SDGNE) ||
                      (compCode === 2200 && Lang.LBL_SOCALGAS)
                    : '',
            [Lang.LBL_PO]: po.poNumber ?? '-',
            ...(isUserAdmin && { [Lang.LBL_PRIME]: po.primeName ?? '-' }),
            [Lang.LBL_PO_DESCRIPTION]: po.description ?? '-',
            [Lang.LBL_PO_YTD]:
                po?.POYTD >= 0
                    ? po.POYTD.toLocaleString()
                    : `(${Math.abs(po.POYTD).toLocaleString()})`,
            [Lang.LBL_PO_GOAL_PERCENTAGE]:
                po?.overallGoal >= 0
                    ? `${po.overallGoal.toLocaleString()}%`
                    : `(${Math.abs(po.overallGoal).toLocaleString()})%`,
            [Lang.LBL_PO_GOAL]:
                po?.POGoal >= 0
                    ? po.POGoal.toLocaleString()
                    : `(${Math.abs(po.POGoal).toLocaleString()})`,
            [Lang.LBL_VERIFIED_SUB]:
                po?.verifiedSub >= 0
                    ? po.verifiedSub.toLocaleString()
                    : `(${Math.abs(po.verifiedSub).toLocaleString()})`,
            [Lang.LBL_ACTUAL_SUB]:
                po?.verifiedSubcontracting >= 0
                    ? `${po.verifiedSubcontracting.toLocaleString()}%`
                    : `(${Math.abs(po.verifiedSubcontracting).toLocaleString()})%`,
            [Lang.LBL_ACTUAL_VS_SUB_GOAL_DIFFERENCE]:
                po?.subcontractingDiff >= 0
                    ? po.subcontractingDiff.toLocaleString()
                    : `(${Math.abs(po.subcontractingDiff).toLocaleString()})`,
        }));
        poInfoData &&
            Object.keys(poInfoData.totals).length > 0 &&
            poInfoData?.individualContracts?.length > 0 &&
            data.push({
                [Lang.LBL_UTILITY]: Lang.LBL_TOTAL,
                [Lang.LBL_PO]: '',
                ...(isUserAdmin && { [Lang.LBL_PRIME]: '' }),
                [Lang.LBL_PO_DESCRIPTION]: '',
                [Lang.LBL_PO_YTD]:
                    poInfoData.totals?.POYTD >= 0
                        ? poInfoData.totals.POYTD.toLocaleString()
                        : `(${Math.abs(poInfoData.totals.POYTD).toLocaleString()})`,
                [Lang.LBL_PO_GOAL_PERCENTAGE]:
                    poInfoData.totals?.overallGoal >= 0
                        ? `${poInfoData.totals.overallGoal.toLocaleString()}%`
                        : `(${Math.abs(poInfoData.totals.overallGoal).toLocaleString()})%`,
                [Lang.LBL_PO_GOAL]:
                    poInfoData.totals?.POGoal >= 0
                        ? poInfoData.totals.POGoal.toLocaleString()
                        : `(${Math.abs(poInfoData.totals.POGoal).toLocaleString()})`,
                [Lang.LBL_VERIFIED_SUB]:
                    poInfoData.totals?.verifiedSub >= 0
                        ? poInfoData.totals.verifiedSub.toLocaleString()
                        : `(${Math.abs(poInfoData.totals.verifiedSub).toLocaleString()})`,
                [Lang.LBL_ACTUAL_SUB]:
                    poInfoData.totals?.verifiedSubcontracting >= 0
                        ? `${poInfoData.totals.verifiedSubcontracting.toLocaleString()}%`
                        : `(${Math.abs(poInfoData.totals.verifiedSubcontracting).toLocaleString()})%`,
                [Lang.LBL_ACTUAL_VS_SUB_GOAL_DIFFERENCE]:
                    poInfoData.totals?.subcontractingDiff >= 0
                        ? poInfoData.totals.subcontractingDiff.toLocaleString()
                        : `(${Math.abs(poInfoData.totals.subcontractingDiff).toLocaleString()})`,
            });
        let poInfo: any = null;
        if (isUserPrime) {
            poInfo = handleDownloadPrimeReport(data, poInfo);
        } else if (isUserAdmin) {
            poInfo = handleDownloadAdminReport(data, poInfo);
        }

        const workbook = {
            Sheets: {
                Sheet: poInfo,
            },
            SheetNames: ['Sheet'],
        };

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const a = document.createElement('a');
        document.body.appendChild(a);
        saveData(excelBuffer, 'Individual PO Details.xlsx');
        setDownloading(false);
    };

    const handleDownloadPrimeReport = (data, poInfo) => {
        const wscols = [
            { wch: 20 },
            { wch: 25 },
            { wch: 25 },
            { wch: 20 },
            { wch: 20 }, //E
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 30 },
        ];
        try {
            poInfo = XLSX.utils.json_to_sheet(data);
            for (const key in poInfo) {
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    const alignment = { ...leftAlignment, wrapText: true };
                    poInfo[key].s = {
                        fill: reportHeaderBackground,
                        alignment: alignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = { ...leftAlignment, wrapText: true };
                    if (
                        key.charAt(0) === 'D' ||
                        key.charAt(0) === 'F' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'I'
                    ) {
                        alignment = { ...rightAlignment, wrapText: true };
                    }
                    poInfo[key].s = {
                        font: reportCellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        } catch (e) {}
        poInfo['!cols'] = wscols;
        return poInfo;
    };

    const handleDownloadAdminReport = (data, poInfo) => {
        const wscols = [
            { wch: 20 },
            { wch: 25 },
            { wch: 30 },
            { wch: 25 },
            { wch: 20 }, //E
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 30 },
        ];
        try {
            poInfo = XLSX.utils.json_to_sheet(data);
            for (const key in poInfo) {
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    const alignment = { ...leftAlignment, wrapText: true };
                    poInfo[key].s = {
                        fill: reportHeaderBackground,
                        alignment: alignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = { ...leftAlignment, wrapText: true };
                    if (
                        key.charAt(0) === 'E' ||
                        key.charAt(0) === 'H' ||
                        key.charAt(0) === 'G' ||
                        key.charAt(0) === 'J'
                    ) {
                        alignment = { ...rightAlignment, wrapText: true };
                    }
                    poInfo[key].s = {
                        font: reportCellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        } catch (e) {}
        poInfo['!cols'] = wscols;
        return poInfo;
    };

    return isLoading ? (
        <div
            style={{
                width: '100%',
                paddingTop: 200,
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 200,
            }}
        >
            <ATMLoader active inline="centered" size="medium" />
        </div>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                }}
            >
                <div style={{ width: '80%' }}>
                    <span style={fillText}>{Lang.LBL_PO}</span>
                    <ATMInput
                        maxLength="10"
                        value={po}
                        onChange={(e) => setPO(e.target.value)}
                        className={style.text}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                    />
                    {(isUserAdmin || isUserContact) && (
                        <>
                            <span style={fillText}>{Lang.LBL_PRIME}</span>
                            <ATMSelect
                                search
                                placeholder={Lang.LBL_SELECT_PRIME}
                                options={primeDropdownOptions}
                                selection
                                value={sapId}
                                className={style.select}
                                compact
                                onChange={(_, val) => setSapId(val.value)}
                            />
                        </>
                    )}
                    <ATMButton
                        style={{ marginLeft: 10 }}
                        color="blue"
                        secondary
                        onClick={() => handleSearch()}
                    >
                        {Lang.LBL_SEARCH}
                    </ATMButton>
                    <ATMButton
                        style={{ marginLeft: 5 }}
                        color="blue"
                        secondary
                        onClick={() => handleClear()}
                    >
                        {Lang.LBL_CLEAR}
                    </ATMButton>
                </div>
                <div>
                    {(isUserAdmin || isUserPrime) && (
                        <ATMButton
                            style={{ marginLeft: 5 }}
                            color="blue"
                            secondary
                            loading={isDownloading}
                            disabled={disableDownload || isDownloading}
                            onClick={() => handleDownload()}
                        >
                            {Lang.LBL_DOWNLOAD}
                        </ATMButton>
                    )}
                </div>
            </div>
            <div style={{ padding: 5 }}>
                <ATMTable compact structured enableScroll>
                    <ATMTable.Header>
                        <ATMTable.Row>
                            <ATMTable.HeaderCell style={{ width: '200px' }}>
                                {Lang.LBL_UTILITY}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell style={{ width: '200px' }}>
                                {Lang.LBL_PO}
                            </ATMTable.HeaderCell>
                            {(isUserAdmin || isUserContact) && (
                                <ATMTable.HeaderCell>
                                    {Lang.LBL_PRIME}
                                </ATMTable.HeaderCell>
                            )}
                            <ATMTable.HeaderCell style={{ width: '200px' }}>
                                {Lang.LBL_PO_DESCRIPTION}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_PO_YTD}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_PO_GOAL_PERCENTAGE}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_PO_GOAL}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_VERIFIED_SUB}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_ACTUAL_SUB}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell style={{ width: '200px' }}>
                                {Lang.LBL_ACTUAL_VS_SUB_GOAL_DIFFERENCE}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                        {poInfo.map((po, index) => {
                            return (
                                <>
                                    <ATMTable.Row>
                                        <ATMTable.Cell>
                                            {index === 0 &&
                                                ((compCode === 2100 &&
                                                    Lang.LBL_SDGNE) ||
                                                    (compCode === 2200 &&
                                                        Lang.LBL_SOCALGAS))}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.poNumber}
                                        </ATMTable.Cell>
                                        {(isUserAdmin || isUserContact) && (
                                            <ATMTable.Cell>
                                                {po.primeName}
                                            </ATMTable.Cell>
                                        )}
                                        <ATMTable.Cell>
                                            {po.description}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.POYTD}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.overallGoal}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.POGoal}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.verifiedSub}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.verifiedSubcontracting}
                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {po.subcontractingDiff}
                                        </ATMTable.Cell>
                                    </ATMTable.Row>
                                </>
                            );
                        })}
                        {poTotalInfo &&
                            Object.keys(poTotalInfo).length > 0 &&
                            poInfo.length > 0 && (
                                <ATMTable.Row
                                    style={{
                                        fontWeight: 'bold',
                                        background:
                                            '#ffa50016 0% 0% no-repeat padding-box',
                                    }}
                                >
                                    <ATMTable.Cell>
                                        {Lang.LBL_TOTAL}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell></ATMTable.Cell>
                                    {(isUserAdmin || isUserContact) && (
                                        <ATMTable.Cell></ATMTable.Cell>
                                    )}
                                    <ATMTable.Cell></ATMTable.Cell>
                                    <ATMTable.Cell>
                                        {poTotalInfo.POYTD}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                        {poTotalInfo.overallGoal}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                        {poTotalInfo.POGoal}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                        {poTotalInfo.verifiedSub}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                        {poTotalInfo.verifiedSubcontracting}
                                    </ATMTable.Cell>
                                    <ATMTable.Cell>
                                        {poTotalInfo.subcontractingDiff}
                                    </ATMTable.Cell>
                                </ATMTable.Row>
                            )}
                    </ATMTable.Body>
                </ATMTable>
            </div>
            <ATMTable.Footer
                style={{
                    display: 'flex',
                    paddingTop: 10,
                    paddingLeft: 10,
                    width: '100%',
                }}
            >
                <React.Fragment>
                    <span>{Lang.LBL_ROW_PER_PAGE}</span>
                    <ATMDropdown
                        compact
                        options={pageOptions}
                        value={pageOption}
                        onChange={(i, j) => selectPageOption(Number(j.value))}
                    />
                </React.Fragment>
                <ATMPagination
                    style={{
                        marginLeft: (windowDimensions.width - 200) / 2.5,
                    }}
                    defaultActivePage={pageNumber}
                    totalPages={Math.ceil(totalRows / Number(pageOption))}
                    size="tiny"
                    onPageChange={(i, j) => {
                        setPageNumber(Number(j.activePage));
                    }}
                />
            </ATMTable.Footer>
        </>
    );
};

export default IndividualContractInfoView;
